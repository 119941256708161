import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { TextField, Button, CircularProgress } from "@material-ui/core";
import { Container } from "./styles";

import { Alert } from '@material-ui/lab';
import { toast } from 'react-toastify';

import axios from 'axios';

const Recovery:React.FC = ({ match }: any) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    confirmPassword:"",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword:"",
  });
  const [msg, setMsg] = useState("");

  const { token } = match.params;
  const history = useHistory();

  if(!token || token.length === 0) {
    history.push('/auth/home');
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setMsg("");
    setError({
      password: "",
      confirmPassword:"",
    })
    let lock_error = {
      password: "",
      confirmPassword:"",
    }
    function passwordIsValid(senha: string) {
      var regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/; 
      if (senha.length < 8) {
        return false;
      } else if (!regex.exec(senha)) {
        return false;
      }
      return true;
    }
    if (values.password.length === 0) {
      lock_error.password = "Preencha este campo";
    } else if (values.password.length < 8) {
      lock_error.password = "A senha deve conter no minímo 8 digitos!";
    } else if (!passwordIsValid(values.password)) {
      lock_error.password =
        "A senha deve no mínimo oito caracteres, sendo pelo menos um número.";
    }
    if(values.confirmPassword.length === 0) {
      lock_error.confirmPassword = "Preencha esse Campo";
    }
    if(values.password !== values.confirmPassword) {
      lock_error = {
        password: "Senhas não coincidem",
        confirmPassword:"Senhas não coincidem",
      }
    }
    if(lock_error.password !== "" || lock_error.confirmPassword !== "") {
      return setError(lock_error);
    }
    setLoading(true);
    try {
      await axios.post('/users/user/reset-password', {
        newPassword: values.password,
        token,
      });
      history.push('/auth/login');
      toast.success('Senha atualizada');
    }catch(err) {
      if(err.response && err.response.status === 400) {
        setMsg("Link inválido, requesite um novo link de recuperação.");
      }else if(err.response && err.response.status === 404){
        setMsg("Link inválido, requesite um novo link de recuperação.");
      }else if(err.response && err.response.status === 500){
        setMsg("Ocorreu um erro em nossos servidores, tente novamente mais tarde");
      }else{
        setMsg("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img src="/assets/images/logo-branca.png" alt="BFC Capital Partners LTDA" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4>Recuperar Conta</h4>
            <p>
                Senha deverá conter no mínimo oito caracteres, sendo pelo menos
                um número.
              </p>
            {msg !== "" && (
              <Alert severity="error">
                {msg}
              </Alert>
            )}
            <TextField
              type="password"
              label="Senha"
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              style={{ animationDelay: "150ms" }}
              error={error.password !== ""}
              helperText={error.password}
              disabled={loading}
            />
            <TextField
              type="password"
              label="Confirmar Senha"
              value={values.confirmPassword}
              onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
              style={{ animationDelay: "300ms" }}
              error={error.confirmPassword !== ""}
              helperText={error.confirmPassword}
              disabled={loading}
            />
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? (<CircularProgress />) : 'Recuperar Conta'}
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default Recovery;