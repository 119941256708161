import React from 'react';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import Resume from './components/Resume';

const Wallet: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  const history = useHistory();

  const handleNextItem = (actual: string) => {
    history.push(`/resumo/${actual}`);
  };

  return (
    <Container>
      {current === 'carteira' && (
        <Resume handleNextItem={() => handleNextItem('carteira')} />
      )}
    </Container>
  );
};

export default Wallet;
