import produce from 'immer';

import {
  IInitialState,
  UserActionTypes,
  SWITCH_COMPANY,
  PERSIST_COMPANY,
  SET_TYPE_EMISSION,
  UPDATE_NAME,
} from './types';
import { SIGN_IN_SUCCESS, SIGN_OUT } from '../auth/types';

const INITIAL_STATE: IInitialState = {
  profile: {
    id: '',
    name: '',
    email: '',
  },
  id: '',
  clieId: null,
  typeEmission: '',
  companyName: '',
  fantasyName: '',
  limit: null,
  limitUsed: null,
  cnpj: '',
};

export default function user(
  state = INITIAL_STATE,
  action: UserActionTypes
): IInitialState {
  return produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN_SUCCESS: {
        draft.profile = action.payload.user;
        draft.id = action.payload.company.id;
        draft.clieId = action.payload.company.clieId;
        draft.cnpj = action.payload.company.cnpj;
        draft.limit = action.payload.company.limit;
        draft.limitUsed = action.payload.company.limitUsed;
        draft.fantasyName = action.payload.company.fantasyName;
        draft.companyName = action.payload.company.companyName;
        draft.typeEmission = action.payload.company.typeEmission;
        break;
      }
      case SIGN_OUT: {
        draft = INITIAL_STATE;
        break;
      }
      case SWITCH_COMPANY: {
        draft.id = action.payload.company.id;
        draft.clieId = action.payload.company.clieId;
        draft.cnpj = action.payload.company.cnpj;
        draft.limit = action.payload.company.limit;
        draft.limitUsed = action.payload.company.limitUsed;
        draft.fantasyName = action.payload.company.fantasyName;
        draft.companyName = action.payload.company.companyName;
        draft.typeEmission = action.payload.company.typeEmission;
        break;
      }
      case PERSIST_COMPANY: {
        draft.id = action.payload.company.id;
        draft.clieId = action.payload.company.clieId;
        draft.cnpj = action.payload.company.cnpj;
        draft.limit = action.payload.company.limit;
        draft.limitUsed = action.payload.company.limitUsed;
        draft.fantasyName = action.payload.company.fantasyName;
        draft.companyName = action.payload.company.companyName;
        draft.typeEmission = action.payload.company.typeEmission;
        break;
      }
      case SET_TYPE_EMISSION: {
        draft.typeEmission = action.payload.typeEmission;
        break;
      }
      case UPDATE_NAME: {
        draft.profile.name = action.payload.name;
        break;
      }
      default:
    }
  });
}
