export const porte = (value: string) => {
  switch (value) {
    case "00":
      return "NAO INFORMADO";
    case "01":
      return "MICRO EMPRESA";
    case "03":
      return "EMPRESA DE PEQUENO PORTE";
    case "04":
      return "MEDIA";
    case "05":
      return "DEMAIS";
    default:
      return "";
  }
};
