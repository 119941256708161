import React, { useState, useEffect } from 'react';

import axios from 'axios';

import {
  Menu,
  MenuItem,
  LinearProgress,
  Tooltip,
  TextField,
  Button,
} from '@material-ui/core';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ExpandMore,
  StarHalf,
} from '@material-ui/icons';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { Table, Row, Col } from 'react-bootstrap';

import { format, addMinutes } from 'date-fns';
import useSelector from '~/store/useSelector';
import ModalDetail from './components/ModalDetail/index';

import { Container } from './styles';

import { normalizeNumber, normalizeCurrency } from '../utils/normalize';

import { IOperation } from './operation';

interface IOperationDetail {
  active: boolean;
  operation?: IOperation;
}
const Operations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [operations, setOperations] = useState<IOperation[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState(false);

  const [filters, setFilters] = useState({
    endDate: '',
    startDate: '',
    bordero: '',
  });

  const [operationDetail, setOperationDetail] = useState<IOperationDetail>({
    active: false,
  });

  const { id } = useSelector((state) => state.user);

  async function loadLastOperations(reset?: boolean) {
    setLoading(true);
    try {
      const { data: dataStatus } = await axios.get(`companies/status/${id}`);
      if (dataStatus.status === 'Em operação') {
        const query: string[] = [];
        if (page) {
          query.push(`page=${page}`);
        }
        if (perPage) {
          query.push(`perPage=${perPage}`);
        }
        if (!reset) {
          Object.keys(filters).forEach((k) => {
            if (filters[k] !== '') {
              query.push(`${k}=${filters[k]}`);
            }
          });
        }

        const { data } = await axios.get(
          `${
            process.env.REACT_APP_GATEWAY_OPERATION
          }/operations/operations/company/me?${query.map((i) => i).join('&')}`
        );
        setOperations(data || []);
        setStatus(true);
      } else {
        setOperations([]);
        setStatus(false);
      }
    } catch (err) {}
    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      loadLastOperations();
    }
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <div id="operations_table_wrap">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Borderôs</h4>
          <div>
            <Button
              color="primary"
              variant="contained"
              className="mr-2"
              type="button"
              disabled={operations.length === 0 || loading}
              onClick={() => loadLastOperations(false)}
            >
              <FaSearch className="mr-2" />
              Filtrar
            </Button>
            {Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={(e) => {
                  setFilters({
                    startDate: '',
                    endDate: '',
                    bordero: '',
                  });
                  loadLastOperations(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          </div>
        </div>
        <div className="filters mb-4">
          <Row>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  type="text"
                  label="Nº Borderô"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.bordero}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      bordero: normalizeNumber(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  type="date"
                  label="De"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.startDate}
                  onChange={(e) =>
                    setFilters({ ...filters, startDate: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.endDate}
                  onChange={(e) =>
                    setFilters({ ...filters, endDate: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <ModalDetail
          open={operationDetail.active}
          setOpen={() => setOperationDetail({ active: false })}
          operationId={operationDetail.operation?.id}
        />
        <Table responsive hover>
          <thead>
            <tr>
              <th className="text-left">Nº Borderô</th>
              <th className="text-center">Data do Borderô</th>
              <th className="text-center">Qtd de Títulos</th>
              <th>Vlr. Total</th>
              <th>Vlr. Líquido</th>
              <th>Em Aberto</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {status === false && !loading && (
              <tr style={{ height: '350px' }}>
                <td className="text-center" colSpan={8}>
                  <StarHalf
                    color="primary"
                    style={{ fontSize: '42px', marginBottom: '20px' }}
                  />
                  <h4>Seu cadastro ainda não está completo.</h4>
                  <h5>Para começar a antecipar é necessário:</h5>
                  <ul
                    style={{ listStyle: 'none', margin: '0px', padding: '0px' }}
                  >
                    <li>Preencher todos os dados cadastrais</li>
                    <li>Enviar e ter todos os documentos validados</li>
                    <li>Assinar contrato</li>
                  </ul>
                </td>
              </tr>
            )}
            {status &&
              operations.length > 0 &&
              operations.map((operation, index) => {
                return (
                  <tr
                    key={Number(index)}
                    onClick={() =>
                      setOperationDetail({
                        active: true,
                        operation,
                      })
                    }
                  >
                    <td className="text-left">{operation.borderoId}</td>
                    <td className="text-center">
                      {operation.borderoDate
                        ? format(
                            addMinutes(
                              new Date(operation.borderoDate),
                              new Date(
                                operation.borderoDate
                              ).getTimezoneOffset()
                            ),
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </td>
                    <td className="text-center">
                      {operation.titleQtd || '----'}
                    </td>
                    <td>
                      {operation.totalValue &&
                        `R$ ${normalizeCurrency(
                          Math.round(operation.totalValue)
                        )}`}
                    </td>
                    <td>
                      {operation.totalNetValue &&
                        `R$ ${normalizeCurrency(
                          Math.round(operation.totalNetValue)
                        )}`}
                    </td>
                    <td>-</td>
                    <td>{operation.operationStatus.description}</td>
                    <td className="text-right">
                      <button type="button">
                        <Tooltip title="Detalhamento">
                          <ArrowForwardIos />
                        </Tooltip>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end align-items-center footer_component">
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPerPage(25);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(50);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(100);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <span className="mr-4 ml-4">
              {page * perPage - perPage + 1}-{page * perPage}
            </span>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={operations.length < perPage || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Operations;
