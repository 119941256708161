import React, { useState, useEffect , useCallback} from "react";

import { useHistory } from "react-router-dom";

import { CircularProgress, Button } from "@material-ui/core";
import { Container } from "./styles";

import axios from "axios";

const ActiveAcc:React.FC = ({ match }: any) => {
  const history = useHistory()
  const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [msg, setMsg] = useState("");

  const { token } = match.params;

  useEffect(()=> { 
    async function loadData(){
      try {
        setLoading(true)
        await axios.post(`/users/user/active-account`, {
          token,
        })
        setVerified(true)
      } catch (err) {
        //TODO: Aprimorar o tratamento de erros
        setIsTokenInvalid(true)
      } finally {
        setLoading(false)
      }
    }

    loadData()
  }, [isTokenInvalid, token])

  const handleButtonClick = useCallback(() => {
    history.push(`/auth/login`)
  }, [history])

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img src="/assets/images/logo-branca.png" alt="BFC Capital Partners LTDA" />
        </div>
        <div className="form-container">
        {loading && (<CircularProgress />)}  
        {verified && (
          <>
          <h4>Conta ativada com sucesso!</h4> 
          <p>Você já pode fazer o login na plataforma da BFC</p>
          <div className="button-wrap">
            <Button
                onClick={handleButtonClick}
                type="submit"
                color="primary"
                size="large"
                variant="contained"
              >
                Entrar
            </Button>
          </div>
          </>
        )}
        {isTokenInvalid && (<h4>Token inválido</h4>)}
        </div>
      </div>
    </Container>
  );
}

export default ActiveAcc;