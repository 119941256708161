import React, { memo, useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  FormHelperText,
  LinearProgress,
} from "@material-ui/core";

import { format } from "date-fns";

import StateSelect from "./StateSelect";
import {
  normalizeCpf,
  normalizeTelephone,
  normalizeDate,
  normalizeCep,
} from "../../../utils";

import {
  validatorMember,
  validatorMemberSpouses,
} from "../utils/memberPFValidation";

import consultCep from "../../../../../../services/consultCep";

import { useDispatch } from "react-redux";
import { signOut } from "../../../../../../store/modules/auth/actions";

import axios from "axios";
import { toast } from "react-toastify";

interface IMember {
  id: string;
  docType: string;
  type: string;
  name: string;
  document: string;
}
interface IAddMember {
  (value: IMember): void;
}

interface propsInt {
  resetForm: any;
  editMember: IAddMember;
  id: string;
  status:
    | "Incompleto"
    | "Em análise"
    | "Aguardando geração do contrato"
    | "Aguardando assinatura do contrato"
    | "Em operação";
}

interface IMemberType {
  id: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

const EditMemberPF: React.FC<propsInt> = ({
  resetForm,
  editMember,
  id,
  status,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
  const [memberType, setMemberType] = useState<string | undefined | unknown>(
    ""
  );
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const history = useHistory();

  const [editMemberPF, setEditMemberPF] = useState({
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    telephone: "",
    birthDate: "",
    motherName: "",
    maritalStatus: "",
    maritalStatusRegime: "",
    politicalExposedPerson: "",
    logradouro: "",
    numero: "",
    complemento: "",
    cep: "",
    estado: "",
    cidade: "",
    bairro: "",
    memberTypes: [],
  });
  const [editMemberPFErrors, setEditMemberPFErrors] = useState({
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    telephone: "",
    birthDate: "",
    motherName: "",
    maritalStatus: "",
    maritalStatusRegime: "",
    logradouro: "",
    numero: "",
    complemento: "",
    cep: "",
    estado: "",
    cidade: "",
    bairro: "",
  });
  const [memberSpouses, setMemberSpouses] = useState({
    id: "",
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    birthDate: "",
    motherName: "",
  });

  useEffect(() => {
    async function loadMemberTypes() {
      try {
        const { data } = await axios.get(`/partners/member-types`);
        setMemberTypes(data.filter(type => type.visible));
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error("Sua sessão expirou, entre novamente");
        } else if (err.response && err.response.status === 403) {
          toast.error("Você não está autorizado a acessar este recurso");
        } else if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
          );
        } else {
          toast.error("Ocorreu um erro, tente novamente mais tarde");
        }
      }
    }
    loadMemberTypes();
  }, [dispatch]);

  const [memberSpousesErrors, setMemberSpousesErrors] = useState({
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    birthDate: "",
    motherName: "",
  });

  function resetInfos() {
    setEditMemberPF({
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      telephone: "",
      birthDate: "",
      motherName: "",
      maritalStatus: "",
      maritalStatusRegime: "",
      politicalExposedPerson: "",
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
      memberTypes: []
    });
    setMemberSpouses({
      id: "",
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      birthDate: "",
      motherName: "",
    });
    setMemberSpousesErrors({
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      birthDate: "",
      motherName: "",
    });
    setEditMemberPFErrors({
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      telephone: "",
      birthDate: "",
      motherName: "",
      maritalStatus: "",
      maritalStatusRegime: "",
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
    });
    resetForm();
  }

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/partners/physical-persons/${id}`);
        setEditMemberPF({
          ...data,
          cep: data.address.cep,
          bairro: data.address.district,
          cidade: data.address.city,
          complemento: data.address.complement,
          logradouro: data.address.street,
          numero: data.address.number,
          estado: data.address.state,
          eCpf: data.eCpf ? "1" : "0",
          birthDate: format(new Date(data.birthDate), "dd/MM/yyyy"),
        });
        setMemberType(data.memberTypes.find((type:any) => type.visible).id);
        if (data.spouse) {
          setMemberSpouses({
            ...data.spouse,
            birthDate: data.spouse.birthDate
              ? format(new Date(data.spouse.birthDate), "dd/MM/yyyy")
              : "",
            eCpf: data.spouse.eCpf ? "1" : "0",
          });
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error("Sua sessão expirou, entre novamente");
        } else if (err.response && err.response.status === 403) {
          toast.error("Você não está autorizado a acessar este recurso");
        } else if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
          );
        } else {
          toast.error("Ocorreu um erro, tente novamente mais tarde");
        }
      }
      setLoading(false);
    }
    loadData();
  }, [dispatch, id]);

  async function handleSubmit() {
    setMemberSpousesErrors({
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      birthDate: "",
      motherName: "",
    });
    setEditMemberPFErrors({
      name: "",
      cpf: "",
      rg: "",
      rgEmmiter: "",
      eCpf: "",
      nacionality: "",
      profission: "",
      email: "",
      telephone: "",
      birthDate: "",
      motherName: "",
      maritalStatus: "",
      maritalStatusRegime: "",
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
    });
    setLoading(true);
    const {
      count_errors: count_errors_member,
      errors: errors_member,
    } = await validatorMember({ ...editMemberPF, id });
    setLoading(false);
    let count_errors_spouses = 0;
    if (
      (editMemberPF.maritalStatus === "Casado(a)" ||
        editMemberPF.maritalStatus === "União estável") &&
      editMemberPF.maritalStatusRegime !== "Separação total de bens"
    ) {
      const { count_errors, errors } = validatorMemberSpouses({
        ...memberSpouses,
        cpfMember: editMemberPF.cpf,
      });
      count_errors_spouses = count_errors;
      if (count_errors > 0) {
        setMemberSpousesErrors(errors);
      }
    }

    if (count_errors_member > 0) {
      setEditMemberPFErrors(errors_member);
    }
    if (
      count_errors_spouses > 0 ||
      count_errors_member > 0 ||
      memberType === ""
    ) {
      return;
    }
    setLoading(true);
    try {
      let birthDate: string | Date = "";
      let splited_date = editMemberPF.birthDate.split("/");
      birthDate = new Date(`${splited_date[2]}-${splited_date[1]}-${splited_date[0]}`);

      let spouses_birthDate: string | Date = "";

      if (
        (editMemberPF.maritalStatus === "Casado(a)" ||
          editMemberPF.maritalStatus === "União estável") &&
        editMemberPF.maritalStatusRegime !== "Separação total de bens"
      ) {
        let splited_date_spouses = memberSpouses.birthDate.split("/");
        spouses_birthDate =
        new Date(`${splited_date_spouses[2]}-${splited_date_spouses[1]}-${splited_date_spouses[0]}`);
      }

      let memberTypeIds: string[] = [] 

      editMemberPF.memberTypes.filter((type: any) => !type.visible).forEach((type: any) => memberTypeIds.push(type.id as string))

      memberTypeIds.push(memberType as string)

      const response = await axios.put(`/partners/physical-persons/${id}`, {
        memberTypeIds,
        name: editMemberPF.name,
        cpf: editMemberPF.cpf,
        rg: editMemberPF.rg,
        rgEmmiter: editMemberPF.rgEmmiter,
        eCpf: editMemberPF.eCpf === "1" ? true : false,
        nacionality: editMemberPF.nacionality,
        profission: editMemberPF.profission,
        email: editMemberPF.email,
        telephone: editMemberPF.telephone,
        birthDate,
        motherName: editMemberPF.motherName,
        maritalStatus: editMemberPF.maritalStatus,
        maritalStatusRegime: editMemberPF.maritalStatusRegime,
        politicalExposedPerson: editMemberPF.politicalExposedPerson,
        address: {
          street: editMemberPF.logradouro,
          number: editMemberPF.numero,
          complement: editMemberPF.complemento,
          cep: editMemberPF.cep,
          state: editMemberPF.estado,
          city: editMemberPF.cidade,
          district: editMemberPF.bairro,
        },
        spouses:
          (editMemberPF.maritalStatus === "Casado(a)" ||
            editMemberPF.maritalStatus === "União estável") &&
          editMemberPF.maritalStatusRegime !== "Separação total de bens"
            ? {
                id: memberSpouses.id !== "" ? memberSpouses.id : undefined,
                name: memberSpouses.name,
                birthDate: spouses_birthDate,
                cpf: memberSpouses.cpf,
                eCpf: memberSpouses.eCpf,
                email: memberSpouses.email,
                rg: memberSpouses.rg,
                rgEmmiter: memberSpouses.rgEmmiter,
                motherName: memberSpouses.motherName,
                nacionality: memberSpouses.nacionality,
                profission: memberSpouses.profission,
              }
            : undefined,
      });
      editMember({
        id: response.data.id,
        name: editMemberPF.name,
        document: editMemberPF.cpf,
        docType: "PF",
        type: response.data.memberTypes[0].type,
      });
      resetForm();
      if (urlParams.get("pendency") === "1") {
        history.push(
          `cadastro/documentos?pendency=1&doc=${urlParams.get(
            "doc"
          )}&type=pf&id=${urlParams.get("id")}`
        );
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error("Sua sessão expirou, entre novamente");
      } else if (err.response && err.response.status === 403) {
        toast.error("Você não está autorizado a acessar este recurso");
      } else if (err.response && err.response.status === 400) {
        toast.error("Há campos inválidos");
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, tente novamente mais tarde"
        );
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
    setLoading(false);
  }

  async function handleCep() {
    const consult = await consultCep(editMemberPF.cep);

    if (consult) {
      setEditMemberPF({
        ...editMemberPF,
        ...consult,
      });
    }
  }

  return (
    <>
      <div className="content_component">
        {loading && <LinearProgress />}
        <form>
          <FormControl
            error={memberType === ""}
            disabled={
              loading || (status !== "Incompleto" && status !== "Em análise")
            }
            style={{ width: "100%" }}
            className="mb-5"
          >
            <InputLabel>Tipo de Sócio</InputLabel>
            <Select
              disabled={
                loading || (status !== "Incompleto" && status !== "Em análise")
              }
              value={memberType}
              onChange={(e) => setMemberType(e.target.value)}
              style={{ width: "100%" }}
            >
              {memberTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <fieldset>
            <h4>Identificação</h4>
            <div className="row">
              <div className="col-md-3 mt-2">
                <TextField
                  label="Nome completo"
                  className="d-flex"
                  value={editMemberPF.name}
                  onChange={(e) =>
                    setEditMemberPF({ ...editMemberPF, name: e.target.value })
                  }
                  error={editMemberPFErrors.name !== ""}
                  helperText={editMemberPFErrors.name}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="CPF"
                  className="d-flex"
                  value={editMemberPF.cpf}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      cpf: normalizeCpf(e.target.value),
                    })
                  }
                  error={editMemberPFErrors.cpf !== ""}
                  helperText={editMemberPFErrors.cpf}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="RG/RNE"
                  className="d-flex"
                  value={editMemberPF.rg}
                  onChange={(e) =>
                    setEditMemberPF({ ...editMemberPF, rg: e.target.value })
                  }
                  error={editMemberPFErrors.rg !== ""}
                  helperText={editMemberPFErrors.rg}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Emissor RG/RNE"
                  className="d-flex"
                  value={editMemberPF.rgEmmiter}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      rgEmmiter: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.rgEmmiter !== ""}
                  helperText={editMemberPFErrors.rgEmmiter}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <FormControl
                  style={{ width: "100%" }}
                  error={editMemberPFErrors.eCpf !== ""}
                >
                  <InputLabel id="eCpf_label">eCPF</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    value={editMemberPF.eCpf}
                    onChange={(e) =>
                      setEditMemberPF({
                        ...editMemberPF,
                        eCpf: String(e.target.value),
                      })
                    }
                    labelId="eCpf_label"
                    error={editMemberPFErrors.eCpf !== ""}
                    disabled={
                      loading ||
                      (status !== "Incompleto" && status !== "Em análise")
                    }
                  >
                    <MenuItem value={"1"}>Possui</MenuItem>
                    <MenuItem value={"0"}>Não Possui</MenuItem>
                  </Select>
                  {editMemberPFErrors.eCpf !== "" && (
                    <FormHelperText error>
                      {editMemberPFErrors.eCpf}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Nacionalidade"
                  className="d-flex"
                  value={editMemberPF.nacionality}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      nacionality: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.nacionality !== ""}
                  helperText={editMemberPFErrors.nacionality}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Profissão"
                  className="d-flex"
                  value={editMemberPF.profission}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      profission: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.profission !== ""}
                  helperText={editMemberPFErrors.profission}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Endereço de E-mail"
                  className="d-flex"
                  value={editMemberPF.email}
                  onChange={(e) =>
                    setEditMemberPF({ ...editMemberPF, email: e.target.value })
                  }
                  error={editMemberPFErrors.email !== ""}
                  helperText={editMemberPFErrors.email}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Telefone"
                  className="d-flex"
                  value={editMemberPF.telephone}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      telephone: normalizeTelephone(e.target.value),
                    })
                  }
                  error={editMemberPFErrors.telephone !== ""}
                  helperText={editMemberPFErrors.telephone}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Data de Nascimento"
                  className="d-flex"
                  value={editMemberPF.birthDate}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      birthDate: normalizeDate(e.target.value),
                    })
                  }
                  error={editMemberPFErrors.birthDate !== ""}
                  helperText={editMemberPFErrors.birthDate}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Nome da mãe"
                  className="d-flex"
                  value={editMemberPF.motherName}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      motherName: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.motherName !== ""}
                  helperText={editMemberPFErrors.motherName}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <h4>Informações do Estado Civil</h4>
            <div className="row">
              <div className="col-md-3">
                <FormControl
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                  style={{ width: "100%" }}
                  error={editMemberPFErrors.maritalStatus !== ""}
                >
                  <InputLabel id="maritalStatus_label">Estado civil</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    labelId="maritalStatus_label"
                    value={editMemberPF.maritalStatus}
                    onChange={(e) =>
                      setEditMemberPF({
                        ...editMemberPF,
                        maritalStatus: String(e.target.value),
                        maritalStatusRegime: "",
                      })
                    }
                    error={editMemberPFErrors.maritalStatus !== ""}
                    disabled={
                      loading ||
                      (status !== "Incompleto" && status !== "Em análise")
                    }
                  >
                    <MenuItem value={"Solteiro(a)"}>Solteiro(a)</MenuItem>
                    <MenuItem value={"Casado(a)"}>Casado(a)</MenuItem>
                    <MenuItem value={"Sepadaro(a)/Divorciado(a)"}>
                      Sepadaro(a)/Divorciado(a)
                    </MenuItem>
                    <MenuItem value={"Viúvo(a)"}>Viúvo(a)</MenuItem>
                    <MenuItem value={"União estável"}>União estável</MenuItem>
                  </Select>
                  {editMemberPFErrors.maritalStatus !== "" && (
                    <FormHelperText error>
                      {editMemberPFErrors.maritalStatus}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              {(editMemberPF.maritalStatus === "Casado(a)" ||
                editMemberPF.maritalStatus === "União estável") && (
                <div className="col-md-3" style={{ padding: "0px 5px" }}>
                  <FormControl
                    disabled={
                      loading ||
                      (status !== "Incompleto" && status !== "Em análise")
                    }
                    style={{ width: "100%" }}
                    error={editMemberPFErrors.maritalStatusRegime !== ""}
                  >
                    <InputLabel id="maritalStatusRegime_label">
                      Regime do estado civil
                    </InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      disabled={
                        loading ||
                        (status !== "Incompleto" && status !== "Em análise")
                      }
                      labelId="maritalStatusRegime_label"
                      value={editMemberPF.maritalStatusRegime}
                      onChange={(e) =>
                        setEditMemberPF({
                          ...editMemberPF,
                          maritalStatusRegime: String(e.target.value),
                        })
                      }
                      error={editMemberPFErrors.maritalStatusRegime !== ""}
                    >
                      <MenuItem value={"Comunhão de Bens"}>
                        Comunhão de Bens
                      </MenuItem>
                      <MenuItem value={"Comunhão Parcial de Bens"}>
                        Comunhão Parcial de Bens
                      </MenuItem>
                      <MenuItem value={"Separação total de bens"}>
                        Separação total de bens
                      </MenuItem>
                      <MenuItem value={"União estável"}>União estável</MenuItem>
                    </Select>
                    {editMemberPFErrors.maritalStatusRegime !== "" && (
                      <FormHelperText error>
                        {editMemberPFErrors.maritalStatusRegime}
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              )}
            </div>
            {(editMemberPF.maritalStatus === "Casado(a)" ||
              editMemberPF.maritalStatus === "União estável") &&
              editMemberPF.maritalStatusRegime !== "Separação total de bens" &&
              editMemberPF.maritalStatusRegime !== "" && (
                <fieldset className="mt-4">
                  <h4>Dados do Cônjuge</h4>
                  <div className="row">
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Nome completo"
                        className="d-flex"
                        value={memberSpouses.name}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            name: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.name !== ""}
                        helperText={memberSpousesErrors.name}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="CPF"
                        className="d-flex"
                        value={memberSpouses.cpf}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            cpf: normalizeCpf(e.target.value),
                          })
                        }
                        error={memberSpousesErrors.cpf !== ""}
                        helperText={memberSpousesErrors.cpf}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="RG/RNE"
                        className="d-flex"
                        value={memberSpouses.rg}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            rg: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.rg !== ""}
                        helperText={memberSpousesErrors.rg}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Emissor RG/RNE"
                        className="d-flex"
                        value={memberSpouses.rgEmmiter}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            rgEmmiter: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.rgEmmiter !== ""}
                        helperText={memberSpousesErrors.rgEmmiter}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <FormControl
                        style={{ width: "100%" }}
                        error={memberSpousesErrors.eCpf !== ""}
                      >
                        <InputLabel id="eCpf_label-spouse">eCPF</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          value={memberSpouses.eCpf}
                          onChange={(e) =>
                            setMemberSpouses({
                              ...memberSpouses,
                              eCpf: String(e.target.value),
                            })
                          }
                          error={memberSpousesErrors.eCpf !== ""}
                          labelId="eCpf_label-spouse"
                          disabled={
                            loading ||
                            (status !== "Incompleto" && status !== "Em análise")
                          }
                        >
                          <MenuItem value={"1"}>Possui</MenuItem>
                          <MenuItem value={"0"}>Não Possui</MenuItem>
                        </Select>
                        {memberSpousesErrors.eCpf !== "" && (
                          <FormHelperText error>
                            {memberSpousesErrors.eCpf}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Nacionalidade"
                        className="d-flex"
                        value={memberSpouses.nacionality}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            nacionality: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.nacionality !== ""}
                        helperText={memberSpousesErrors.nacionality}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Profissão"
                        className="d-flex"
                        value={memberSpouses.profission}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            profission: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.profission !== ""}
                        helperText={memberSpousesErrors.profission}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Endereço de e-mail"
                        className="d-flex"
                        value={memberSpouses.email}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            email: e.target.value,
                          })
                        }
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Data de Nascimento"
                        className="d-flex"
                        value={memberSpouses.birthDate}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            birthDate: normalizeDate(e.target.value),
                          })
                        }
                        error={memberSpousesErrors.birthDate !== ""}
                        helperText={memberSpousesErrors.birthDate}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                    <div className="col-md-3 mt-2">
                      <TextField
                        label="Nome da mãe"
                        className="d-flex"
                        value={memberSpouses.motherName}
                        onChange={(e) =>
                          setMemberSpouses({
                            ...memberSpouses,
                            motherName: e.target.value,
                          })
                        }
                        error={memberSpousesErrors.motherName !== ""}
                        helperText={memberSpousesErrors.motherName}
                        disabled={
                          loading ||
                          (status !== "Incompleto" && status !== "Em análise")
                        }
                      />
                    </div>
                  </div>
                </fieldset>
              )}
          </fieldset>
          <fieldset>
            <h4>Pessoas politicamente expostas?</h4>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        editMemberPF.politicalExposedPerson ===
                        "Sou políticamente exposto"
                      }
                      onChange={(e) =>
                        setEditMemberPF({
                          ...editMemberPF,
                          politicalExposedPerson: "Sou políticamente exposto",
                        })
                      }
                      disabled={
                        loading ||
                        (status !== "Incompleto" && status !== "Em análise")
                      }
                      color="primary"
                    />
                  }
                  label="Sou políticamente exposto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        editMemberPF.politicalExposedPerson ===
                        "Não sou politicamente exposto"
                      }
                      onChange={(e) =>
                        setEditMemberPF({
                          ...editMemberPF,
                          politicalExposedPerson:
                            "Não sou politicamente exposto",
                        })
                      }
                      disabled={
                        loading ||
                        (status !== "Incompleto" && status !== "Em análise")
                      }
                      color="primary"
                    />
                  }
                  label="Não sou politicamente exposto"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        editMemberPF.politicalExposedPerson ===
                        "Tenho relacionamento com alguém que é políticamente exposto"
                      }
                      onChange={(e) =>
                        setEditMemberPF({
                          ...editMemberPF,
                          politicalExposedPerson:
                            "Tenho relacionamento com alguém que é políticamente exposto",
                        })
                      }
                      disabled={
                        loading ||
                        (status !== "Incompleto" && status !== "Em análise")
                      }
                      color="primary"
                    />
                  }
                  label="Tenho relacionamento com alguém que é políticamente exposto"
                />
              </FormGroup>
            </FormControl>
          </fieldset>
          <fieldset>
            <h4>Endereço do Sócio</h4>
            <div className="row">
              <div className="col-md-3 mt-2">
                <TextField
                  label="CEP"
                  value={editMemberPF.cep}
                  required
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      cep: normalizeCep(e.target.value),
                    })
                  }
                  onBlur={handleCep}
                  error={editMemberPFErrors.cep !== ""}
                  helperText={editMemberPFErrors.cep}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Logradouro"
                  value={editMemberPF.logradouro}
                  required
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      logradouro: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.logradouro !== ""}
                  helperText={editMemberPFErrors.logradouro}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Número"
                  value={editMemberPF.numero}
                  required
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      numero: e.target.value,
                    })
                  }
                  error={editMemberPFErrors.numero !== ""}
                  helperText={editMemberPFErrors.numero}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Complemento"
                  value={editMemberPF.complemento}
                  onChange={(e) =>
                    setEditMemberPF({
                      ...editMemberPF,
                      complemento: e.target.value,
                    })
                  }
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <StateSelect
                  label="Estado"
                  value={editMemberPF.estado}
                  onChange={(e: any) =>
                    setEditMemberPF({ ...editMemberPF, estado: e.target.value })
                  }
                  error={editMemberPFErrors.estado !== ""}
                  helperText={editMemberPFErrors.estado}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                  required
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Cidade"
                  value={editMemberPF.cidade}
                  required
                  onChange={(e) =>
                    setEditMemberPF({ ...editMemberPF, cidade: e.target.value })
                  }
                  error={editMemberPFErrors.cidade !== ""}
                  helperText={editMemberPFErrors.cidade}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
              <div className="col-md-3 mt-2">
                <TextField
                  label="Bairro"
                  value={editMemberPF.bairro}
                  required
                  onChange={(e) =>
                    setEditMemberPF({ ...editMemberPF, bairro: e.target.value })
                  }
                  error={editMemberPFErrors.bairro !== ""}
                  helperText={editMemberPFErrors.bairro}
                  disabled={
                    loading ||
                    (status !== "Incompleto" && status !== "Em análise")
                  }
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            loading || (status !== "Incompleto" && status !== "Em análise")
          }
        >
          Salvar
        </Button>
        <Button
          variant="outlined"
          className="ml-2"
          onClick={() => resetInfos()}
          color="primary"
          disabled={loading}
        >
          Voltar
        </Button>
      </div>
      {status !== "Incompleto" && status !== "Em análise" && (
        <small className="d-block text-muted mt-4">
          Deseja atualizar os dados cadastrais?{" "}
          <a
            href="https://wa.me/558140072614"
            rel="noopener noreferrer"
            target="_blank"
          >
            Clique aqui para falar com um de nossos atendentes
          </a>
        </small>
      )}
    </>
  );
};

export default memo(EditMemberPF);
