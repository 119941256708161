import styled, { keyframes } from 'styled-components';

const fadeTop = keyframes`
0% {
    transform:translateY(-30px);
    opacity:0;
  }100%{
    transform:translateY(0);
    opacity:1;
  }
`;

export const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  flex:1;
  min-height:100vh;
  width:100%;
  overflow:hidden;
  div#wrap-login {
    display:flex;
    flex:1;
    width:100%;
    div.logo-section {
      display:flex;
      flex:0.5;
      background:none;
      justify-content:center;
      align-items:center;
      position:relative;
      img {
        z-index:20;
        max-width:200px;
        max-height:150px;
        width: auto;
        height: auto;
      }
      &::after {
        content:'';
        position:absolute;
        top:0;
        right:0;
        width:200%;
        height:100%;
        background:linear-gradient(180deg, #3757a1 0%, #06205d 100%);
        border-bottom-right-radius:50%;
      }
      &::before {
        content:'';
        position:absolute;
        top:80px;
        right:0;
        width:200%;
        height:100%;
        background:rgb(55 87 161 / 50%);
        border-bottom-right-radius:50%;
      }
    }
    div.form-container {
      width:100%;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex:1.5;
      
      h4{
        text-transform:uppercase;
        text-align:center;
        margin-bottom:20px;
        color:#3757a1;
        font-weight:700;
        animation:${fadeTop} 300ms linear;
        animation-fill-mode:backwards;
        animation-delay:150ms;
      }
      div.button-wrap {
          display:flex;
          justify-content: center;
          align-items: center;
          margin-top:20px;
          button {
            width:100%;
            animation:${fadeTop} 300ms linear;
            animation-fill-mode:backwards;
            animation-delay:300ms;
          }
          a {
            color:#3757a1;
            text-align:center;
            display:flex;
            justify-content:center;
            align-items:center;
            padding:10px;
            margin-top:20px;
            border:1px solid #3757a1;
            border-radius:4px;
            text-transform:uppercase;
            font-size:14px;
            text-decoration:none !important;
            animation:${fadeTop} 300ms linear;
            animation-fill-mode:backwards;
            animation-delay:450ms;
          }
        }
    }
  }
  @media screen and (max-width:1000px) {
    & {
      overflow:unset;
      div#wrap-login {
        flex-wrap:wrap;
        div.logo-section {
          flex:unset;
          width:100%;
          max-height:100px;
          justify-content:flex-start;
          padding:15px;
          img {
            height:100%;
          }
          &::before {
            top:30px;
          }
        }
      }
    }
  }
`;
