import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ResponsivePie } from '@nivo/pie';
import { format } from 'date-fns';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import useSelector from '~/store/useSelector';

import animationData from '~/animations/dashboard_animation.json';

import { Container } from './styles';
import { normalizeCurrency, normalizeCpfCnpj } from '../utils/normalize';
import { editUnActive } from '~/store/modules/register/actions';
import { signOut } from '~/store/modules/auth/actions';

interface ITitle {
  id: string;
  titleStatus: {
    id: number;
    description: string;
  };
  payerJson: string;
  value: number;
  originalValue: number;
  date: Date;
}

interface IOperation {
  descriptionStatus: string;
  totalOpenValue: number;
  borderoId: number;
  borderoDate: number;
}

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [filterTitle, setFilterTitle] = useState('Todos');
  const [titles, setTitles] = useState<ITitle[]>([]);
  const [operations, setOperations] = useState<IOperation[]>([]);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    async function loadDashboard() {
      setLoading(true);
      try {
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        const { data: dataTitles } = await axios.get(
          `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/titles?all=1&titleStatusId=3,11&order=ASC`
        );
        setTitles(dataTitles);
        const { data: dataOperations } = await axios.get(
          `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/company/me?dash=1&page=1&perPage=5`
        );
        setOperations(dataOperations);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    loadDashboard();
  }, [token, dispatch]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    dispatch(editUnActive());
  }, []); // eslint-disable-line

  return (
    <Container>
      {!loading ? (
        operations.length === 0 ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="widget-about">
                  <div className="widget-about__box_text">
                    <p>
                      Uma plataforma para você
                      <br />
                      antecipar seus recebíveis.
                    </p>
                    <strong>Venda a prazo, receba à vista!</strong>
                  </div>
                  <div className="widget-about__box_illustrator">
                    <div className="actor">
                      <img src="/assets/images/actor.png" alt="" />
                    </div>
                    <div className="bigPlant">
                      <img src="/assets/images/big-plant.png" alt="" />
                    </div>
                    <div className="smallPlant">
                      <img src="/assets/images/small-plant.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-8">
                <div className="widget-anticipation-sale">
                  <div className="widget-anticipation-sale__box_text">
                    <p>
                      Vamos
                      <br /> começar a{' '}
                      <strong>
                        adiantar os <br /> recebíveis?
                      </strong>
                    </p>
                    <a href="/operacoes/novo">quero antecipar</a>
                  </div>
                  <div className="widget-anticipation-sale__box_illustrator">
                    <img src="/assets/images/antecipe.png" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="widget-whatsapp">
                  <div className="widget-whatsapp__box_text">
                    <p>
                      Ainda <br />
                      com <strong>dúvidas?</strong>
                    </p>
                    <a
                      href="https://api.whatsapp.com/send?phone=558140072614"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      whatsapp
                    </a>
                  </div>
                  <div className="widget-whatsapp__box_illustrator">
                    <img src="/assets/images/suporte-whatsapp.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-sm-12 col-lg-8">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <div className="widget-services">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h4>Consulta de Empresa</h4>
                          <p>
                            Consultas cadastrais de CNPJ junto à Receita
                            Federal.
                          </p>
                          <a className="btn" href="/servicos/empresa">
                            Consultar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <div className="widget-services">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h4>Consulta de Protesto</h4>
                          <p>
                            Consultas de restrições cadastrais junto à central
                            de protesto.
                          </p>
                          <a className="btn" href="/servicos/protesto">
                            Consultar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="widget-titles">
                    <div className="row">
                      <div className="col-12">
                        <div className="widget-titles__header">
                          <h4>Títulos em Aberto</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4" style={{ height: '450px' }}>
                        <ResponsivePie
                          data={[
                            {
                              id: 'A vencer',
                              label: 'A vencer',
                              value: titles.filter(
                                (title) => title.titleStatus.id === 3
                              ).length,
                              color: 'hsl(24, 70%, 50%)',
                            },
                            {
                              id: 'Vencidos',
                              label: 'Vencidos',
                              value: titles.filter(
                                (title) => title.titleStatus.id === 11
                              ).length,
                              color: 'hsl(138, 70%, 50%)',
                            },
                          ]}
                          startAngle={180}
                          margin={{ top: 40, right: 0, bottom: 40, left: 80 }}
                          innerRadius={0.5}
                          padAngle={0.7}
                          cornerRadius={3}
                          colors={{ scheme: 'nivo' }}
                          borderWidth={1}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.2]],
                          }}
                          radialLabelsSkipAngle={10}
                          radialLabelsTextXOffset={0}
                          radialLabelsTextColor="#333333"
                          radialLabelsLinkOffset={0}
                          radialLabelsLinkDiagonalLength={0}
                          radialLabelsLinkHorizontalLength={24}
                          radialLabelsLinkStrokeWidth={1}
                          radialLabelsLinkColor={{ from: 'color' }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor="#333333"
                          animate
                          motionStiffness={90}
                          motionDamping={15}
                          defs={[
                            {
                              id: 'dots',
                              type: 'patternDots',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              size: 4,
                              padding: 1,
                              stagger: true,
                            },
                            {
                              id: 'lines',
                              type: 'patternLines',
                              background: 'inherit',
                              color: 'rgba(255, 255, 255, 0.3)',
                              rotation: -45,
                              lineWidth: 6,
                              spacing: 10,
                            },
                          ]}
                          fill={[
                            {
                              match: {
                                id: 'ruby',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'c',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'go',
                              },
                              id: 'dots',
                            },
                            {
                              match: {
                                id: 'python',
                              },
                              id: 'dots',
                            },
                          ]}
                        />
                      </div>
                      <div className="col-8" style={{ height: '450px' }}>
                        <div className="widget-titles__box_body">
                          <ul>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'Todos' && 'active'
                                }`}
                                onClick={() => setFilterTitle('Todos')}
                              >
                                Todos
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'A vencer' && 'active'
                                }`}
                                onClick={() => setFilterTitle('A vencer')}
                              >
                                A vencer
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                className={`btn btn-sm ${
                                  filterTitle === 'Vencidos' && 'active'
                                }`}
                                onClick={() => setFilterTitle('Vencidos')}
                              >
                                Vencidos
                              </button>
                            </li>
                          </ul>
                          <div>
                            <table>
                              <thead>
                                <tr>
                                  <th className="text-left">Sacado</th>
                                  <th className="text-center">Valor</th>
                                  <th className="text-center">Vencimento</th>
                                  <th className="text-center">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {titles
                                  .filter((title) => {
                                    if (filterTitle === 'A vencer') {
                                      if (title.titleStatus.id === 3) {
                                        return true;
                                      }
                                      return false;
                                    }
                                    if (filterTitle === 'Vencidos') {
                                      if (title.titleStatus.id === 11) {
                                        return true;
                                      }
                                      return false;
                                    }
                                    return true;
                                  })
                                  .map((title, index) => {
                                    // eslint-disable-line
                                    if (
                                      title.titleStatus.id === 3 ||
                                      title.titleStatus.id === 11
                                    ) {
                                      return (
                                        <tr key={String(index)}>
                                          <td className="text-left">
                                            {title.payerJson
                                              ? JSON.parse(title.payerJson).name
                                              : '-'}
                                            <span>
                                              {title.payerJson
                                                ? normalizeCpfCnpj(
                                                    JSON.parse(title.payerJson)
                                                      .document
                                                  )
                                                : '-'}
                                            </span>
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            R${' '}
                                            {normalizeCurrency(
                                              Math.round(
                                                title.value === 0
                                                  ? title.originalValue
                                                  : title.value
                                              )
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {format(
                                              new Date(title.date),
                                              'dd/MM/yyyy'
                                            )}
                                          </td>
                                          <td
                                            className="text-center"
                                            style={{ whiteSpace: 'nowrap' }}
                                          >
                                            {title.titleStatus.id === 3 && (
                                              <small className="avencer">
                                                A vencer
                                              </small>
                                            )}
                                            {title.titleStatus.id === 11 && (
                                              <small className="ematraso">
                                                Vencido
                                              </small>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    return '';
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4">
              <div className="row">
                <div className="col-12">
                  <div className="widget-calendar">
                    <div className="widget-calendar__box">
                      <div className="widget-calendar__box_header">
                        <h4>Últimas Operações</h4>
                        <a className="btn" href="/operacoes/lista">
                          Ver todas
                        </a>
                      </div>
                      <div className="widget-calendar__box_body">
                        <table className="table table-striped">
                          <tbody>
                            {operations.length > 0 &&
                              operations.map((operation) => (
                                <tr key={operation.borderoId}>
                                  <td className="pl-2">
                                    {operation.borderoId}
                                  </td>
                                  <td>
                                    R${' '}
                                    {normalizeCurrency(
                                      operation.totalOpenValue
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {format(
                                      new Date(operation.borderoDate),
                                      'dd/MM/yyyy'
                                    )}
                                  </td>
                                  <td>{operation.descriptionStatus}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="widget-whatsapp">
                    <div className="widget-whatsapp__box_text">
                      <p>
                        Ainda <br />
                        com <strong>dúvidas?</strong>
                      </p>
                      <a
                        href="https://api.whatsapp.com/send?phone=558140072614"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        whatsapp
                      </a>
                    </div>
                    <div className="widget-whatsapp__box_illustrator">
                      <img src="/assets/images/suporte-whatsapp.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="loading">
          <Lottie options={defaultOptions} height={400} width={400} />
          <div className="d-flex">
            <CircularProgress />
            <h2 className="ml-2">
              Estamos Montando seu Painel. Aguarde um instante...
            </h2>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Home;
