import React, { useEffect, useState } from 'react';

import { QuestionAnswer, Mail, Phone, Print } from '@material-ui/icons';
import { Table } from 'react-bootstrap';
import { format, addMinutes } from 'date-fns';
import axios from 'axios';
import Lottie from 'react-lottie';
import animationData from '~/animations/searching_files.json';
import { Container } from './styles';

import { IOperation } from '../../operation';

import { normalizeCurrency, normalizeCpfCnpj } from '../../../utils/normalize';
import printBodero from '../../utils/printBordero';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  operationId?: string;
}

const ModalDetail: React.FC<IProps> = ({ open, setOpen, operationId }) => {
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState<IOperation | null>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get<IOperation>(
          `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/${operationId}`
        );
        setOperation(data);
      } catch (err) {}
      setLoading(false);
    }
    if (operationId) {
      loadData();
    } else {
      setOperation(null);
    }
  }, [operationId]);

  return (
    <Container show={open} onHide={() => setOpen(false)} centered size="xl">
      <Container.Header closeButton className="bg-white" />
      {loading && (
        <Container.Body className="bg-white">
          <Lottie options={defaultOptions} height={300} width={300} />
        </Container.Body>
      )}
      {!loading && operation && (
        <Container.Body
          style={{ background: 'none', padding: '0px', margin: '0px' }}
        >
          <div
            className="d-flex flex-wrap justify-content-between align-items-center bg-white"
            id="header_summary"
          >
            <div>
              <h4>Borderô: {operation.borderoId}</h4>
              <h6 className="status_operation">
                {operation.operationStatus.description}
              </h6>
            </div>
            <div>
              {operation.totalValue && (
                <h5>
                  <strong>Vlr. Total:</strong> R${' '}
                  {normalizeCurrency(Math.round(operation.totalValue))}
                </h5>
              )}
              {operation.totalNetValue && (
                <h5>
                  <strong>Vlr. Líquido:</strong> R${' '}
                  {normalizeCurrency(Math.round(operation.totalNetValue))}
                </h5>
              )}
              <button type="button" onClick={() => printBodero(operation)}>
                <Print /> Imprimir
              </button>
            </div>
          </div>
          <div id="body_content" className="bg-white">
            <div>
              <h5>Títulos ({operation.titles.length})</h5>
              <div className="mt-3">
                <Table hover responsive="lg">
                  <thead>
                    <tr>
                      <th>Sacado</th>
                      <th>Data vencimento</th>
                      <th>Valor</th>
                      <th>Documento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operation.titles.map((title) => {
                      const payer = JSON.parse(title.payerJson);
                      return (
                        <tr key={title.id}>
                          <td>
                            {payer.name}
                            <strong style={{ display: 'block' }}>
                              {normalizeCpfCnpj(payer.document)}
                            </strong>
                          </td>
                          <td>
                            {title.date
                              ? format(
                                  addMinutes(
                                    new Date(title.date),
                                    new Date(title.date).getTimezoneOffset()
                                  ),
                                  'dd/MM/yyyy'
                                )
                              : '-'}
                          </td>
                          <td>
                            R$ {normalizeCurrency(Math.round(title.value))}
                          </td>
                          <td>{title.document}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div id="footer_content">
            <div className="d-flex align-items-center justify-content-center">
              <QuestionAnswer className="mr-4" />
              <div id="text_footer">
                <h3>Está com dúvidas?</h3>
                <p>
                  Entre em contato através do chat ou contate-nos em um dos
                  meios abaixo.
                </p>
                <div className="d-flex flex-wrap">
                  <span>
                    <Mail /> contato@grupobfc.com.br
                  </span>
                  <span className="ml-4">
                    <Phone /> 4007-2614
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container.Body>
      )}
    </Container>
  );
};

export default ModalDetail;
