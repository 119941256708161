import React, { useEffect, useState } from 'react';

import { Close } from '@material-ui/icons';
import {
  CircularProgress,
  CircularProgressProps,
  Box,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setTypeEmission } from '../../../../../store/modules/user/actions';

import useSelector from '~/store/useSelector';

interface IProps {
  file: File;
  index: number;
  handleRemove: (index: number) => void;
}

const ModalXml: React.FC<IProps> = ({ file, index, handleRemove }) => {
  const [progress, setProgress] = useState(Math.floor(Math.random() * 101));
  const [error, setError] = useState({ active: false, message: '' });
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  const { typeEmission, cnpj } = useSelector((state) => state.user);

  function onUpload() {
    let filesUploaded = sessionStorage.getItem('xml_uploaded');
    if (!filesUploaded) {
      filesUploaded = '[]';
    }
    const arrFiles: string[] = JSON.parse(filesUploaded);

    if (!arrFiles.find((i) => i === file.name)) {
      arrFiles.push(file.name);
      sessionStorage.setItem('xml_uploaded', JSON.stringify(arrFiles));
    }
  }

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <small className="text-muted" style={{ fontSize: '8px' }}>
            {`${Math.round(props.value)}%`}
          </small>
        </Box>
      </Box>
    );
  }

  async function handleTypeEmission() {
    let validated = false;
    let errorValidation = '';
    const objData = new FormData();
    objData.append('xmls', file);
    try {
      await axios.post(`/nfes/xml/upload`, objData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total));
        },
      });
      dispatch(setTypeEmission('nfe'));
      await axios.put(`/companies/typeemission`, {
        cnpj,
        typeEmission: 'nfe',
      });
      validated = true;
    } catch (err) {
      if (err.response && err.response.status !== 500) {
        errorValidation = err.response.data.message;
      }
    }
    try {
      if (!validated) {
        await axios.post(`/ctes/xml/upload`, objData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            setProgress(Math.round((100 * e.loaded) / e.total));
          },
        });
        dispatch(setTypeEmission('cte'));
        await axios.put(`/companies/typeemission`, {
          cnpj,
          typeEmission: 'cte',
        });
      }
      onUpload();
    } catch (err) {
      if (err.response && err.response.status !== 500) {
        errorValidation = err.response.data.message;
      }
    }
    if (errorValidation !== '') {
      setError({
        active: true,
        message: errorValidation,
      });
    } else {
      setSuccess(true);
      onUpload();
    }
  }

  async function uploadItem() {
    setError({
      active: false,
      message: '',
    });
    try {
      if (typeEmission === null) {
        await handleTypeEmission();
      } else {
        const objData = new FormData();
        objData.append('xmls', file);
        await axios.post(`/${typeEmission}s/xml/upload`, objData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            setProgress(Math.round((100 * e.loaded) / e.total));
          },
        });
        setSuccess(true);
      }
    } catch (err) {
      if (err.response && err.response.status !== 500) {
        setError({
          active: true,
          message: err.response.data.message,
        });
      } else if (
        (err.response &&
          err.response.data.err ===
            "Cannot read property 'protNFe' of undefined") ||
        (err.response &&
          err.response.data.err ===
            "Cannot read property 'cteProc' of undefined")
      ) {
        setError({
          active: true,
          message: 'Arquivo inválido',
        });
      } else {
        setError({
          active: true,
          message: 'Ocorreu um erro ao enviar, tente novamente',
        });
      }
    }
    onUpload();
  }

  useEffect(() => {
    const uploadedString = sessionStorage.getItem('xml_uploaded');
    const uploaded: string[] = JSON.parse(uploadedString || '[]');
    if (file && !uploaded.find((i) => i === file.name)) {
      uploadItem();
    } else if (uploaded.find((i) => i === file.name)) {
      setError({
        active: true,
        message: 'Arquivo já enviado',
      });
    }
  }, [file, typeEmission]); // eslint-disable-line

  return (
    <div key={index} className="file_item">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          {!success && (
            <button type="button" onClick={() => handleRemove(index)}>
              <Close />
            </button>
          )}
          <h4
            className={`mb-0 ${error.active ? 'text-danger' : ''} ${
              success ? 'text-success' : ''
            }`}
          >
            {file.name}
          </h4>
          {error.active && (
            <i className="d-block ml-2">
              <small className="text-muted">{error.message}</small>
            </i>
          )}
        </div>
        {progress >= 100 && !success && !error.active && (
          <CircularProgress size="24px" />
        )}
        {progress < 100 && !error.active && (
          <CircularProgressWithLabel
            variant="determinate"
            value={progress}
            size="24px"
          />
        )}
      </div>
    </div>
  );
};

export default ModalXml;
