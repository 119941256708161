import React, { useState, useEffect } from 'react';

import { Modal } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import {
  Warning,
  InfoOutlined,
  Description,
  AssignmentInd,
} from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { toast } from 'react-toastify';
import { signOut } from '../../../../../../store/modules/auth/actions';
import useSelector from '~/store/useSelector';

interface ISetOpen {
  (open: boolean): void;
}
interface itemDocumentsInt {
  id: string;
  label: string;
  status: string;
  file: string;
  rejectReasons: string[];
}
interface IOwner {
  type: 'company' | 'pf' | 'pj';
  id: string;
}
interface IProps {
  item: itemDocumentsInt;
  open: boolean;
  setOpen: ISetOpen;
  onResolve: () => void;
  owner: IOwner;
}

const ModalPendency: React.FC<IProps> = ({
  item,
  owner,
  open,
  setOpen,
  onResolve,
}) => {
  const { cnpj } = useSelector((state) => state.user);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (open) {
      if (item.status !== 'REJECTED') {
        setOpen(false);
        setStep(1);
      }
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    async function uploadFile() {
      setLoading(true);
      if (!file) {
        return;
      }
      try {
        const objData = new FormData();
        objData.append('file', file);
        const { data } = await axios.patch(
          `/documents/pendency/${item.id}/${cnpj}/file`,
          objData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        item.file = data.file;
        setFile(null);
        toast.info('Arquivo enviado');
      } catch (err) {
        setFile(null);
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    if (item.id && file) {
      uploadFile();
    }
  }, [file, item.id]); // eslint-disable-line

  useEffect(() => {
    if (open) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get('pendency') === '1') {
        setStep(2);
      }
    }
  }, [open]);

  async function handleResolvePendency() {
    setLoading(true);
    try {
      await axios.put(`/documents/documents/pendency/${item.id}`);
      onResolve();
      toast.info('Pendência resolvida');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  function resetModal() {
    setOpen(false);
    setStep(1);
  }

  return (
    <Modal
      show={open}
      onHide={resetModal}
      backdrop="static"
      centered
      size={step === 1 ? 'lg' : 'xl'}
    >
      <Modal.Body className="text-center">
        {step === 1 && (
          <>
            <div
              className="d-flex align-items-center justify-content-center mt-4"
              style={{ padding: '10px 25px' }}
            >
              <Warning color="primary" style={{ fontSize: '50px' }} />
              <h4 className="text-left ml-4">
                Uma pendência foi enviada por um de nossos analistas acerca
                deste documento:
              </h4>
            </div>
            <hr />
            <div
              className="d-flex flex-column align-items-center justify-content-center text-center"
              style={{
                padding: '30px',
                margin: '20px 0px',
                background: '#fafafa',
                borderTop: '10px solid #eee',
                borderBottom: '10px solid #eee',
              }}
            >
              <h5>{item.label}</h5>
              <img
                src={`https://bfcgru-s3-assinatura-eletronica.s3.amazonaws.com/BFCDIGITAL/DOCUMENTS/${cnpj.replace(
                  /\D/g,
                  ''
                )}/${item.file}`}
                alt={item.label}
                style={{ height: '100%', maxHeight: '40vh' }}
              />
              <small>
                Caso o documento não carregue,{' '}
                <a
                  href={`https://bfcgru-s3-assinatura-eletronica.s3.amazonaws.com/BFCDIGITAL/DOCUMENTS/${cnpj.replace(
                    /\D/g,
                    ''
                  )}/${item.file}`}
                  target="blank"
                >
                  Clique aqui
                </a>
              </small>
            </div>
            <hr />
            <div className="d-flex justify-content-center mb-4">
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => setStep(2)}
              >
                Resolver Pendência
              </Button>
              <Button
                type="button"
                color="primary"
                className="ml-2"
                variant="outlined"
                onClick={resetModal}
              >
                Fechar
              </Button>
            </div>
          </>
        )}
        {step === 2 && (
          <div className="row">
            <div
              className="col-md-4"
              style={{
                padding: '25px',
                background: '#fafafa',
                borderRight: '4px solid #ffb300',
              }}
            >
              <h5>Documento</h5>
              <small>{item.label}</small>
              <img
                src={`https://bfcgru-s3-assinatura-eletronica.s3.amazonaws.com/BFCDIGITAL/DOCUMENTS/${cnpj.replace(
                  /\D/g,
                  ''
                )}/${item.file}`}
                alt={item.label}
                style={{ width: '100%', maxWidth: '600px' }}
              />
              <small>
                Caso o documento não carregue,{' '}
                <a
                  href={`https://bfcgru-s3-assinatura-eletronica.s3.amazonaws.com/BFCDIGITAL/DOCUMENTS/${cnpj.replace(
                    /\D/g,
                    ''
                  )}/${item.file}`}
                  target="blank"
                >
                  Clique aqui
                </a>
              </small>
            </div>
            <div className="col-md-8 text-left" style={{ padding: '25px' }}>
              <h4 className="mb-4">
                Mensagem do nosso analista acerca do Documento:
              </h4>
              <p style={{ padding: '10px 15px', background: '#f9f9f9' }}>
                <i>{item.rejectReasons[0]}</i>
              </p>
              <hr />
              <small>
                <InfoOutlined /> Ficou em dúvida? Ligue para nossa central de
                atendimento: <strong>(**) * ****-****</strong>
              </small>
              <hr />
              <div className="d-flex flex-wrap">
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  onClick={() =>
                    history.push(
                      `${
                        owner.type === 'company'
                          ? `/cadastro/informacao-geral?pendency=1&id=${owner.id}&doc=${item.id}`
                          : `/cadastro/socios?pendency=1&id=${owner.id}&doc=${item.id}`
                      }`
                    )
                  }
                  disabled={loading}
                >
                  <AssignmentInd className="mr-2" />
                  {owner.type === 'company' && 'Revisar dados da empresa'}
                  {(owner.type === 'pf' || owner.type === 'pj') &&
                    'Revisar dados do sócio'}
                </Button>
                <label
                  htmlFor="doc_upload"
                  id="label_doc"
                  style={{ margin: '0px', padding: '0px' }}
                >
                  <Button
                    type="button"
                    color="primary"
                    className="ml-2"
                    variant="outlined"
                    disabled={loading}
                    onClick={() => {
                      document.getElementById('label_doc')?.click();
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        color="primary"
                        size="16px"
                        className="mr-2"
                      />
                    ) : (
                      <Description className="mr-2" />
                    )}
                    Reenviar documento
                  </Button>
                </label>
                <input
                  type="file"
                  id="doc_upload"
                  onChange={(e) => {
                    setFile(e.target && e.target.files && e.target.files[0]);
                  }}
                  style={{ display: 'none' }}
                />
                <Button
                  type="button"
                  color="secondary"
                  className="ml-auto"
                  variant="contained"
                  disabled={loading}
                  onClick={() => setStep(3)}
                >
                  Finalizar
                </Button>
              </div>
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="text-center" style={{ padding: '20px 15px' }}>
            <h4>Tem certeza que deseja finalizar esta pendência?</h4>
            <p>
              Certifique-se de que as requisições de nosso analista foram
              atendidas.
            </p>
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="button"
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={handleResolvePendency}
              >
                {loading && (
                  <CircularProgress
                    color="primary"
                    size="16px"
                    className="mr-2"
                  />
                )}{' '}
                Sim, finalizar
              </Button>
              <Button
                type="button"
                color="primary"
                className="ml-2"
                variant="outlined"
                disabled={loading}
                onClick={() => setStep(2)}
              >
                Voltar
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalPendency;
