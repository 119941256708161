import React, { useState, useEffect } from 'react';

import { Form, Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';

import axios from 'axios';

import { isBefore } from 'date-fns';

import { Alert } from '@material-ui/lab';
import { Button, CircularProgress } from '@material-ui/core';
import consultCep from '../../../../../services/consultCep';
import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeAccBank,
  normalizeDate,
  normalizeNumber,
  normalizeCep,
  normalizeTelephone,
  normalizeCmc7,
} from '../../utils/normalize';
import { cmc7IsValid } from '../../utils/validation';
import { signOut } from '../../../../../store/modules/auth/actions';
import { Container } from './styles';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  onAdd: () => void;
}

const ModalTapNote: React.FC<IProps> = ({ open, setOpen, onAdd }) => {
  const [values, setValues] = useState<any>({});
  const [payerExists, setPayerExists] = useState(true);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const dispatch = useDispatch();

  function resetForm() {
    setValues({});
    setPayerExists(true);
    setOpen(false);
    setError('');
    setSuccess(false);
  }

  const states = [
    { uf: 'AC', desc: 'Acre' },
    { uf: 'AL', desc: 'Alagoas' },
    { uf: 'AP', desc: 'Amapá' },
    { uf: 'AM', desc: 'Amazonas' },
    { uf: 'BA', desc: 'Bahia' },
    { uf: 'CE', desc: 'Ceará' },
    { uf: 'DF', desc: 'Distrito Federal' },
    { uf: 'ES', desc: 'Espírito Santo' },
    { uf: 'GO', desc: 'Goiás' },
    { uf: 'MA', desc: 'Maranhão' },
    { uf: 'MT', desc: 'Mato Grosso' },
    { uf: 'MS', desc: 'Mato Grosso do Sul' },
    { uf: 'MG', desc: 'Minas Gerais' },
    { uf: 'PA', desc: 'Pará' },
    { uf: 'PB', desc: 'Paraíba' },
    { uf: 'PR', desc: 'Paraná' },
    { uf: 'PE', desc: 'Pernambuco' },
    { uf: 'PI', desc: 'Piauí' },
    { uf: 'RJ', desc: 'Rio de Janeiro' },
    { uf: 'RN', desc: 'Rio Grande do Norte' },
    { uf: 'RS', desc: 'Rio Grande do Sul' },
    { uf: 'RO', desc: 'Rondônia' },
    { uf: 'RR', desc: 'Roraima' },
    { uf: 'SC', desc: 'Santa Catarina' },
    { uf: 'SP', desc: 'São Paulo' },
    { uf: 'SE', desc: 'Sergipe' },
    { uf: 'TO', desc: 'Tocantis' },
  ];

  function handleNormalize(name: string, value: string) {
    switch (name) {
      case 'payerDocument':
        return normalizeCpfCnpj(value);
      case 'total':
        return normalizeCurrency(Number(value.replace(/[^\d]/g, '')));
      case 'titleValue':
        return normalizeCurrency(Number(value.replace(/[^\d]/g, '')));
      case 'titleExpiresDate':
        return normalizeDate(value);
      case 'emissionDate':
        return normalizeDate(value);
      case 'documentNumber':
        return normalizeAccBank(value);
      case 'serie':
        return normalizeNumber(value);
      case 'key':
        return normalizeCmc7(value);
      case 'payerZipcode':
        return normalizeCep(value);
      case 'payerPhone1':
        return normalizeTelephone(value);
      case 'payerPhone2':
        return normalizeTelephone(value);
      default:
        return value;
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setSuccess(false);
    const { name, value } = e.target;
    setValues({ ...values, [name]: handleNormalize(name, value) });
  }

  async function handleVerifyPayer() {
    setValues({
      payerName: '',
      payerRg: '',
      payerZipcode: '',
      payerAddress: '',
      payerNumber: '',
      payerComplement: '',
      payerDistrict: '',
      payerCity: '',
      payerUf: '',
      payerPhone1: '',
      payerEmail: '',
    });
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/payers/document/${values.payerDocument.replace(
          /[^\d]/g,
          ''
        )}`
      );
      setValues({
        ...values,
        payerName: data.name,
        payerDocument: values.payerDocument,
        payerRg: data.rgOrStateRegistration,
        payerZipcode: data.address.zipcode,
        payerAddress: data.address.street,
        payerNumber: data.address.number,
        payerComplement: data.address.complement,
        payerDistrict: data.address.district,
        payerCity: data.address.city,
        payerUf: data.address.state,
        payerPhone1: data.phone,
        payerEmail: data.emails[0],
      });
      setPayerExists(true);
    } catch (err) {
      setValues({
        ...values,
        payerDocument: values.payerDocument,
        payerName: '',
        payerRg: '',
        payerZipcode: '',
        payerAddress: '',
        payerNumber: '',
        payerComplement: '',
        payerDistrict: '',
        payerCity: '',
        payerUf: '',
        payerPhone1: '',
        payerEmail: '',
      });
      setPayerExists(false);
    }
  }
  async function handleCepAddress() {
    const consult = await consultCep(values.payerZipcode.replace('-', ''));
    if (consult) {
      setValues({
        ...values,
        payerAddress: consult.logradouro,
        payerDistrict: consult.bairro,
        payerCity: consult.cidade,
        payerUf: consult.estado,
      });
    }
    document.getElementsByName('payerNumber')[0].focus();
  }

  function parseToDate(value: string) {
    const splitedDate = value.split('/');
    return new Date(`${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`);
  }

  async function handleSubmit(e): Promise<any> {
    e.preventDefault();
    if (
      !values.titleExpiresDate ||
      values.titleExpiresDate.length < 10 ||
      !isBefore(new Date(), parseToDate(values.titleExpiresDate))
    ) {
      return setError('Data do cheque inválida');
    }
    if (!cmc7IsValid(values.key)) {
      return setError('CMC7 Inválido');
    }
    let cmc7OnlyNums = '';
    if (values.key) {
      cmc7OnlyNums = normalizeNumber(values.key);
    }
    setLoading(true);
    try {
      const body = {
        check: {
          titleType: 'C',
          titleDocument: values.key && cmc7OnlyNums.slice(11, 17),
          titleDate: parseToDate(values.titleExpiresDate),
          titleValue: values.total.replace(/[^\d]/g, ''),
          titleCmc7: cmc7OnlyNums,
          titleBankNumber: values.key && cmc7OnlyNums.slice(0, 3),
          titleBankAccount: values.key && cmc7OnlyNums.slice(19, 30),
          titleBankNumberAgency: values.key && cmc7OnlyNums.slice(3, 7),
          sacado: {
            payerName: values.payerName,
            payerDocument: values.payerDocument.replace(/[^\d]/g, ''),
            payerRg: values.payerRg,
            payerZipcode: values.payerZipcode,
            payerAddress: values.payerAddress,
            payerNumber: values.payerNumber,
            payerComplement: values.payerComplement,
            payerDistrict: values.payerDistrict,
            payerCity: values.payerCity,
            payerUf: values.payerUf,
            payerPhone1: values.payerPhone1,
            payerEmail: values.payerEmail,
          },
        },
      };
      await axios.post(`/checks/digitation`, body);
      const emptyValues: any = {};
      Object.keys(values).forEach((i) => {
        emptyValues[i] = '';
      });
      setValues(emptyValues);
      setPayerExists(true);
      setSuccess(true);
      onAdd();
      setRefreshing(true);
    } catch (err) {
      if (err.response && err.response.status === 403) {
        setError(err.response.data.message);
      } else if (err.response && err.response.status === 401) {
        toast.error('Sessão expirada, entre novamente');
        dispatch(signOut());
      } else if (err.response && err.response.status === 500) {
        setError(
          'Ocorreu um erro em nossos servidores, contate a equipe técnica'
        );
      } else {
        setError(
          'Ocorreu um erro em nossa aplicação, contate a equipe técnica'
        );
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (refreshing) {
      setTimeout(() => {
        setRefreshing(false);
      }, 500);
    }
  }, [refreshing]);

  return (
    <Container size="lg" show={open} onHide={resetForm} centered>
      {!refreshing && (
        <Form onSubmit={handleSubmit}>
          <Container.Body>
            {error !== '' && <Alert severity="error">{error}</Alert>}
            {success && (
              <Alert severity="success">Cheque adicionado com sucesso</Alert>
            )}
            <small>Dados do Sacado</small>
            <Row>
              <Col lg={4}>
                <Form.Group>
                  <Form.Label>CNPJ/CPF</Form.Label>
                  <Form.Control
                    name="payerDocument"
                    value={values.payerDocument}
                    onChange={handleChange}
                    disabled={loading}
                    onBlur={handleVerifyPayer}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={8}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    name="payerName"
                    value={values.payerName}
                    onChange={handleChange}
                    disabled={payerExists || loading}
                    required
                  />
                </Form.Group>
              </Col>
              {!payerExists && (
                <>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>RG/CGF</Form.Label>
                      <Form.Control
                        name="payerRg"
                        value={values.payerRg}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        name="payerZipcode"
                        value={values.payerZipcode}
                        onChange={handleChange}
                        disabled={loading}
                        onBlur={handleCepAddress}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        name="payerAddress"
                        value={values.payerAddress}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        name="payerNumber"
                        value={values.payerNumber}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        name="payerComplement"
                        value={values.payerComplement}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        name="payerDistrict"
                        value={values.payerDistrict}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={3}>
                    <Form.Group>
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        name="payerCity"
                        value={values.payerCity}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Form.Group>
                      <Form.Label>UF</Form.Label>
                      <Form.Control
                        name="payerUf"
                        as="select"
                        value={values.payerUf}
                        onChange={handleChange}
                        disabled={loading}
                      >
                        <option />
                        {states.map((s) => (
                          <option key={s.uf} value={s.uf}>
                            {s.desc}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        name="payerPhone1"
                        value={values.payerPhone1}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>Telefone 2</Form.Label>
                      <Form.Control
                        name="payerPhone2"
                        value={values.payerPhone2}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="email"
                        name="payerEmail"
                        value={values.payerEmail}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </Form.Group>
                  </Col>
                </>
              )}
            </Row>
            <br />
            <small>Dados do Cheque</small>
            <Row>
              <Col lg={6}>
                <Form.Group>
                  <Form.Label>CMC7</Form.Label>
                  <Form.Control
                    name="key"
                    value={values.key}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Vencimento</Form.Label>
                  <Form.Control
                    name="titleExpiresDate"
                    value={values.titleExpiresDate}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Valor Total</Form.Label>
                  <Form.Control
                    name="total"
                    value={values.total}
                    onChange={handleChange}
                    disabled={loading}
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Banco</Form.Label>
                  <Form.Control
                    name="bank"
                    value={values.key && values.key.slice(1, 4)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Documento</Form.Label>
                  <Form.Control
                    name="doc_check"
                    value={values.key && values.key.slice(13, 19)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    name="agency"
                    value={values.key && values.key.slice(4, 8)}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group>
                  <Form.Label>Conta</Form.Label>
                  <Form.Control
                    name="account"
                    value={values.key && values.key.slice(21, 32)}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container.Body>
          <Container.Footer>
            <div className="d-flex justify-content-between">
              {loading ? <CircularProgress /> : <span />}
              <div>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={loading}
                >
                  Enviar
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={loading}
                  onClick={resetForm}
                  className="ml-2"
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Container.Footer>
        </Form>
      )}
    </Container>
  );
};

export default ModalTapNote;
