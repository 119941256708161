import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #titles_table_wrap {
    > div:nth-child(1) {
      padding: 30px;
      p {
        margin: 0px;
      }
    }
    .content_component {
      display: flex;
      flex-direction: column;
      padding: 0 0 10px;
      .filters {
        margin: 0 30px 30px;
        label {
          margin: 0;
          font-size: 14px;
          color: #555;
        }
        .input-group {
          input {
          }
        }
      }
      table {
        thead {
          tr {
            background: rgb(55 87 161 / 20%);
            th {
              padding: 10px 7.5px;
              border: none;
              font-weight: 500;
              font-size: 14px;
              text-align: center;
              vertical-align: middle;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 10px 7.5px;
              text-align: center;
              vertical-align: middle;
              font-size: 14px;
            }
          }
        }
      }
      .pagination {
        justify-content: center;
        li.page-item {
          .page-link {
            color: #3757a1;
          }
          &.active {
            .page-link {
              background: #3757a1;
              border-color: #3757a1;
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
