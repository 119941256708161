import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  height: auto;
  max-height: '500px';
  background-color: ${props => props.theme.colors.background};
  color:${props => props.theme.colors.text};
  border: none;
  box-shadow: 0px 0px 10px ${props => props.theme.title === 'light' ? 'rgba(0,0,0,.4)' : 'rgba(255,255,255,.4)'};
  padding: 40px;
`;
