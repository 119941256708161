import { takeLatest, call, all, put, select } from 'redux-saga/effects';

import axios from 'axios';

import { ISwitchCompanyAction } from './types';
import { persistCompany } from './actions';

import { RootState } from '~/store/useSelector';

export function* switchCompanySuccess({ payload }: ISwitchCompanyAction): any {
  // eslint-disable-line
  const { company } = payload;
  if (process.env.REACT_APP_ERP === 'facta') {
    try {
      yield call(
        axios.post,
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/companies/${company.id}/synchronize/facta`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
    try {
      yield call(
        axios.get,
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/fees/synchronize/facta/${company.id}/${company.clieId}`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
    try {
      yield call(
        axios.post,
        `${process.env.REACT_APP_GATEWAY_REGISTER}/consultFacta/companies/${company.cnpj}/importFacta`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
  }
  try {
    const response = yield call(axios.get, '/companies/company');
    const { token } = yield select((state: RootState) => state.auth);
    yield put(persistCompany({ company: { ...response.data }, token }));
  } catch (err) {
    console.log('error => ', err); // eslint-disable-line
  }

  return true;
}

export default all([takeLatest('@user/SWITCH_COMPANY', switchCompanySuccess)]);
