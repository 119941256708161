import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";

import { LinearProgress, Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";

import { Container } from "./styles";
import FileUpload from "./FileUpload";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const ImportXml: React.FC<IProps> = ({ handleNextItem }) => {
  const [loading] = useState(false);
  
  const [files, setFiles] = useState<File[]>([]);

  function handleRemove(index: number) {
    setFiles(files.filter((f, i) => i !== index));
  }

  const history = useHistory();

  return (
    <Container>
      <div id="titles_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4 className="m-0">Importação de XML</h4>
          </div>
            <Button type="button" color="primary" variant="contained" onClick={() => {
              window.location.href = '/operacoes/novo';
            }}>
              Antecipar Recebíveis
            </Button>
        </div>
        <div className="content_component">
          {loading && <LinearProgress />}
          <div className="filters">
            <Row className="mb-2">
              <Col sm={12}>
                <label htmlFor="xml">
                  <div>
                    <h5>Selecionar arquivos .XML</h5>
                    <CloudUpload />
                  </div>
                  <input
                    id="xml"
                    type="file"
                    onChange={(e) => {
                      if (e && e.target && e.target.files) {
                        setFiles([...files, ...Array.from(e.target.files)]);
                      }
                    }}
                    multiple
                    style={{ display: "none" }}
                  />
                </label>
                {files.length > 0 && (
                  <div id="list-files">
                    {files.map((file, index) => (
                      <FileUpload
                        key={index}
                        file={file}
                        handleRemove={(e) => handleRemove(e)}
                        index={index}
                      />
                    ))}
                  </div>
                )}
                  <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className="d-block mt-5 ml-auto mr-auto"
                  onClick={() => {
                    history.push(`/operacoes/novo`)
                  }}>
                    Antecipar Recebíveis
                  </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default memo(ImportXml);
