import React from "react";

import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@material-ui/core";

interface IStateSelect {
  value: string;
  onChange: any;
  label: string;
  required: boolean;
  error: boolean;
  helperText: string;
  disabled?: boolean;
}

const states = [
  { uf: "AC", desc: "Acre", },
  { uf: "AL", desc: "Alagoas", },
  { uf: "AP", desc: "Amapá", },
  { uf: "AM", desc: "Amazonas", },
  { uf: "BA", desc: "Bahia", },
  { uf: "CE", desc: "Ceará", },
  { uf: "DF", desc: "Distrito Federal", },
  { uf: "ES", desc: "Espírito Santo", },
  { uf: "GO", desc: "Goiás", },
  { uf: "MA", desc: "Maranhão", },
  { uf: "MT", desc: "Mato Grosso", },
  { uf: "MS", desc: "Mato Grosso do Sul", },
  { uf: "MG", desc: "Minas Gerais", },
  { uf: "PA", desc: "Pará", },
  { uf: "PB", desc: "Paraíba", },
  { uf: "PR", desc: "Paraná", },
  { uf: "PE", desc: "Pernambuco", },
  { uf: "PI", desc: "Piauí", },
  { uf: "RJ", desc: "Rio de Janeiro", },
  { uf: "RN", desc: "Rio Grande do Norte", },
  { uf: "RS", desc: "Rio Grande do Sul", },
  { uf: "RO", desc: "Rondônia", },
  { uf: "RR", desc: "Roraima", },
  { uf: "SC", desc: "Santa Catarina", },
  { uf: "SP", desc: "São Paulo", },
  { uf: "SE", desc: "Sergipe", },
  { uf: "TO", desc: "Tocantis", },
]

const StateSelect = ({
  value,
  onChange,
  label = "Estado",
  required,
  error,
  helperText,
  disabled,
  ...rest
}: IStateSelect) => {
  return (
    <FormControl style={{ width: "100%" }} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        style={{ width: "100%" }}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        {...rest}
        error={error}
        disabled={disabled}
      >
        {states.map(s => (
          <MenuItem key={s.uf} value={s.uf}>{s.desc}</MenuItem>
        ))}
      </Select>
      {helperText !== "" && (
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default StateSelect;
