import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import axios from 'axios';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
import history from '~/services/history';

import { signInSuccess, signFailure, signOut } from './actions';

import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  ISignInRequestAction,
} from './types';

import { persistCompany } from '../user/actions';
import { RootState } from '~/store/useSelector';

type UnsafeReturnType<T> = T extends (...args: any[]) => infer R ? R : any; // eslint-disable-line

export function* signIn({ payload }: ISignInRequestAction): any {
  // eslint-disable-line
  try {
    const { email, password } = payload;
    const response = yield call(axios.post, '/users/user/sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    axios.defaults.headers.Authorization = `Bearer ${token}`;

    const { data: company } = yield call(axios.get, '/companies/company');

    const {
      id,
      cnpj,
      clieId,
      limit,
      limitUsed,
      companyName,
      fantasyName,
      typeEmission,
    } = company;

    yield put(
      signInSuccess({
        token,
        user,
        company: {
          id,
          clieId,
          cnpj,
          limit,
          limitUsed,
          companyName,
          fantasyName,
          typeEmission,
        },
      })
    );

    history.push('/dashboard');
  } catch (err) {
    yield put(signFailure());
  }
}

export function* syncCompany(): any {
  // eslint-disable-line
  const { data: company } = yield call(axios.get, '/companies/company');
  if (process.env.REACT_APP_ERP === 'facta') {
    try {
      yield call(
        axios.post,
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/companies/${company.id}/synchronize/facta`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
    try {
      yield call(
        axios.get,
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/fees/synchronize/facta/${company.id}/${company.clieId}`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
    try {
      yield call(
        axios.post,
        `${process.env.REACT_APP_GATEWAY_REGISTER}/consultFacta/companies/${company.cnpj}/importFacta`
      );
    } catch (err) {
      console.log('error => ', err); // eslint-disable-line
    }
  }
  try {
    const response = yield call(axios.get, '/companies/company');
    const { token } = yield select((state: RootState) => state.auth);
    yield put(persistCompany({ company: { ...response.data }, token }));
  } catch (err) {
    console.log('error => ', err); // eslint-disable-line
  }
}

export function* setToken({ payload }: UnsafeReturnType<RehydrateAction>): any {
  // eslint-disable-line
  axios.defaults.baseURL =
    process.env.REACT_APP_GATEWAY_REGISTER || 'http://localhost:6000';
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    const response = yield call(axios.get, '/companies/company');
    yield put(persistCompany({ company: { ...response.data }, token }));
    if (response.data.status === 401) {
      yield put(signOut());
    }
  }
}

export function signOutRedirect(): any {
  // eslint-disable-line
  history.push('/');
}

export default all([
  takeLatest(REHYDRATE, setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest(SIGN_IN_SUCCESS, syncCompany),
  takeLatest('@user/SYNC_COMPANY', syncCompany),
  takeLatest(SIGN_OUT, signOutRedirect),
]);
