import React from 'react';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="container-fluid" id="first_footer">
        <div className="container">
          <h4>Fale Conosco</h4>
          <p>Ligue 4007-2614 | Horário de Atendimento: 08:00h às 18:00h.</p>
          <p>&copy; Todos os Direitos Reservados</p>
        </div>
      </div>
    </Container>
  );
}

export default Footer;