import React, { memo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import {
  LinearProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from "@material-ui/icons";
import { Table } from "react-bootstrap";

import { Container } from "./styles";
import IPayer from "./payer";
import { normalizeCnpj, normalizeCpf, normalizeTelephone } from "./utils";

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

interface IFilters {
  name: string;
  cpfCnpj: string;
  email: string;
}

const Sacados: React.FC<IProps> = ({ handleNextItem }) => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [payers, setPayers] = useState<IPayer[]>([]);
  const [filters, ] = useState<IFilters>({
    name: "",
    cpfCnpj: "",
    email: "",
  });

  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function loadPayers() {
    setLoading(true);
    try {
      const query: string[] = [];
      query.push(`page=${page}`);
      query.push(`perPage=${perPage}`);
      if (filters.name) {
        query.push(`name=${filters.name}`);
      }
      if (filters.email) {
        query.push(`email=${filters.email}`);
      }
      if (filters.cpfCnpj) {
        query.push(`cpfCnpj=${filters.cpfCnpj.replace(/[^\d]/g, "")}`);
      }
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_GATEWAY_OPERATION
        }/operations/payers/companies/me?${query.map((i) => i).join("&")}`
      );
      setPayers(data);
    } catch (err) {}
    setLoading(false);
  }

  useEffect(() => {
    loadPayers();
  }, [page, perPage]); // eslint-disable-line

  // async function searchPayer() {
  //   setLoading(true);
  //   const { data } = await axios.get(
  //     `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/payers/companies/me?name=${filters.name}&cpfCnpj=${filters.cpfCnpj}`
  //   );
  //   setPayers(data);
  //   setLoading(false);
  // }

  return (
    <Container>
      <div id="titles_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4 className="m-0">Sacados</h4>
          </div>
          {/* <div>
            <Button
              onClick={() => loadPayers()}
              color="primary"
              variant="contained"
              type="button"
            >
              <FaSearch className="mr-2" />
              Filtrar
            </Button>
            {Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                className="ml-2"
                onClick={(e) =>
                  setFilters({
                    cpfCnpj: "",
                    name: "",
                    email: "",
                  })
                }
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          </div> */}
        </div>
        <div className="content_component">
          {loading && <LinearProgress />}
          {/* <div className="filters">
            <Row className="mb-2">
              <Col sm={4}>
                <div className="input-group">
                  <TextField
                    value={filters.cpfCnpj}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        cpfCnpj: normalizeCpfCnpj(e.target.value),
                      })
                    }
                    label="CPF/CNPJ"
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="input-group">
                  <TextField
                    value={filters.name}
                    onChange={(e) =>
                      setFilters({ ...filters, name: e.target.value })
                    }
                    label="Nome"
                  />
                </div>
              </Col>
              <Col sm={4}>
                <div className="input-group">
                  <TextField
                    value={filters.email}
                    onChange={(e) =>
                      setFilters({ ...filters, email: e.target.value })
                    }
                    label="E-mail"
                  />
                </div>
              </Col>
            </Row>
          </div> */}
          <Table responsive hover>
            <thead>
              <tr>
                <th className="text-left">Sacado</th>
                <th>E-mail</th>
                <th>Telefone</th>
                <th>Cidade/Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payers.map((payer, index) => {
                return (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/operacoes/sacados/${payer.id}`)
                    }
                  >
                    <td className="text-left">
                      <small>
                        <strong>{payer.name}</strong>
                        <br />
                        {payer.type === "PF"
                          ? normalizeCpf(payer.document)
                          : normalizeCnpj(payer.document)}
                      </small>
                    </td>
                    <td>{payer.emails.length > 0 ? payer.emails[0] : "-"}</td>
                    <td>{payer.phone ? normalizeTelephone(payer.phone) : "-"}</td>
                    <td>
                      {payer.address.city && payer.address.state
                        ? `${payer.address.city}/${payer.address.state}`
                        : "-"}
                    </td>
                    <td>
                      <ArrowForwardIos style={{ fontSize: "12px" }} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end align-items-center mt-2 mb-3 mr-4">
            <div>
              Exibir:
              <button
                type="button"
                aria-controls="simple-menu"
                className="ml-4"
                aria-haspopup="true"
                style={{ color: "#444" }}
                // onClick={handleClickMenu}
              >
                {perPage} <ExpandMore color="primary" />
              </button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setPerPage(25);
                    handleClose();
                  }}
                >
                  25
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(50);
                    handleClose();
                  }}
                >
                  50
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(100);
                    handleClose();
                  }}
                >
                  100
                </MenuItem>
              </Menu>
              <span className="mr-4 ml-4">
                {page * perPage - perPage + 1}-{page * perPage}
              </span>
              <button
                type="button"
                disabled={page === 1 || loading}
                onClick={() => setPage(page - 1)}
              >
                <ArrowBackIos style={{ fontSize: "12px" }} />
              </button>
              <button
                type="button"
                onClick={() => setPage(page + 1)}
                disabled={payers.length < perPage}
              >
                <ArrowForwardIos style={{ fontSize: "12px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default memo(Sacados);
