import styled from "styled-components";

export const Container = styled.div`
  padding: 15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  div.content_component {
    margin: 20px 0px;
    padding: 20px 0px;
    height: 100%;
    width: 100%;
    fieldset{
      .row {
        margin: 0 -15px;
      }
      h4 {
        font-size: 14px !important;
        font-weight: 500;
        margin-bottom: 20px;
      }
      legend.MuiFormLabel-root {
        font-size: 9px !important;
      }
    }
  }
`;
