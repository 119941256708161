export const situacaoCadastral = (value: string) => {
  switch (value) {
    case "01":
      return "NULA";
    case "02":
      return "ATIVA";
    case "03":
      return "SUSPENSA";
    case "04":
      return "INAPTA";
    case "08":
      return "BAIXADA";
    default:
      return "";
  }
};
