import React, { memo, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Instructions: React.FC<IProps> = () => {
  const [loading] = useState(false);

  return (
    <div id="titles_table_wrap">
      <div className="header_component d-flex">
        <div>
          <h4>Títulos instruções</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut iusto
            nisi nam incidunt voluptate quaerat facilis magni dolores quam
            nihil.
          </p>
        </div>
      </div>
      <div className="content_component">{loading && <LinearProgress />}</div>
    </div>
  );
};

export default memo(Instructions);
