export const natureza = (value: number) => {
  switch (value) {
    case 2011:
      return "Empresa Pública";
    case 2038:
      return "Sociedade de Economia Mista";
    case 2046:
      return "Sociedade Anônima Aberta";
    case 2054:
      return "Sociedade Anônima Fechada";
    case 2062:
      return "Sociedade Empresária Limitada";
    case 2070:
      return "Sociedade Empresária em Nome Coletivo";
    case 2089:
      return "Sociedade Empresária em Comandita Simples";
    case 2097:
      return "Sociedade Empresária em Comandita por Ações";
    case 2127:
      return "Sociedade em Conta de Participação";
    case 2135:
      return "Empresário Individual";
    case 2143:
      return "Cooperativa";
    case 2151:
      return "Consórcio de Sociedades";
    case 2160:
      return "Grupo de Sociedades";
    case 2232:
      return "Sociedade Simples Pura";
    case 2240:
      return "Sociedade Simples Limitada";
    case 2259:
      return "Sociedade Simples em Nome Coletivo";
    case 2267:
      return "Sociedade Simples em Comandita Simples";
    case 2291:
      return "Consórcio Simples";
    case 2305:
      return "Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)";
    case 2313:
      return "Empresa Individual de Responsabilidade Limitada (de Natureza Simples)";
    case 2321:
      return "Sociedade Unipessoal de Advogados";
    case 3069:
      return "Fundação Privada";
    case 3220:
      return "Organização Religiosa";
    case 3301:
      return "Organização Social (OS)";
    case 3999:
      return "Associação Privada";
    case 4081:
      return "Contribuinte Individual";
    default:
      return "";
  }
};
