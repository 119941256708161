import React, { useState } from 'react';

import { TextField, Avatar, Button } from '@material-ui/core';
import {
  VpnKey,
  Visibility,
  VisibilityOff,
  HighlightOff,
  AddCircle,
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { signOut } from '~/store/modules/auth/actions';

import { cnpjIsValid } from '../utils/validation';
import { normalizeCnpj } from '../../../utils';

interface IEcnpj {
  id: string;
  companyId: string;
  password: string;
  extName: string;
  expiresIn: string;
  cnpj: string;
  createdAt: string;
  updatedAt: string;
}

interface IProps {
  onAdd: (data: IEcnpj) => void;
  onRefresh: (data: IEcnpj[]) => void;
}

const AddEcnpj: React.FC<IProps> = ({ onAdd, onRefresh }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [ecnpjId, setEcnpjId] = useState<string | null>(null);
  const [branchs, setBranchs] = useState<string[]>(['']);

  const [msg, setMsg] = useState('');
  const [values, setValues] = useState({
    password: '',
    cnpj: '',
  });
  const [errors, setErrors] = useState({
    password: '',
    cnpj: '',
  });
  function validateCnpj() {
    if (!cnpjIsValid(values.cnpj)) {
      setErrors({
        ...errors,
        cnpj: 'Cnpj inválido',
      });
    } else {
      setErrors({
        ...errors,
        cnpj: '',
      });
    }
  }

  function resetForm() {
    setValues({
      password: '',
      cnpj: '',
    });
    setErrors({
      password: '',
      cnpj: '',
    });
    setPasswordVisible(false);
    setFile(null);
  }

  async function handleSubmit() {
    if (!file) {
      return toast.error('Nenhum arquivo anexado');
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('password', values.password);
      formData.append('cnpj', values.cnpj.replace(/[^\d]+/g, ''));

      const { data } = await axios.post<IEcnpj>(`ecnpj/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onAdd(data);
      setStep(2);
      setEcnpjId(data.id);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 400) {
        setMsg(err.response.data.msg);
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    return setLoading(false);
  }

  async function handleAdd() {
    setLoading(true);
    try {
      await axios.post(`/ecnpj/upload/associate/${ecnpjId}`, {
        branchs: branchs.map((b) => {
          if (b.length === 7) {
            return b.replace(/[^\d]/g, '');
          }
          return '';
        }),
      });
      try {
        const { data } = await axios.get<IEcnpj[]>(`/ecnpj`);
        onRefresh(data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      resetForm();
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        dispatch(signOut());
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.msg);
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde.'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente.');
      }
    }
    setLoading(false);
  }

  function handleChangeByIndex(value: string, index: number) {
    const valuesBranchs = branchs.map((b, ind) => {
      if (ind === index) {
        return value.length > 4
          ? `${value.slice(0, 4)}-${value.slice(4, 6)}`
          : value;
      }
      return b;
    });
    setBranchs(valuesBranchs);
  }

  return (
    <div>
      {file === null ? (
        <>
          <label htmlFor="e-cnpj-file" id="e_cnpj_label">
            Enviar e-CNPJ
          </label>
          <input
            type="file"
            id="e-cnpj-file"
            accept=".pfx,.p12"
            onChange={(e) =>
              setFile(e && e.target && e.target.files && e.target.files[0])}
            disabled={loading}
          />
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          id="detached_file"
          style={
            msg !== '' ? { transition: '300ms', background: '#de5839' } : {}
          }
        >
          <div
            id="grid_detail"
            style={
              msg !== '' ? { transition: '300ms', background: '#de5839' } : {}
            }
          >
            <div
              className="item_grid"
              style={
                msg !== '' ? { transition: '300ms', background: '#de5839' } : {}
              }
            >
              <Avatar style={{ background: '#fff', color: '#3757a1' }}>
                <VpnKey />
              </Avatar>
              <h5 className="mt-2">{file.name}</h5>
            </div>
            {step === 1 && (
              <div className="item_grid">
                {msg !== '' && (
                  <Alert
                    severity="error"
                    style={{ width: '100%' }}
                    className="mb-2"
                  >
                    {msg}
                  </Alert>
                )}
                <TextField
                  label="CNPJ"
                  onBlur={validateCnpj}
                  value={values.cnpj}
                  onChange={(e) => {
                    if (msg !== '') {
                      setMsg('');
                    }
                    setValues({
                      ...values,
                      cnpj: normalizeCnpj(e.target.value),
                    });
                  }}
                  error={errors.cnpj !== ''}
                  helperText={errors.cnpj}
                  style={{ width: '100%' }}
                  disabled={loading}
                />
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <TextField
                    label="Senha"
                    type={passwordVisible ? 'text' : 'password'}
                    value={values.password}
                    onChange={(e) => {
                      if (msg !== '') {
                        setMsg('');
                      }
                      setValues({ ...values, password: e.target.value });
                    }}
                    error={errors.password !== ''}
                    helperText={errors.password}
                    style={{ width: '100%' }}
                    disabled={loading}
                  />
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </button>
                </div>
                <div className="d-flex mt-4">
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={
                      values.cnpj.length < 18 ||
                      values.password.length < 4 ||
                      loading
                    }
                  >
                    Salvar
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    color="primary"
                    variant="outlined"
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="item_grid">
                <div>
                  <h4>Seu e-CNPJ foi instalado com sucesso!</h4>
                  <p>
                    Deseja informar outras filiais que utilizam este
                    certificado?
                  </p>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => setStep(3)}
                    variant="contained"
                  >
                    Sim
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    color="primary"
                    variant="outlined"
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Não
                  </Button>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="item_grid">
                {msg !== '' && (
                  <Alert
                    severity="error"
                    style={{ width: '100%' }}
                    className="mb-2"
                  >
                    {msg}
                  </Alert>
                )}
                <h4>Informe as filiais que deseja associar.</h4>
                <div className="d-flex align-items-center justify-content-center flex-column">
                  {branchs.map((cnpj, index) => (
                    <div
                      key={String(index)}
                      className="d-flex align-items-center justify-content-center mt-2"
                    >
                      {values.cnpj.slice(0, 11)}
                      <TextField
                        type="text"
                        placeholder="0000-00"
                        value={cnpj}
                        style={{
                          maxWidth: '72px',
                          marginRight: index === 0 ? '44px' : '0px',
                        }}
                        onChange={(e) =>
                          handleChangeByIndex(
                            e.target.value.replace(/[^\d]/g, ''),
                            index
                          )
                        }
                      />
                      {index <= branchs.length - 1 && index > 0 && (
                        <button
                          type="button"
                          style={{
                            background: 'none',
                            border: 'none',
                            padding: '10px',
                          }}
                          onClick={() =>
                            setBranchs(branchs.filter((b, i) => i !== index))
                          }
                        >
                          <HighlightOff />
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    style={{
                      background: 'none',
                      border: 'none',
                      padding: '10px 0px',
                      width: '25px',
                    }}
                    onClick={() => setBranchs([...branchs, ''])}
                  >
                    <AddCircle />
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    type="button"
                    color="primary"
                    onClick={handleAdd}
                    disabled={loading || !!branchs.find((b) => b.length < 7)}
                    variant="contained"
                  >
                    Associar
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    color="primary"
                    variant="outlined"
                    onClick={resetForm}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEcnpj;
