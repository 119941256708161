import React, { useState } from "react";

import { Link } from "react-router-dom";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Container } from "./styles";

import axios from 'axios';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState({ type: '', value: '' })

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError("");
    function emailIsValid(email: string) {
      if (email.indexOf("@") === -1) {
        return false;
      }
      let after = email.split("@");
      if (after[1].indexOf(".") === -1) {
        return false;
      }
      return true;
    }
    if (email.length === 0) {
      return setError("Preencha um email")
    }

    if (!emailIsValid(email)) {
      return setError("Email não é válido")
    }

    setLoading(true);
    try {
      await axios.post('/users/user/forgot-password', {
        email,
      });
      setMsg({ type: 'success', value: 'Email enviado com o link de recuperação de senha.' });
    }catch(err) {
      if(err.response && err.response.status === 400) {
        setMsg({ type: 'error', value: 'Email não cadastrado.' });
      }else{
        setMsg({ type: 'error', value: 'Ocorreu um erro, tente novamente mais tarde.' });
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img src="/assets/images/logo-branca.png" alt="BFC Capital Partners LTDA" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4>Esqueceu a Senha?</h4>
            <p className="descript">Preencha seu email para recuperar sua senha.<br/>
              Enviaremos um e-mail com um link para redefini-la.</p> 
            {msg.type !== "" && msg.value !== "" && (
              <Alert severity={msg.type === "error" ? "error" : "success"}>{msg.value}</Alert>
            )}
            <TextField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ animationDelay: "150ms" }}
              error={error !== ""}
              helperText={error}
              disabled={loading}
            />
            <div className="button-wrap">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? <CircularProgress size={18} style={{ color: '#fff' }}/> : 'Enviar e-mail'}
              </Button>
              <Link to="/auth/login">Voltar</Link>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}
