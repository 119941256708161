export const normalizeCurrency = (value:number) => {
  if (value === 0) {
    return '';
  }
  const stringOfNumber = String(value);
  if(stringOfNumber.length === 2) {
    return `0,${stringOfNumber[0] || 0}${stringOfNumber[1] || 0}`;
  }else if (stringOfNumber.length === 1) {
    return `0,0${stringOfNumber[0] || 0}`;
  }
  let cents = stringOfNumber[stringOfNumber.length-2] + stringOfNumber[stringOfNumber.length-1];
  let reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  let arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((value, index) => {
    if((index + 1)%3 === 0) {
      if(index === arrayNumber.length - 1) {
        pointValue = value + pointValue;
      }else{
        pointValue = "." + value + pointValue;
      }
    }else{
      pointValue = value + pointValue;
    }
  });
  return pointValue + "," + cents;
}

export const normalizeCep = (value:string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

export const normalizeTelephone = (value:string) => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 0) {
    onlyNums = ``;
  }
  else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  }
  else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  }
  else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,7)}`;
  }
  else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,6)}-${onlyNums.slice(6,11)}`;
  }else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,7)}-${onlyNums.slice(7,11)}`;
  }

  return onlyNums;
};

export const normalizeAccBank = (value:string) => {
  if(!value) {
    return value;
  }
  var onlyNums = value.replace(/\D/g, '');

  if(onlyNums.length === 1) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, onlyNums.length - 1)}-${onlyNums.slice(onlyNums.length - 1, onlyNums.length)}`
}

export const normalizeNumber = (value:string) => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  return onlyNums;
};

export const normalizeCpfCnpj = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
}

export const normalizeDate = (value:string) => {
  if (!value) {
    return value;
  }

  const formated = value.replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{4})\d+?$/, '$1');

  return formated;
};