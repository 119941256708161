import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #titles_table_wrap {
    > div:nth-child(1) {
      padding: 30px;
      p {
        margin: 0px;
      }
    }
    .content_component {
      display: flex;
      flex-direction: column;
      padding: 0 0 10px;
      .filters {
        margin: 0 30px 30px;
        label {
          margin: 0;
          font-size: 14px;
          color: #555;
        }
        .input-group {
          input {
          }
        }
      }
      table {
        thead {
          tr {
            background: rgb(55 87 161 / 20%);
            th {
              padding: 10px 7.5px;
              border: none;
              font-weight: 500;
              font-size: 14px;
              text-align: center;
              vertical-align: middle;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 10px 7.5px;
              text-align: center;
              vertical-align: middle;
              font-size: 14px;
            }
          }
        }
      }
      .pagination {
        justify-content: center;
        li.page-item {
          .page-link {
            color: #3757a1;
          }
          &.active {
            .page-link {
              background: #3757a1;
              border-color: #3757a1;
              color: #fff;
            }
          }
        }
      }
    }
  }
  label {
    padding:15px;
    text-align: center;
    background: #f7f7f7;
    color: #666;
    margin-bottom: 12px;
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    transition: 350ms;
    div {
      width: 100%;
      height: 100%;
      border: 3px dashed #e3e3e3;
      border-radius: 12px;
      padding: 30px 10px;
      svg {
        font-size: 60px;
        color: #d2d2d2;
      }
    }
    &:hover {
      background: rgba(0,0,0,.1);
    }
  }
  #list-files {
    border-top: 1px solid #eee;
    margin-top: 60px;
    padding-bottom: 30px;
    #no_file {
      display:flex;
      justify-content:center;
      align-items: center;
      min-height: 150px;
      text-align: center;
      color: #999;
      h4 {
        margin: 0px;
      }
      svg {
        font-size: 44px;
      }
    }
    div.file_item {
      margin-top: 12px;
      padding: 15px;
      &.bg-gray {
        background: #f9f9f9;
      }
      h4 {
        font-size: 14px;
        color: #444;
      }
      button {
        font-size: 12px;
        color: #999;
        margin: 0px;
        padding: 0px;
        margin-right: 8px;
      }
    }
  }
`;
