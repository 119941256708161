import React from "react";

import { Container } from "./styles";

import { useHistory } from "react-router-dom";

import Open from "./components/Open";
import Settled from "./components/Settled";

const Fees: React.FC = ({ match }: any) => {
  const current = String(match.params.current);

  const history = useHistory();

  const handleNextItem = (actual: string) => {
    history.push(`/fees/${actual}`);
  };

  return (
    <Container>
      {current === "abertos" && (
        <Open handleNextItem={() => handleNextItem("abertos")} />
      )}
      {current === "liquidadas" && (
        <Settled handleNextItem={() => handleNextItem("liquidadas")} />
      )}
    </Container>
  );
};

export default Fees;
