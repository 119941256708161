import React, { memo, useState, useEffect } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
  Tooltip,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { FaPrint, FaSearch, FaTimes, FaBarcode } from 'react-icons/fa';

import { format, addMinutes } from 'date-fns';

import { Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSelector from '~/store/useSelector';
import {
  normalizeCpfCnpj,
  normalizeAccBank,
  normalizeCurrency,
} from '../../utils';
import { signOut } from '~/store/modules/auth/actions';

import { ITitle } from '../ITitle';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Open: React.FC<IProps> = () => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    payerDocument: '',
    document: '',
    value: '',
    filialType: '',
    titleType: '',
    status: 3,
  });
  const dispatch = useDispatch();

  const [titles, setTitles] = useState<ITitle[]>([]);
  const { id } = useSelector((state) => state.user);

  async function loadTitles(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.startDate) {
        query.push(`startDate=${filters.startDate}`);
      }
      if (filters.endDate) {
        query.push(`endDate=${filters.endDate}`);
      }
      if (filters.payerDocument) {
        query.push(
          `payerDocument=${filters.payerDocument.replace(/[^\d]/g, '')}`
        );
      }
      if (filters.document) {
        query.push(`document=${filters.document}`);
      }
      if (filters.value) {
        query.push(`value=${filters.value.replace(/[^\d]/g, '')}`);
      }
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perPage=${perPage}`);
    }
    query.push(`titleStatusId=3`);
    query.push(`order=ASC`);
    setLoading(true);
    try {
      const { data } = await axios.get<ITitle[]>(
        `${
          process.env.REACT_APP_GATEWAY_OPERATION
        }/operations/titles?${query.map((i) => i).join('&')}`
      );
      setTitles(data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  async function handlePrint() {
    const query: string[] = [];

    if (filters.startDate) {
      query.push(`startDate=${filters.startDate}`);
    }
    if (filters.endDate) {
      query.push(`endDate=${filters.endDate}`);
    }
    if (filters.payerDocument) {
      query.push(
        `payerDocument=${filters.payerDocument.replace(/[^\d]/g, '')}`
      );
    }
    if (filters.document) {
      query.push(`document=${filters.document}`);
    }
    if (filters.value) {
      query.push(`value=${filters.value.replace(/[^\d]/g, '')}`);
    }
    query.push(`order=ASC`);

    window.open(
      `${
        process.env.REACT_APP_GATEWAY_OPERATION
      }/operations/titles/companies/pdf/3/${id}?${query
        .map((i) => i)
        .join('&')}`,
      '_blank'
    );
  }

  useEffect(() => {
    loadTitles();
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div>
          <h4>Títulos a vencer</h4>
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => handlePrint()}
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={titles.length === 0}
            onClick={() => loadTitles()}
          >
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
          {Object.entries(filters).filter(([k, v], i) =>
            k === 'status' ? false : !!v
          ).length > 0 && (
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={() => {
                setFilters({
                  startDate: '',
                  endDate: '',
                  payerDocument: '',
                  document: '',
                  value: '',
                  filialType: '',
                  titleType: '',
                  status: 3,
                });
                loadTitles(true);
              }}
            >
              <FaTimes className="mr-2" />
              Limpar filtros
            </Button>
          )}
        </div>
      </div>
      <div className="content_component">
        <div className="filters">
          <Row className="mb-2">
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.startDate}
                  onChange={(e) =>
                    setFilters({ ...filters, startDate: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  id="date"
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.endDate}
                  onChange={(e) =>
                    setFilters({ ...filters, endDate: e.target.value })
                  }
                />
              </div>
            </Col>
            <Col sm={4}>
              <div className="input-group">
                <TextField
                  label="CPF/CNPJ Sacado"
                  value={filters.payerDocument}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      payerDocument: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="Documento"
                  value={filters.document}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      document: normalizeAccBank(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={3}>
              <div className="input-group">
                <TextField
                  label="Valor"
                  value={filters.value}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      value:
                        Number(e.target.value.replace(/[^\d]/g, '')) === 0
                          ? ''
                          : normalizeCurrency(
                              Number(e.target.value.replace(/[^\d]/g, ''))
                            ),
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr style={{ cursor: 'pointer' }}>
              <th>Borderô</th>
              <th>Título</th>
              <th>Documento</th>
              <th>Vencimento</th>
              <th>Sacado</th>
              <th>Valor</th>
              <th>Tipo</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {titles.map((t) => (
              <tr key={t.id}>
                <td>{t.borderoId}</td>
                <td>{t.referenceId}</td>
                <td>{t.document}</td>
                <td>
                  {format(
                    addMinutes(
                      new Date(t.date),
                      new Date(t.date).getTimezoneOffset()
                    ),
                    'dd/MM/yyyy'
                  )}
                </td>
                <td>{t.payerJson ? JSON.parse(t.payerJson).name : '-'}</td>
                <td>R$ {normalizeCurrency(t.value)}</td>
                <td>Duplicata</td>
                <td>
                  <Tooltip title="Imprimir Boleto">
                    <a
                      className="text-dark"
                      href={`${process.env.REACT_APP_GATEWAY_OPERATION}/analysts/titles/boleto?titles=${t.referenceId}`}
                      target="blank"
                      style={{
                        padding: '2px',
                        border: '1px solid #444',
                        borderRadius: '2px',
                      }}
                    >
                      <FaBarcode />
                    </a>
                  </Tooltip>
                </td>
              </tr>
            ))}
            {titles.length > 0 && (
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>
                  <strong>
                    R${' '}
                    {normalizeCurrency(
                      titles.reduce((sum, title) => {
                        return sum + title.originalValue;
                      }, 0)
                    )}
                  </strong>
                </td>
                <td />
                <td />
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end align-items-center mt-2 mb-3 mr-4">
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(25);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(50);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(100);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <span className="mr-4 ml-4">
              {page * perPage - perPage + 1}-
              {titles.filter(
                (n, i) =>
                  i + 1 > page * perPage - perPage && i + 1 <= page * perPage
              ).length +
                page * perPage -
                perPage}{' '}
              de {titles.length}
            </span>
            <button
              type="button"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={titles.length < page * perPage}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Open);
