import React, { memo, useState } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { FaPrint, FaSearch } from 'react-icons/fa';

import { Table, Row, Col } from 'react-bootstrap';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Returned: React.FC<IProps> = () => {
  const [loading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div>
          <h4 className="m-0">Títulos Devolvidos</h4>
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button color="primary" variant="contained" type="button">
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
        </div>
      </div>
      <div className="content_component">
        {loading && <LinearProgress />}
        <div className="filters">
          <Row className="mb-2">
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="Período"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  id="date"
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </Col>
            <Col sm={4}>
              <div className="input-group">
                <TextField label="CPF/CNPJ Sacado" />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField label="Documento" />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField label="Valor" />
              </div>
            </Col>
          </Row>
        </div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Nr. Documento</th>
              <th>Dt. Vencimento</th>
              <th>Sacado</th>
              <th>Nome</th>
              <th>Vlr. Título</th>
              <th>Motivo</th>
              <th>Dt. Devolução</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>851246</td>
              <td>28/08/2020</td>
              <td>04.692.248/0001-11</td>
              <td>PANIFICADORA SANTA INES RODRIGO BARBOSA PINHEIRO LTDA</td>
              <td>R$ 11.294,80</td>
              <td>11</td>
              <td>31/08/2020</td>
            </tr>
            <tr>
              <td>851246</td>
              <td>28/08/2020</td>
              <td>04.692.248/0001-11</td>
              <td>PANIFICADORA SANTA INES LTDA</td>
              <td>R$ 11.294,80</td>
              <td>11</td>
              <td>31/08/2020</td>
            </tr>
            <tr>
              <td>851246</td>
              <td>28/08/2020</td>
              <td>04.692.248/0001-11</td>
              <td>PANIFICADORA SANTA INES LTDA</td>
              <td>R$ 11.294,80</td>
              <td>11</td>
              <td>31/08/2020</td>
            </tr>
            <tr>
              <td />
              <td />
              <td />
              <td />
              <td>
                <strong>11.294,80</strong>
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-end align-items-center mt-2 mb-3 mr-4">
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              // onClick={handleClickMenu}
            >
              {perPage} 
{' '}
<ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPerPage(10);
                  handleClose();
                }}
              >
                10
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(25);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPerPage(50);
                  handleClose();
                }}
              >
                50
              </MenuItem>
            </Menu>
            <span className="mr-4 ml-4">
              {page * perPage - perPage + 1}-
              {/* {notes.filter(
                (n, i) =>
                  i + 1 > page * perPage - perPage && i + 1 <= page * perPage
              ).length +
                page * perPage -
                perPage}{" "}
              de {notes.length} */}
            </span>
            <button
              type="button"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              // disabled={notes.length < page * perPage}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Returned);
