import React, { memo, useState, useEffect } from 'react';

import { Button, Avatar, LinearProgress } from '@material-ui/core';
import { Publish, Warning } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import useSelector from '~/store/useSelector';

import { signOut } from '../../../../../../store/modules/auth/actions';

import ModalPendency from './ModalPendency';

interface itemDocumentsInt {
  id: string;
  label: string;
  status: string;
  file: string;
  rejectReasons: string[];
}
interface itemInt {
  id: string;
  name: string;
  doc: string;
  type: 'company' | 'pf' | 'pj';
  documents: itemDocumentsInt[];
}
interface IUpdateDoc {
  (id: string): void;
}
interface propsInt {
  item: itemInt;
  resetForm: any;
  updateDoc: IUpdateDoc;
}

interface IPendency {
  item: itemDocumentsInt;
  open: boolean;
}

const DetailDocs = ({ item, resetForm, updateDoc }: propsInt) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [docId, setDocId] = useState<string | null>(null);

  const [pendency, setPendency] = useState<IPendency>({
    item: {
      id: '',
      label: '',
      status: 'company',
      rejectReasons: [],
      file: '',
    },
    open: false,
  });

  const dispatch = useDispatch();
  const { cnpj } = useSelector((state) => state.user);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('pendency') === '1') {
      const doc = item.documents.find((d) => d.id === urlParams.get('doc'));
      if (doc) {
        setPendency({
          open: true,
          item: doc,
        });
      }
    }
  }, [item.documents]);

  useEffect(() => {
    async function uploadFile() {
      setLoading(true);
      if (!file) {
        return;
      }
      try {
        const objData = new FormData();
        objData.append('file', file);
        await axios.patch(`/documents/${docId}/${cnpj}/file`, objData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        updateDoc(docId || '');
        setFile(null);
        setDocId(null);
        toast.info('Arquivo enviado');
      } catch (err) {
        setFile(null);
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    if (docId && file) {
      uploadFile();
    }
  }, [file, docId]); // eslint-disable-line

  return (
    <>
      <ModalPendency
        open={pendency.open}
        setOpen={() =>
          setPendency({
            open: false,
            item: {
              id: '',
              label: '',
              status: '',
              rejectReasons: [],
              file: '',
            },
          })
        }
        item={pendency.item}
        owner={{ type: item.type, id: item.id }}
        onResolve={() => {
          updateDoc(pendency.item.id);
          setPendency({
            open: false,
            item: {
              id: '',
              label: '',
              status: '',
              rejectReasons: [],
              file: '',
            },
          });
        }}
      />
      <div className="header_component d-flex">
        <Avatar
          variant="circle"
          style={{ color: '#3757a1', background: '#f7f7f7' }}
        >
          {item.name[0]}
        </Avatar>
        <div className="ml-4">
          <h4>{item.name}</h4>
          {item.doc}
        </div>
      </div>
      <div className="content_component">
        {loading && <LinearProgress />}
        <table className="table">
          <tbody>
            {item.documents.length > 0 &&
              item.documents.map((doc, index) => (
                <tr key={index}>
                  <td>{doc.label}</td>
                  <td style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {doc.status === 'EMPTY' && (
                      <label
                        htmlFor={doc.id}
                        className={loading ? 'loadingLabel' : ''}
                        onClick={() => setDocId(doc.id)}
                      >
                        Enviar <Publish />
                        <input
                          type="file"
                          onChange={(e) =>
                            setFile(
                              e &&
                                e.target &&
                                e.target.files &&
                                e.target.files[0]
                            )
                          }
                          id={doc.id}
                          style={{ display: 'none' }}
                          disabled={loading}
                        />
                      </label>
                    )}
                    {doc.status === 'REJECTED' && (
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                          setPendency({
                            open: true,
                            item: doc,
                          })
                        }
                      >
                        Ver Pendência <Warning />
                      </Button>
                    )}
                    {doc.status === 'UPLOADED' && (
                      <Button variant="contained" disabled color="primary">
                        Em análise
                      </Button>
                    )}
                    {doc.status === 'APPROVED' && (
                      <Button
                        variant="contained"
                        disabled
                        style={{ background: '#39d8ac' }}
                        color="primary"
                      >
                        Aprovado
                      </Button>
                    )}
                    {doc.status === 'EXEMPT' && (
                      <Button variant="contained" disabled color="primary">
                        Isento
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div>
        <Button
          disabled={loading}
          color="primary"
          variant="outlined"
          onClick={() => resetForm()}
        >
          Voltar
        </Button>
      </div>
    </>
  );
};

export default memo(DetailDocs);
