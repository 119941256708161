import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  box-shadow:0px 0px 10px rgba(0,0,0,.1);
  div.row {
    margin:0px;
    flex:1;
  }
  div.col-md-12{
    div.header_component {
      @media screen and (max-width: 500px) {
        h4 {
          font-size:14px;
        }
        p{
          font-size:12px;
        }
      }
    }
  }
  fieldset {
    legend {
      font-size:25px;
      font-weight:400;
    }
    * {
      font-size:12px !important;
      color: ${props => props.theme.title === 'dark' && `${props.theme.colors.text} !important`};
    }
  }
  fieldset + fieldset {
    margin-top: 35px;
  }
`;
