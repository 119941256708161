import { format, addMinutes } from 'date-fns';
import { IOperation } from '../operation';
import { normalizeCurrency, normalizeCpfCnpj } from '../../utils/normalize';

export default function printBordero(operation: IOperation): void {
  const myWindow = window.open('', 'PRINT');
  if (myWindow) {
    myWindow.document.write(`
    <html lang="pt-BR">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>${operation.borderoId}</title>
</head>
<style>
  * {
    font-family: sans-serif;
    padding:0;
    margin:0;
  }
  .blue {
    color: #1f2d5b;
  }
  .d-flex {
    display: flex;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  table tbody tr td{
    padding: 5px;
    font-size: 12px;
  }
  table tbody tr:nth-child(odd) {
    background: #eee;
  }
  table thead tr th {
    padding: 10px 5px;
  }
</style>
<body style="display: flex; flex-direction: column; justify-content: space-between; min-height: 100vh;">
  <div style="width: 100%;">
  <header>
    <img src="https://app.bfcdigital.com.br/assets/images/logo_email.png" style="display: block; margin:0px auto; width: 65px;"/>
  </header>
  <section style="margin-top: 20px;">
    <div style="width: 100%; background: #eee; padding: 10px 0px;">
      <h2 class="blue text-center">
        Relatório Borderô ${operation.borderoId}
      </h2>
    </div>
    <strong class="text-center" style="margin: 8px 0px; color: #444;">
      Data de Impressão: ${format(new Date(), 'dd/MM/yyyy - HH:mm')}
    </strong>
    <hr />
  </section>
  <section style="margin-top: 16px;">
    <p style="margin-bottom: 8px;">
      Valor total bruto:
      <strong>
        R$ ${normalizeCurrency(Math.round(operation.totalValue))}
      </strong>
    </p>
    <p>
      Valor total líquido:
      <strong>
      R$ ${normalizeCurrency(Math.round(operation.totalNetValue))}
      </strong>
    </p>
    <br />
    <br />
    <h2 style="margin-bottom: 10px;">Títulos</h2>
    <hr />
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr>
          <th style="text-align: left;">Sacado</th>
          <th style="text-align: left;">Documento</th>
          <th style="text-align: left;">Valor</th>
          <th style="text-align: right;">Data de Vencimento</th>
        </tr>
      </thead>
      <tbody>
        ${operation.titles
          .map((title) => {
            const payer = JSON.parse(title.payerJson);
            return `
            <tr>
              <td>
                ${payer.name}<br />
                ${normalizeCpfCnpj(payer.document)}
              </td>
              <td>${title.document}</td>
              <td>
                R$ ${normalizeCurrency(Math.round(title.value))}
              </td>
              <td style="text-align: right;">
                ${
                  title.date
                    ? format(
                        addMinutes(
                          new Date(title.date),
                          new Date(title.date).getTimezoneOffset()
                        ),
                        'dd/MM/yyyy'
                      )
                    : '-'
                }
              </td>
            </tr>
          `;
          })
          .join('')}
      </tbody>
    </table>
  </section>
</div>
  <footer style="margin-top: 16px; page-break-before:always">
    <div style="display:flex; justify-content:space-between; text-align: left; width: 100%;">
      <div>
      <h5 class="blue">
        BFC Capital Partners
      </h5>
      <p class="blue" style="font-size: 10px;">
        Empresarial Alexandre de Castro e Silva,
        Av. Domingos Ferreira<br /> 2589 - Sala 1001, 10° Andar -
        CEP: 51.020-031 - Boa Viagem, Recife - PE
      </p>
      </div>
      <div class="blue">
        <strong style="display: block; text-align: right;">+55 (81) 4007-2614</strong>
        <strong style="display: block; text-align: right;">bfc@grupobfc.com.br</strong>
      </div>
    </div>
  </footer>
</body>
</html>
  `);
    myWindow.document.close();
    myWindow.focus();
    setTimeout(() => {
      myWindow.print();
      myWindow.close();
    }, 1000);
  }
}
