import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { Container } from "./styles";

import axios from "axios";

export default function Home() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [loading, setLoading] = useState(false);
  const [msgUser, setMsgUser] = useState({ type: "", value: "" });
  const [msgCompany, setMsgCompany] = useState({ type: "", value: "" });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    cnpj: "",
  });

  const normalizeCnpj = (value: string) => {
    if (!value) {
      return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 2) {
      return onlyNums;
    }
    if (onlyNums.length <= 5) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
    }
    if (onlyNums.length <= 8) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8
      )}`;
    }
    if (onlyNums.length <= 12) {
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
        5,
        8
      )}/${onlyNums.slice(8, 12)}`;
    }
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    setMsgUser({ type: "", value: "" });
    setMsgCompany({ type: "", value: "" });
    setErrors({
      name: "",
      email: "",
      password: "",
      cnpj: "",
    });
    function fullnameIsValid(name: string) {
      if (name.indexOf(" ") === -1) {
        return false;
      }
      return true;
    }
    function emailIsValid(email: string) {
      if (email.indexOf("@") === -1) {
        return false;
      }
      let after = email.split("@");
      if (after[1].indexOf(".") === -1) {
        return false;
      }
      return true;
    }
    function passwordIsValid(senha: string) {
      var regex = /^(?=(?:.*?[0-9]){1})(?=(?:.*?[a-zA-Z]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/; 
      if (senha.length < 8) {
        return false;
      } else if (!regex.exec(senha)) {
        return false;
      }
      return true;
    }
    function cnpjIsValid(cnpj: any) {
      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj === "") return false;

      if (cnpj.length !== 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
      )
        return false;

      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(0))) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(1))) return false;

      return true;
    }
    let lock_error = {
      name: "",
      email: "",
      password: "",
      cnpj: "",
    };
    if (!fullnameIsValid(name)) {
      lock_error.name = "Preencha seu nome completo";
    }
    if (!emailIsValid(email)) {
      lock_error.email = "Email não é válido";
    }
    if (password.length === 0) {
      lock_error.password = "Preencha este campo";
    } else if (password.length < 8) {
      lock_error.password = "A senha deve conter no minímo 8 digitos!";
    } else if (!passwordIsValid(password)) {
      lock_error.password =
        "A senha deve no mínimo oito caracteres, sendo pelo menos um número.";
    }
    if (name.length === 0) {
      lock_error.name = "Preencha este campo";
    }
    if (!cnpjIsValid(cnpj)) {
      lock_error.cnpj = "CNPJ não é válido";
    }
    if (
      lock_error.email !== "" ||
      lock_error.password !== "" ||
      lock_error.name !== "" ||
      lock_error.cnpj !== ""
    ) {
      return setErrors(lock_error);
    }

    setLoading(true);
    try {
      await axios.post("/companies", {
        cnpj: cnpj.replace(/[^\d]/g, ""),
        name,
        email,
        password,
      });
      setMsgUser({ type: "success", value: "Cadastro efetuado com sucesso" });
      setName("");
      setEmail("");
      setPassword("");
      setCnpj("");
      setTimeout(() => {
        history.push("/auth/confirm");
      }, 3000);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setMsgUser({ type: "error", value: err.response.data.message });
      } else if (err.response && err.response.status === 500) {
        setMsgUser({
          type: "error",
          value:
            "Ocorreu um erro em nossos servidores, tente novamente mais tarde",
        });
      } else {
        setMsgUser({
          type: "error",
          value: "Ocorreu um erro, tente novamente mais tarde",
        });
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img src="/assets/images/logo-branca.png" alt="BFC Capital Partners LTDA" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4>Realizar Cadastro</h4>
            {msgUser.value !== "" && (
              <Alert
                severity={msgUser.type === "error" ? "error" : "success"}
                className="mb-2"
              >
                {msgUser.value}
              </Alert>
            )}
            {msgCompany.value !== "" && (
              <Alert
                severity={msgCompany.type === "error" ? "error" : "success"}
              >
                {msgCompany.value}
              </Alert>
            )}
            <TextField
              type="text"
              label="Nome Completo"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (errors.name !== "") {
                  setErrors({
                    ...errors,
                    name: "",
                  });
                }
              }}
              style={{ animationDelay: "300ms" }}
              error={errors.name !== ""}
              helperText={errors.name}
              inputProps={{
                autocomplete: "off",
              }}
            />
            <TextField
              type="text"
              label="CNPJ"
              value={cnpj}
              onChange={(e) => {
                setCnpj(normalizeCnpj(e.target.value));
                if (errors.cnpj !== "") {
                  setErrors({
                    ...errors,
                    cnpj: "",
                  });
                }
              }}
              style={{ animationDelay: "450ms" }}
              error={errors.cnpj !== ""}
              helperText={errors.cnpj}
              inputProps={{
                autocomplete: "off",
              }}
            />
            <TextField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (errors.email !== "") {
                  setErrors({
                    ...errors,
                    email: "",
                  });
                }
              }}
              style={{ animationDelay: "600ms" }}
              error={errors.email !== ""}
              helperText={errors.email}
              inputProps={{
                autocomplete: "off",
              }}
            />
            <div className="d-flex justify-content-center align-items-center">
              <TextField
                label="Senha"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (errors.password !== "") {
                    setErrors({
                      ...errors,
                      password: "",
                    });
                  }
                }}
                style={{ animationDelay: "750ms" }}
                error={errors.password !== ""}
                helperText={errors.password}
                inputProps={{
                  autocomplete: "new-password",
                }}
                disabled={loading}
              />
              <button
                type="button"
                style={{ animationDelay: "800ms" }}
                className="ml-2 mt-2 inputIcon"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {!passwordVisible ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>
            <small>
                Senha deverá conter no mínimo oito caracteres, sendo pelo menos
                um número.
              </small>
            <div className="button-wrap">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: "#fff" }} />
                ) : (
                  "criar conta"
                )}
              </Button>
              <Link to="/auth/login">Voltar</Link>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}
