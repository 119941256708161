import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #payer_detail {
    #header_content {
      border-bottom: 1px solid #c9c9c9;
      padding: 30px;
    }
    #body_content {
      padding: 30px;
    }
    #footer_content {
      .title_footer {
        padding: 10px 30px;
        background: #eee;
        h5 {
          font-size: 16px;
        }
      }
      .content {
        padding: 20px 30px;
      }
    }
  }
`;
