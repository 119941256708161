import React, { useEffect, useState } from 'react';

import { MonetizationOnOutlined, DeleteOutline } from '@material-ui/icons';
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  InputLabel,
  Select,
  LinearProgress,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import useSelector from '~/store/useSelector';
import { ITitle } from '~/store/modules/nfseAnticipation/types';
import { banks as bankOptions } from './BankSelect';

import {
  storeTitles,
  resetAnticipation,
  addBankAccount,
} from '../../../../store/modules/nfseAnticipation/actions';
import { signOut } from '../../../../store/modules/auth/actions';
import { normalizeCurrency } from '../utils/normalize';

import ModalConclued from './ModalConclued';

interface IBank {
  accountDigit: string;
  accountNumber: string;
  agencyDigit?: string;
  agencyNumber: string;
  bankNumber: string;
  companyId: string;
  complement: string;
  createdAt: string;
  document: string;
  id: string;
  legalName: string;
  updatedAt: string;
}
interface IOperation {
  taxa: string;
  valorBrutoTotal: string;
  valorLiquidoTotal: string;
}

const BoxFloat: React.FC = () => {
  const dispatch = useDispatch();
  const { titles, operationId } = useSelector(
    (state) => state.nfseAnticipation
  );
  const [openConclued, setOpenConclued] = useState(false);
  const [bankSelected, setBankSelected] = useState('');
  const [expand, setExpand] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const [, setOperation] = useState<IOperation>({
    taxa: '',
    valorBrutoTotal: '',
    valorLiquidoTotal: '',
  });
  const [banks, setBanks] = useState<IBank[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let amountTotal = 0;
    titles.forEach((n) => {
      amountTotal += Number(n.value);
    });
    setTotal(amountTotal);
  }, [titles]); // eslint-disable-line

  async function loadOperation() {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/${operationId}`
      );
      setOperation({ ...data });
      setBankSelected(data.bank.number);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 404) {
        dispatch(resetAnticipation());
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else if (
        err.response &&
        err.response.status === 400 &&
        err.response.data &&
        err.response.data.error === 'Operação não encontrada'
      ) {
        dispatch(resetAnticipation());
      } else {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      }
    }
  }

  useEffect(() => {
    if (operationId) {
      loadOperation();
    }
    if (titles.length === 0) {
      setExpand(false);
    }
  }, [titles, dispatch, operationId]); // eslint-disable-line

  useEffect(() => {
    async function loadBanks() {
      try {
        const { data } = await axios.get('/banks');
        const banks_d: IBank[] = []; // eslint-disable-line
        data.forEach((i: any) => {
          banks_d.push(i);
        });
        setBanks(banks_d);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
    }
    loadBanks();
  }, [dispatch, operationId]);

  const resetBox = () => {
    setBankSelected('');
    setTotal(0);
    setExpand(false);
  };

  const removeFromNotes = async (index: number, title: ITitle) => {
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/titles/${title.id}/${operationId}`
      );
      const filtered = titles.filter((n, i) => i !== index);
      dispatch(storeTitles({ titles: filtered }));
      if (filtered.length === 0) {
        dispatch(resetAnticipation());
        resetBox();
      }
    } catch (error) {
      console.log('Error', error);
    }
    setLoading(false);
  };

  const handleChange = async (
    event: React.ChangeEvent<{ value: unknown }>
  ): Promise<void> => {
    setLoading(true);
    try {
      const bankSelec = banks.find((b) => b.id === event.target.value);
      if (!bankSelec) {
        setLoading(false);
        return;
      }
      await axios.patch(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/${operationId}/bank`,
        {
          bank: {
            number: bankSelec.bankNumber,
            agency: {
              number: bankSelec.agencyNumber,
              digit: bankSelec.agencyDigit,
            },
            account: {
              number: bankSelec.accountNumber,
              digit: bankSelec.accountDigit,
            },
          },
        }
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
    setBankSelected(event.target.value as string);
    addBankAccount(event.target.value as string);
  };

  async function handleSubmitConfirm() {
    try {
      await axios.post(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/${operationId}/confirm`
      );
      dispatch(resetAnticipation());
      setOpenConclued(true);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 400) {
        toast.warn(
          'Algumas notas foram removidos desta operação por não estarem hábeis à antecipar, envie novamente',
          { autoClose: false }
        );
        loadOperation();
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
  }

  return (
    <div id="antecipate_box" className={titles.length > 0 ? 'open' : ''}>
      <ModalConclued open={openConclued} setOpen={(e) => setOpenConclued(e)} />
      <div className="antecipate_box__container">
        <div>
          <h3> {titles.length} NFS-e(s) Selecionado(s)</h3>
          <span
            style={{
              fontSize: '14px',
            }}
          >
            Total parcial: R$ {normalizeCurrency(total)}
          </span>
        </div>
        <div>
          <Button
            type="button"
            endIcon={<MonetizationOnOutlined />}
            color="primary"
            variant="outlined"
            onClick={() => setExpand(!expand)}
          >
            {expand ? 'Voltar' : 'Antecipar'}
          </Button>
        </div>
      </div>
      {expand && (
        <div
          className={`container ${expand ? 'opened' : 'closed'}`}
          id="expanded"
        >
          {loading && <LinearProgress />}
          <div id="grid_detail">
            <div>
              <p>NFS-e a antecipar</p>
              <div className="list-items">
                {titles.length > 0 &&
                  titles.map((title, index) => (
                    <div className="item" key={String(index)}>
                      <div>
                        <p>{title.sacado}</p>
                        <h4>R$ {normalizeCurrency(title.value)}</h4>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{title.key}</span>
                          <button
                            type="button"
                            onClick={() => removeFromNotes(index, title)}
                            disabled={loading}
                          >
                            <DeleteOutline />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <FormLabel component="legend">Contas Bancárias:</FormLabel>
              {banks.length === 0 ? (
                <h4>Nenhum banco cadastrado</h4>
              ) : (
                <FormControl className="mb-2" component="fieldset">
                  <FormControl>
                    <InputLabel id="demo-simple-select-helper-label">
                      Selecionar Conta Bancária
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={bankSelected}
                      onChange={handleChange}
                    >
                      {banks.map((bank) => (
                        <MenuItem key={bank.id} value={bank.id}>
                          <span>
                            Banco:{' '}
                            {bankOptions.find((b) => {
                              if (b.slice(0, 3) === bank.bankNumber) {
                                return b;
                              }
                              return false;
                            }) || bank.bankNumber}
                            <br />
                            Ag: {bank.agencyNumber}
                            {bank.agencyDigit && `-${bank.agencyDigit}`}
                            <br />
                            Cc: {bank.accountNumber}- {bank.accountDigit}
                          </span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </FormControl>
              )}
              <div id="total_antecipated" className="mt-5">
                <p
                  style={{
                    color: '#585858',
                    fontSize: '14px',
                  }}
                >
                  Resumo da Operação:
                </p>
                <div>
                  <span>Total</span>
                  <strong>R$ {normalizeCurrency(total)}</strong>
                </div>
              </div>
              <hr />
              <Button
                type="button"
                className="mt-4"
                disabled={
                  bankSelected === '' ||
                  !bankSelected ||
                  titles.length === 0 ||
                  loading
                }
                color="primary"
                variant="contained"
                onClick={() => handleSubmitConfirm()}
                style={{ width: '100%' }}
              >
                Enviar Operação
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoxFloat;
