import React, { useEffect, useState } from 'react';

import axios from 'axios';
import {
  LinearProgress,
  Button,
  MenuItem,
  TextField,
  Menu,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Lottie from 'react-lottie';
import { Table, Row, Col } from 'react-bootstrap';
import { HiDocumentReport } from 'react-icons/hi';
import {
  MdQueryBuilder,
  MdError,
  MdCheckCircle,
  MdContentCopy,
} from 'react-icons/md';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import useSelector from '~/store/useSelector';
import { signOut } from '~/store/modules/auth/actions';

import {
  normalizeCurrency,
  normalizeCpfCnpj,
  normalizeNumber,
} from '../Anticipation/utils/normalize';

import Manifest from './components/Manifest';

import noEcnpj from '~/animations/no_ecnpj.json';
import serverWorking from '~/animations/server_working.json';

import { Container, NoEcnpjInstalled } from './styles';

const ConsNotes: React.FC = () => {
  const [hasEcnpj, setHasEcnpj] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openManifest, setOpenManifest] = useState(false);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    startValue: '',
    endValue: '',
    key: '',
    emissorDocument: '',
    status: 0,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    async function loadEcnpjs() {
      setLoading(true);
      try {
        const { data: ecnpjs } = await axios.get(`/ecnpj`);
        if (ecnpjs.length && ecnpjs.length > 0) {
          setHasEcnpj(true);
        } else {
          setHasEcnpj(false);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }

    loadEcnpjs();
  }, [dispatch, id]);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function copyToClipboard(text: string) {
    const textarea = document.createElement('input');
    textarea.setAttribute('value', text);
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  return (
    <Container>
      <Manifest open={openManifest} onClose={() => setOpenManifest(false)} />
      <div className="header_component">
        <h4>Notas Emitidas Contra</h4>
        {hasEcnpj && (
          <Button
            type="button"
            startIcon={<HiDocumentReport />}
            color="primary"
            variant="contained"
            onClick={() => setOpenManifest(true)}
            disabled={openManifest}
          >
            Realizar Manifestação
          </Button>
        )}
      </div>
      <div className="content_component">
        {hasEcnpj && (
          <div className="filters">
            <Row className="mb-3 mt-0 filters-wrap">
              <Col lg={6} className="mb-0 mt-0">
                <small className="d-block mb-2">Data de Emissão</small>
                <div className="d-flex align-items-center">
                  <TextField
                    type="date"
                    value={filters.startDate}
                    onChange={(e) =>
                      setFilters({ ...filters, startDate: e.target.value })
                    }
                  />
                  <small className="d-block ml-2 mr-2">até</small>
                  <TextField
                    type="date"
                    value={filters.endDate}
                    onChange={(e) =>
                      setFilters({ ...filters, endDate: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col lg={6} className="mb-0 mt-0">
                <small className="d-block mb-2">Valor</small>
                <div className="d-flex align-items-center">
                  <TextField
                    placeholder="0,00"
                    value={filters.startValue}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        startValue: normalizeCurrency(
                          Number(e.target.value.replace(/[^\d]/g, ''))
                        ),
                      })
                    }
                  />
                  <small className="d-block ml-2 mr-2">até</small>
                  <TextField
                    placeholder="0,00"
                    value={filters.endValue}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        endValue: normalizeCurrency(
                          Number(e.target.value.replace(/[^\d]/g, ''))
                        ),
                      })
                    }
                  />
                </div>
              </Col>
              <Col lg={4} className="mb-0 mt-2 d-flex align-items-end">
                <TextField
                  label="Chave NF-e"
                  value={filters.key}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      key: normalizeNumber(e.target.value),
                    })
                  }
                />
              </Col>
              <Col lg={4} className="mb-0 mt-2 d-flex align-items-end">
                <TextField
                  label="Emissor (CNPJ)"
                  value={filters.emissorDocument}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      emissorDocument: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </Col>
              <Col lg={4} className="mb-0 mt-2 d-flex align-items-end">
                <TextField
                  label="Status"
                  select
                  value={filters.status}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      status: Number(e.target.value),
                    })
                  }
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={1}>Ciência da Operação</MenuItem>
                  <MenuItem value={2}>Confirmação da Operação</MenuItem>
                  <MenuItem value={3}>Desconhecimento da Operação</MenuItem>
                  <MenuItem value={4}>Operação não Realizada</MenuItem>
                </TextField>
              </Col>
            </Row>
          </div>
        )}
        {loading && <LinearProgress />}
        {hasEcnpj && (
          <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Emissor</th>
                  <th>Chave NF-e</th>
                  <th>Valor</th>
                  <th>Data de Emissão</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: serverWorking,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        width={550}
                        style={{ margin: '0px', marginLeft: '-170px' }}
                      />
                      <div
                        className="text-left"
                        style={{ marginLeft: '-134px' }}
                      >
                        <h4>
                          Nenhuma Nota Emitida Contra encontrada até o momento
                        </h4>
                        <p style={{ fontSize: '14px' }}>
                          Nossos servidores estão trabalhando em buscar as suas
                          notas automaticamente.
                          <br />
                          Pode demorar até <strong>1 dia útil</strong> para
                          novas notas serem listadas.
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    PLACEHOLDER LTDA
                    <br />
                    <strong>00.000.000/0000-00</strong>
                  </td>
                  <td>
                    000000000000000000000000000000000000
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard('000000000000000000000000000000000000')
                      }
                    >
                      <MdContentCopy />
                    </button>
                  </td>
                  <td>
                    <strong>R$ 20.000,00</strong>
                  </td>
                  <td>00/00/0000</td>
                  <td className="text-primary">
                    Ciência da Operação <MdCheckCircle size={20} />
                  </td>
                </tr>
                <tr>
                  <td>
                    PLACEHOLDER LTDA
                    <br />
                    <strong>00.000.000/0000-00</strong>
                  </td>
                  <td>
                    000000000000000000000000000000000000
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard('000000000000000000000000000000000000')
                      }
                    >
                      <MdContentCopy />
                    </button>
                  </td>
                  <td>
                    <strong>R$ 20.000,00</strong>
                  </td>
                  <td>00/00/0000</td>
                  <td className="text-primary">
                    Confirmação da Operação <MdCheckCircle size={20} />
                  </td>
                </tr>
                <tr>
                  <td>
                    PLACEHOLDER LTDA
                    <br />
                    <strong>00.000.000/0000-00</strong>
                  </td>
                  <td>
                    000000000000000000000000000000000000
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard('000000000000000000000000000000000000')
                      }
                    >
                      <MdContentCopy />
                    </button>
                  </td>
                  <td>
                    <strong>R$ 20.000,00</strong>
                  </td>
                  <td>00/00/0000</td>
                  <td className="text-danger">
                    Desconhecimento da Operação <MdError size={20} />
                  </td>
                </tr>
                <tr>
                  <td>
                    PLACEHOLDER LTDA
                    <br />
                    <strong>00.000.000/0000-00</strong>
                  </td>
                  <td>
                    000000000000000000000000000000000000
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard('000000000000000000000000000000000000')
                      }
                    >
                      <MdContentCopy />
                    </button>
                  </td>
                  <td>
                    <strong>R$ 20.000,00</strong>
                  </td>
                  <td>00/00/0000</td>
                  <td className="text-danger">
                    Operação não Realizada <MdError size={20} />
                  </td>
                </tr>
                <tr>
                  <td>
                    PLACEHOLDER LTDA
                    <br />
                    <strong>00.000.000/0000-00</strong>
                  </td>
                  <td>
                    000000000000000000000000000000000000
                    <button
                      type="button"
                      onClick={() =>
                        copyToClipboard('000000000000000000000000000000000000')
                      }
                    >
                      <MdContentCopy />
                    </button>
                  </td>
                  <td>
                    <strong>R$ 20.000,00</strong>
                  </td>
                  <td>00/00/0000</td>
                  <td>
                    Nenhuma manifestação <MdQueryBuilder size={20} />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="d-flex justify-content-end align-items-center mt-2 mb-3 mr-4">
              <div>
                Exibir:
                <button
                  type="button"
                  aria-controls="simple-menu"
                  className="ml-4"
                  aria-haspopup="true"
                  style={{ color: '#444' }}
                  onClick={handleClickMenu}
                >
                  {perPage} <ExpandMore color="primary" />
                </button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      setPage(1);
                      setPerPage(25);
                      handleClose();
                    }}
                  >
                    25
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setPage(1);
                      setPerPage(50);
                      handleClose();
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setPage(1);
                      setPerPage(100);
                      handleClose();
                    }}
                  >
                    100
                  </MenuItem>
                </Menu>
                <span className="mr-4 ml-4">
                  {page * perPage - perPage + 1}-
                  {/* titles.filter(
                (n, i) =>
                  i + 1 > page * perPage - perPage && i + 1 <= page * perPage
              ).length +
                page * perPage -
                perPage}{' '}
              de {titles.length */}
                </span>
                <button
                  type="button"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  <ArrowBackIos style={{ fontSize: '12px' }} />
                </button>
                <button type="button" onClick={() => setPage(page + 1)}>
                  <ArrowForwardIos style={{ fontSize: '12px' }} />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {!hasEcnpj && !loading && (
        <NoEcnpjInstalled>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: noEcnpj,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={250}
            style={{ margin: '0px' }}
          />
          <div className="ml-4 message">
            <h4>Nenhum e-CNPJ instalado em nosso sistema</h4>
            <p>
              Para utilizar este serviço é necessário instalar o e-CNPJ de sua
              empresa em nossa aplicação.
            </p>
            <Link to="/cadastro/ecnpj">Instalar e-CNPJ</Link>
          </div>
        </NoEcnpjInstalled>
      )}
    </Container>
  );
};

export default ConsNotes;
