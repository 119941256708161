export const partner = (value: number) => {
  switch (value) {
    case 1:
      return "Acionista";
    case 2:
      return "Acionista Controlador";
    case 3:
      return "Acionista Diretor";
    case 4:
      return "Acionista Presidente";
    case 5:
      return "Administrador";
    case 6:
      return "Administradora de consórcio de Empresas ou Grupo de Empresas";
    case 7:
      return "Comissário";
    case 8:
      return "Conselheiro de Administração";
    case 9:
      return "Curador";
    case 10:
      return "Diretor";
    case 11:
      return "Interventor";
    case 12:
      return "Inventariante";
    case 13:
      return "Liquidante";
    case 14:
      return "Mãe";
    case 15:
      return "Pai";
    case 16:
      return "Presidente";
    case 17:
      return "Procurador";
    case 18:
      return "Secretário";
    case 19:
      return "Síndico (Condomínio)";
    case 20:
      return "Sociedade Consorciada";
    case 21:
      return "Sociedade Filiada";
    case 22:
      return "Sócio";
    case 23:
      return "Sócio Capitalista";
    case 24:
      return "Sócio Comanditado";
    case 25:
      return "Sócio Comanditário";
    case 26:
      return "Sócio de Indústria";
    case 27:
      return "Sócio Residente ou Domiciliado no Exterior";
    case 28:
      return "Sócio-Gerente";
    case 29:
      return "Sócio ou Acionista Incapaz ou Relativamente Incapaz (exceto menor)";
    case 30:
      return "Sócio ou Acionista Menor (Assistido/Representado)";
    case 31:
      return "Sócio Ostensivo";
    case 32:
      return "Tabelião";
    case 33:
      return "Tesoureiro";
    case 34:
      return "Titular de Empresa Individual Imobiliária";
    case 35:
      return "Tutor";
    case 36:
      return "Gerente-Delegado";
    case 37:
      return "Sócio Pessoa Jurídica Domiciliado no Exterior";
    case 38:
      return "Sócio Pessoa Física Residente ou Domiciliado no Exterior";
    case 39:
      return "Diplomata";
    case 40:
      return "Cônsul";
    case 41:
      return "Representante de Organização Internacional";
    case 42:
      return "Oficial de Registro";
    case 43:
      return "Responsável";
    case 44:
      return "Sócio Participante";
    case 45:
      return "Sócio Investidor";
    case 46:
      return "Ministro de Estado das Relações Exteriores";
    case 47:
      return "Sócio Pessoa Física Residente no Brasil";
    case 48:
      return "Sócio Pessoa Jurídica Domiciliado no Brasil";
    case 49:
      return "Sócio-Administrador";
    case 50:
      return "Empresário";
    case 51:
      return "Candidato a Cargo Político Eletivo";
    case 52:
      return "Sócio com Capital";
    case 53:
      return "Sócio sem Capital";
    case 54:
      return "Fundador";
    case 55:
      return "Sócio Comanditado Residente no Exterior";
    case 56:
      return "Sócio Comanditário Pessoa Física Residente no Exterior";
    case 57:
      return "Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior";
    case 58:
      return "Sócio Comanditário Incapaz";
    case 59:
      return "Produtor Rural";
    case 60:
      return "Cônsul Honorário";
    case 61:
      return "Responsável Indigena";
    case 62:
      return "Representante das Instituições Extraterritoriais";
    case 63:
      return "Cotas em Tesouraria";
    case 64:
      return "Administrador Judicial";
    case 65:
      return "Titular Pessoa Física Residente ou Domiciliado no Brasil";
    case 66:
      return "Titular Pessoa Física Residente ou Domiciliado no Exterior";
    case 67:
      return "Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)";
    case 68:
      return "Titular Pessoa Física Menor (Assistido/Representado)";
    case 69:
      return "Beneficiário Final";
    case 70:
      return "Administrador Residente ou Domiciliado no Exterior";
    case 71:
      return "Conselheiro de Administração Residente ou Domiciliado no Exterior";
    case 72:
      return "Diretor Residente ou Domiciliado no Exterior";
    case 73:
      return "Presidente Residente ou Domiciliado no Exterior";
    case 74:
      return "Sócio-Administrador Residente ou Domiciliado no Exterior";
    case 75:
      return "Fundador Residente ou Domiciliado no Exterior";
    case 76:
      return "Protetor";
    case 77:
      return "Vice-Presidente";
    case 78:
      return "Titular Pessoa Jurídica Domiciliada no Brasil";
    case 79:
      return "Titular Pessoa Jurídica Domiciliada no Exterior";
    default:
      return "";
  }
};
