import React, { memo, useState, useEffect } from 'react';

import {
  LinearProgress,
  TextField,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { FaPrint, FaSearch, FaTimes } from 'react-icons/fa';

import { format } from 'date-fns';

import { useDispatch } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';

import { Table, Row, Col } from 'react-bootstrap';
import useSelector from '~/store/useSelector';
import {
  normalizeCpfCnpj,
  normalizeAccBank,
  normalizeCurrency,
} from '../../utils';
import { signOut } from '~/store/modules/auth/actions';
import IFee from '../../IFee';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Settled: React.FC<IProps> = ({ handleNextItem }) => {
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    payerDocument: '',
    document: '',
    value: '',
  });
  const [fees, setFees] = useState<IFee[]>([]);

  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.user);

  async function loadTitles(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.startDate) {
        query.push(`startDate=${filters.startDate}`);
      }
      if (filters.endDate) {
        query.push(`endDate=${filters.endDate}`);
      }
      if (filters.payerDocument) {
        query.push(
          `payerDocument=${filters.payerDocument.replace(/[^\d]/g, '')}`
        );
      }
      if (filters.document) {
        query.push(`document=${filters.document}`);
      }
      if (filters.value) {
        query.push(`value=${filters.value.replace(/[^\d]/g, '')}`);
      }
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perPage=${perPage}`);
    }
    query.push('payed=true');
    setLoading(true);
    try {
      const { data } = await axios.get<IFee[]>(
        `${
          process.env.REACT_APP_GATEWAY_OPERATION
        }/operations/fees/companies/me?${query.map((i) => i).join('&')}`
      );
      setFees(data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadTitles();
  }, [id, page, perPage]); // eslint-disable-line

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div>
          <h4 className="m-0">Tarifas Liquidadas</h4>
        </div>
        <div>
          <Button
            color="default"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={fees.length === 0}
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/fees/companies/pdf/1/${id}`,
                '_blank'
              );
            }}
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
            disabled={fees.length === 0}
            onClick={() => loadTitles()}
          >
            <FaSearch className="mr-2" />
            Filtrar
          </Button>
          {Object.entries(filters).filter(([k, v], i) => !!v).length > 0 && (
            <Button
              color="default"
              variant="contained"
              type="button"
              onClick={() => {
                setFilters({
                  startDate: '',
                  endDate: '',
                  payerDocument: '',
                  document: '',
                  value: '',
                });
                loadTitles(true);
              }}
            >
              <FaTimes className="mr-2" />
              Limpar filtros
            </Button>
          )}
        </div>
      </div>
      <div className="content_component">
        <div className="filters">
          <Row className="mb-2">
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  id="date"
                  type="date"
                  label="De"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.startDate}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setFilters({ ...filters, startDate: e.target.value });
                    }
                  }}
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  id="date"
                  label="até"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filters.endDate}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setFilters({ ...filters, endDate: e.target.value });
                    }
                  }}
                />
              </div>
            </Col>
            <Col sm={4}>
              <div className="input-group">
                <TextField
                  label="CPF/CNPJ Sacado"
                  value={filters.payerDocument}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      payerDocument: normalizeCpfCnpj(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="Documento"
                  value={filters.document}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      document: normalizeAccBank(e.target.value),
                    })
                  }
                />
              </div>
            </Col>
            <Col sm={2}>
              <div className="input-group">
                <TextField
                  label="Valor"
                  value={filters.value}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      value:
                        Number(e.target.value.replace(/[^\d]/g, '')) === 0
                          ? ''
                          : normalizeCurrency(
                              Number(e.target.value.replace(/[^\d]/g, ''))
                            ),
                    })
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr>
              <th>Nr. Documento</th>
              <th>Vencimento</th>
              <th>Sacado</th>
              <th>Vlr. Título</th>
              <th>Tipo</th>
              <th>Dt. Liquidação</th>
            </tr>
          </thead>
          <tbody>
            {fees.map((fee, index) => (
              <tr key={String(index)}>
                <td>{fee.titleNumberDocument}</td>
                <td>{format(new Date(fee.titleDate), 'dd/MM/yyyy')}</td>
                <td>
                  {fee.payerName}
                  <strong>{normalizeCpfCnpj(fee.payerDocument)}</strong>
                </td>
                <td>R$ {normalizeCurrency(fee.feesValue)}</td>
                <td>{fee.feesType}</td>
                <td>{format(new Date(fee.feesPaymentDate), 'dd/MM/yyyy')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end align-items-center mt-2 mb-3 mr-4">
          <div>
            Exibir:
            <button
              type="button"
              aria-controls="simple-menu"
              className="ml-4"
              aria-haspopup="true"
              style={{ color: '#444' }}
              onClick={handleClickMenu}
            >
              {perPage} <ExpandMore color="primary" />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(25);
                  handleClose();
                }}
              >
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(50);
                  handleClose();
                }}
              >
                50
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setPage(1);
                  setPerPage(100);
                  handleClose();
                }}
              >
                100
              </MenuItem>
            </Menu>
            <span className="mr-4 ml-4">
              {page * perPage - perPage + 1}-{page * perPage}
            </span>
            <button
              type="button"
              disabled={page === 1 || loading}
              onClick={() => setPage(page - 1)}
            >
              <ArrowBackIos style={{ fontSize: '12px' }} />
            </button>
            <button
              type="button"
              onClick={() => setPage(page + 1)}
              disabled={fees.length < perPage || loading}
            >
              <ArrowForwardIos style={{ fontSize: '12px' }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Settled);
