import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import SideMenu from './components/SideMenu';
import Confirmation from './components/Confirmation';

import Home from './Home';
import Register from './Register';
import Sacados from './Sacados';
import SacadoDetail from './SacadoDetail';
import Titles from './Titles';
import Fees from './Fees';
import Wallet from './Wallet';
import Service from './Service';
import Anticipation from './Anticipation';
import Billet from './Billet';
import CheckAnticipation from './CheckAnticipation';
import NfseAnticipation from './NfseAnticipation';
import Configuration from './Configuration';
import Profile from './Profile';
import Operations from './Operations';
import ImportXml from './ImportXml';
import Faq from './Faq';

import { Container } from './styles';

import light from './light';
import dark from './dark';

const AuthPage: React.FC = () => {
  const [theme, setTheme] = useState<string | null | undefined>(
    localStorage.getItem('theme_active')
  );

  const [sideOpen, setSideOpen] = useState(false);

  function toggleTheme(label: string) {
    setTheme(label);
    localStorage.setItem('theme_active', label);
  }

  return (
    <ThemeProvider theme={theme === 'dark' ? dark : light}>
      <Container>
        <Confirmation />
        <Header toggleTheme={(e) => toggleTheme(e)} />
        <div className="container" id="section_main">
          <div className="grid_main">
            <div
              id="side_menu_container"
              className={`${sideOpen ? 'opened' : ''}`}
              style={{ transition: '500ms' }}
              onMouseLeave={() => setSideOpen(false)}
              onMouseEnter={() => setSideOpen(true)}
            >
              <button
                type="button"
                id="bars_button"
                onClick={() => setSideOpen(!sideOpen)}
              >
                <i className={`bar ${sideOpen ? 'times' : ''}`} />
                <i className={`bar ${sideOpen ? 'times' : ''}`} />
                <i className={`bar ${sideOpen ? 'times' : ''}`} />
              </button>
              <SideMenu open={sideOpen} />
            </div>
            <div style={{ transition: '500ms' }}>
              <div>
                <Switch>
                  <Route path="/dashboard" exact component={Home} />
                  <Route path="/cadastro/:current" exact component={Register} />
                  <Route path="/tarifas/:current" exact component={Fees} />
                  <Route path="/titulos/:current" exact component={Titles} />
                  <Route path="/boletos" exact component={Billet} />
                  <Route path="/servicos/:current" exact component={Service} />
                  <Route path="/resumo/:current" exact component={Wallet} />
                  <Route path="/minha-conta" exact component={Profile} />
                  <Route path="/faq" exact component={Faq} />
                  <Route
                    path="/configuracoes/usuarios"
                    exact
                    component={Configuration}
                  />
                  <Route
                    path="/operacoes/antecipar-recebiveis/nfe-cte"
                    exact
                    component={Anticipation}
                  />
                  <Route
                    path="/operacoes/antecipar-recebiveis/cheque"
                    exact
                    component={CheckAnticipation}
                  />
                  <Route
                    path="/operacoes/antecipar-recebiveis/nfse"
                    exact
                    component={NfseAnticipation}
                  />
                  <Route path="/operacoes/sacados" exact component={Sacados} />
                  <Route
                    path="/operacoes/sacados/:id"
                    exact
                    component={SacadoDetail}
                  />
                  <Route path="/operacoes/lista" exact component={Operations} />
                  <Route
                    path="/operacoes/importar-xml"
                    exact
                    component={ImportXml}
                  />
                  {/* /operacoes/import-xml
/configuracoes/pend-financeira
/configuracoes/boletos */}
                  <Redirect to="/dashboard" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default AuthPage;
