import React from 'react';

import { TextField, MenuItem, Button } from '@material-ui/core';
import { HiDocumentReport } from 'react-icons/hi';
import { ManifestContainer } from '../../styles';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const Manifest: React.FC<IProps> = ({ open, onClose }) => {
  return (
    <ManifestContainer className={`${open ? 'opened' : ''}`}>
      <h4>
        <HiDocumentReport className="mr-2" />
        Realizar Manifestação
      </h4>
      <div className="d-flex align-items-center mt-4">
        <TextField
          label="CNPJ"
          disabled
          className="mr-2"
          size="small"
          value="00.000.000"
          variant="outlined"
        />
        /
        <TextField
          className="ml-2"
          size="small"
          style={{ maxWidth: '100px' }}
          variant="outlined"
          placeholder="0000-00"
        />
      </div>
      <TextField
        label="Chave NF-e"
        className="mt-3"
        size="small"
        variant="outlined"
      />
      <TextField
        label="Manifestação"
        select
        className="mt-3"
        variant="outlined"
        size="small"
      >
        <MenuItem value={1}>Ciência da Operação</MenuItem>
        <MenuItem value={2}>Confirmação da Operação</MenuItem>
        <MenuItem value={3}>Desconhecimento da Operação</MenuItem>
        <MenuItem value={4}>Operação não Realizada</MenuItem>
      </TextField>
      <TextField
        label="Justificativa"
        className="mt-3"
        size="small"
        variant="outlined"
      />
      <div className="d-flex mt-4">
        <Button type="button" color="primary" variant="contained">
          Enviar
        </Button>
        <Button
          type="button"
          color="primary"
          variant="outlined"
          className="ml-2"
          onClick={() => onClose()}
        >
          Cancelar
        </Button>
      </div>
    </ManifestContainer>
  );
};

export default Manifest;
