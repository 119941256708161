import styled from "styled-components";

import { lighten } from 'polished';

export const Container = styled.div`
  padding:15px 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  div.header_component {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  div.content_component {
    margin: 20px 0px;
    padding: 20px 0px;
    height: 100%;
    width: 100%;
    fieldset{
      .row {
        margin: 0 -15px;
      }
      h4 {
        font-size: 14px !important;
        font-weight: 500;
        margin-bottom: 20px;
      }
      legend.MuiFormLabel-root {
        font-size: 9px !important;
      }
    }
    div.content_component-members {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px;
      @media screen and (max-width: 850px) {
        & {
          grid-template-columns: 1fr;
        }
      }
      div.member-item:not(.detached) {
        button:not(.button-close) {
          width: calc(100% - 30px) !important;
        }
      }
      div.member-item {
        width: 100%;
        padding: 0 0 15px;
        background:${props => lighten(0.1, props.theme.colors.background)};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        > div {
          width: 100%;
          padding: 20px 20px 10px;
        }
        div.member-item-header {
          background: ${(props) => props.theme.colors.primary};
          color: #fff;
          font-weight: 900;
          border-radius: 8px 8px 0px 0px;
          padding: 20px 20px 10px;
          width: 100%;
          text-transform: uppercase;
        }
        p {
          color: ${props => props.theme.colors.text};
          font-size: 13px;
        }
        button.button-close {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          background: #f7f7f7;
          border: none;
          transition: 300ms;
          &:hover {
            background: #f1f1f1;
            transform: scale(1.1);
          }
        }
      }
      div.detached {
        border: 2px dashed ${(props) => props.theme.colors.primary};
        padding: 10px;
        justify-content: center;
        align-items: center;
        background:${props => props.theme.title === 'dark' && lighten(0.1, props.theme.colors.background)};
        * {
          color: ${props => props.theme.title === 'dark' && `${props.theme.colors.text} !important`};
        }
        form {
          display: block;
          width: calc(100% - 30px);
        }
        button {
          font-size: 10px;
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
  }
`;
