import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { TextField, Button } from "@material-ui/core";
import { Container } from "./styles";

import axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";

const ActivePassword:React.FC = ({ match }: any) => {
  const [values, setValues] = useState({
    password: "",
    confirmPassword:"",
  });
  const [error, setError] = useState({
    password: "",
    confirmPassword:"",
  });
  const [msg, setMsg] = useState("")

  const { token } = match.params;
  const history = useHistory();

  if(!token || token.length === 0) {
    history.push('/auth/home');
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setError({
      password: "",
      confirmPassword:"",
    })
    let lock_error = {
      password: "",
      confirmPassword:"",
    }
    if(values.password.length === 0) {
      lock_error.password = "Preencha esse Campo";
    }
    if(values.confirmPassword.length === 0) {
      lock_error.confirmPassword = "Preencha esse Campo";
    }
    if(values.password !== values.confirmPassword) {
      lock_error = {
        password: "Senhas não coincidem",
        confirmPassword:"Senhas não coincidem",
      }
    }
    if(lock_error.password !== "" || lock_error.confirmPassword !== "") {
      return setError(lock_error);
    }
    try {
      await axios.post(`/users/user/active`, {
        newPassword: values.password,
        token,
      });
      history.push('/auth/login');
      toast.success('Conta ativada');
    }catch(err) {
      if(err.response && err.response.status === 400) {
        setMsg("Link inválido, requesite um novo link de recuperação.");
      }else if(err.response && err.response.status === 404){
        setMsg("Link inválido, requesite um novo link de recuperação.");
      }else if(err.response && err.response.status === 500){
        setMsg("Ocorreu um erro em nossos servidores, tente novamente mais tarde");
      }else{
        setMsg("Ocorreu um erro, tente novamente mais tarde");
      }
    }
  }

  return (
    <Container>
      <div id="wrap-login">
        <div className="logo-section">
          <img src="/assets/images/logo-branca.png" alt="BFC Capital Partners LTDA" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <h4>Ativar Conta</h4>
            <p className="descript">Falta muito pouco para você acessar sua conta! Adicione uma senha e comece a utilizar.</p>
            {msg !== "" && (
              <Alert severity="error">
                {msg}
              </Alert>
            )}
            <TextField
              type="password"
              label="Senha"
              value={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              style={{ animationDelay: "150ms" }}
              error={error.password !== ""}
              helperText={error.password}
            />
            <TextField
              type="password"
              label="Confirmar Senha"
              value={values.confirmPassword}
              onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
              style={{ animationDelay: "300ms" }}
              error={error.confirmPassword !== ""}
              helperText={error.confirmPassword}
            />
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
              >
                Ativar Conta
            </Button>
          </div>
        </form>
      </div>
    </Container>
  );
}

export default ActivePassword;