import { isPast } from "date-fns";
import axios from "axios";

interface IMember {
  id?: string;
  name: string;
  cpf: string;
  rg: string;
  rgEmmiter: string;
  eCpf: string;
  nacionality: string;
  profission: string;
  email: string;
  telephone: string;
  birthDate: string;
  motherName: string;
  maritalStatus: string;
  maritalStatusRegime: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}

enum EMember {
  name = "name",
  cpf = "cpf",
  rg = "rg",
  rgEmmiter = "rgEmmiter",
  eCpf = "eCpf",
  nacionality = "nacionality",
  profission = "profission",
  email = "email",
  telephone = "telephone",
  birthDate = "birthDate",
  motherName = "motherName",
  maritalStatus = "maritalStatus",
  maritalStatusRegime = "maritalStatusRegime",
  logradouro = "logradouro",
  numero = "numero",
  complemento = "complemento",
  cep = "cep",
  estado = "estado",
  cidade = "cidade",
  bairro = "bairro",
}

enum EMemberSpouses {
  name = "name",
  cpf = "cpf",
  rg = "rg",
  rgEmmiter = "rgEmmiter",
  eCpf = "eCpf",
  nacionality = "nacionality",
  profission = "profission",
  email = "email",
  telephone = "telephone",
  birthDate = "birthDate",
  motherName = "motherName",
}

interface IMemberSpouses {
  cpfMember: string;
  name: string;
  cpf: string;
  rg: string;
  rgEmmiter: string;
  eCpf: string;
  nacionality: string;
  profission: string;
  email: string;
  birthDate: string;
  motherName: string;
}

function cpfIsValid(cpf: string) {
  var soma;
  var resto;
  soma = 0;
  let strCPF = cpf.replace(/\D/g, "");
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function emailIsValid(email: string) {
  if (email.indexOf("@") === -1) {
    return false;
  }
  let after = email.split("@");
  if (after[1].indexOf(".") === -1) {
    return false;
  }

  return true;
}

const parseToDate = (value: string): Date => {
  const splitedDate = value.split("/");
  return new Date(
    Number(splitedDate[2]),
    Number(splitedDate[1]),
    Number(splitedDate[0])
  );
};

interface IError {
  name: string;
  cpf: string;
  rg: string;
  rgEmmiter: string;
  eCpf: string;
  nacionality: string;
  profission: string;
  email: string;
  telephone: string;
  birthDate: string;
  motherName: string;
  maritalStatus: string;
  maritalStatusRegime: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}

interface IValidation {
  count_errors: number;
  errors: IError;
}

export const validatorMember = async (
  values: IMember
): Promise<IValidation> => {
  let errors = {
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    telephone: "",
    birthDate: "",
    motherName: "",
    maritalStatus: "",
    maritalStatusRegime: "",
    logradouro: "",
    numero: "",
    complemento: "",
    cep: "",
    estado: "",
    cidade: "",
    bairro: "",
  };
  let count_errors = 0;

  const defaultError = (n: EMember) => {
    errors[n] = "Preencha esse campo";
    count_errors += 1;
  };

  if (values.name === "" || !values.name) {
    defaultError(EMember.name);
  }

  try {
    const { data: members } = await axios.get(`/partners/physical-persons`);
    if (values.cpf === "" || !values.cpf) {
      defaultError(EMember.cpf);
    } else if (values.cpf.length < 14) {
      defaultError(EMember.cpf);
    } else if (!cpfIsValid(values.cpf)) {
      errors.cpf = "Cpf não é válido";
      count_errors += 1;
    } else if (
      !values.id &&
      members.find(
        (m: any) => m.cpf.replace(/\D/g, "") === values.cpf.replace(/\D/g, "")
      )
    ) {
      errors.cpf = "Cpf já utilizado em outro sócio";
      count_errors += 1;
    } else if (
      values.id &&
      members.find(
        (m: any) =>
          m.cpf.replace(/\D/g, "") === values.cpf.replace(/\D/g, "") &&
          m.id !== values.id
      )
    ) {
      errors.cpf = "Cpf já utilizado em outro sócio";
      count_errors += 1;
    }
  } catch (err) {}

  if (values.rg === "") {
    defaultError(EMember.rg);
  }

  if (values.rgEmmiter === "" || !values.rgEmmiter) {
    defaultError(EMember.rgEmmiter);
  }

  if (values.eCpf === "" || !values.eCpf) {
    defaultError(EMember.eCpf);
  }

  if (values.nacionality === "" || !values.nacionality) {
    defaultError(EMember.nacionality);
  }

  if (values.profission === "" || !values.profission) {
    defaultError(EMember.profission);
  }

  if (values.email === "" || !values.email) {
    defaultError(EMember.email);
  } else if (!emailIsValid(values.email)) {
    defaultError(EMember.email);
  }

  if (values.telephone === "" || !values.telephone) {
    defaultError(EMember.telephone);
  } else if (values.telephone.length < 14) {
    defaultError(EMember.telephone);
  }

  if (values.birthDate === "" || !values.birthDate) {
    defaultError(EMember.birthDate);
  } else if (values.birthDate.length < 10) {
    defaultError(EMember.birthDate);
  } else if (!isPast(parseToDate(values.birthDate))) {
    errors.birthDate = "PREENCHA UMA DATA VÁLIDA";
    count_errors += 1;
  }

  if (values.motherName === "" || !values.motherName) {
    defaultError(EMember.motherName);
  }

  if (values.maritalStatus === "" || !values.maritalStatus) {
    defaultError(EMember.maritalStatus);
  }

  if (
    values.maritalStatus === "Casado(a)" &&
    (values.maritalStatusRegime === "" || !values.maritalStatusRegime)
  ) {
    defaultError(EMember.maritalStatusRegime);
  }

  if (values.logradouro === "" || !values.logradouro) {
    defaultError(EMember.logradouro);
  }

  if (values.numero === "" || !values.numero) {
    defaultError(EMember.numero);
  }

  if (values.cep === "" || !values.cep) {
    defaultError(EMember.cep);
  } else if (values.cep.length < 9) {
    defaultError(EMember.cep);
  }

  if (values.estado === "" || !values.estado) {
    defaultError(EMember.estado);
  }

  if (values.cidade === "" || !values.cidade) {
    defaultError(EMember.cidade);
  }

  if (values.bairro === "" || !values.bairro) {
    defaultError(EMember.bairro);
  }

  return {
    count_errors,
    errors,
  };
};

export const validatorMemberSpouses = (values: IMemberSpouses) => {
  let errors = {
    name: "",
    cpf: "",
    rg: "",
    rgEmmiter: "",
    eCpf: "",
    nacionality: "",
    profission: "",
    email: "",
    telephone: "",
    birthDate: "",
    motherName: "",
  };
  let count_errors = 0;

  const defaultError = (n: EMemberSpouses) => {
    errors[n] = "Preencha esse campo";
    count_errors += 1;
  };

  if (values.name === "" || !values.name) {
    defaultError(EMemberSpouses.name);
  }

  if (values.cpf === "" || !values.cpf) {
    defaultError(EMemberSpouses.cpf);
  } else if (values.cpf.length < 14) {
    defaultError(EMemberSpouses.cpf);
  } else if (!cpfIsValid(values.cpf)) {
    errors.cpf = "Cpf não é válido";
    count_errors += 1;
  } else if (values.cpf.replace(/\D/g, "") === values.cpfMember.replace(/\D/g, "")) {
    errors.cpf = "Cpf duplicado";
    count_errors += 1;
  }

  if (values.rg === "") {
    defaultError(EMemberSpouses.rg);
  }

  if (values.rgEmmiter === "" || !values.rgEmmiter) {
    defaultError(EMemberSpouses.rgEmmiter);
  }

  if (values.eCpf === "" || !values.eCpf) {
    defaultError(EMemberSpouses.eCpf);
  }

  if (values.nacionality === "" || !values.nacionality) {
    defaultError(EMemberSpouses.nacionality);
  }

  if (values.profission === "" || !values.profission) {
    defaultError(EMemberSpouses.profission);
  }

  if (values.email === "" || !values.email) {
    defaultError(EMemberSpouses.email);
  } else if (!emailIsValid(values.email)) {
    defaultError(EMemberSpouses.email);
  }

  if (values.birthDate === "" || !values.birthDate) {
    defaultError(EMemberSpouses.birthDate);
  } else if (values.birthDate.length < 10) {
    defaultError(EMemberSpouses.birthDate);
  } else if (!isPast(parseToDate(values.birthDate))) {
    errors.birthDate = "PREENCHA UMA DATA VÁLIDA";
    count_errors += 1;
  }

  if (values.motherName === "" || !values.motherName) {
    defaultError(EMemberSpouses.motherName);
  }

  return {
    count_errors,
    errors,
  };
};
