import styled from 'styled-components';

import { lighten, darken } from 'polished';

export const Container = styled.div`
  padding:15px 0px;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
  div.header_component {
    border-bottom:1px solid #eee;
    width:100%;
  }
  div.content_component {
    margin: 20px 0px;
    padding: 20px 10px;
    height:100%;
    width:100%;
    p {
      strong{
        color:${props => props.theme.colors.primary};
      }
    }
    input[type=file] {
      display:none;
    }
    label#e_cnpj_label {
      display:block;
      width:100%;
      margin-top:15px;
      padding:20px 15px;
      text-align:center;
      border:1px dashed ${props => props.theme.colors.primary};
      border-radius:6px;
      text-transform:uppercase;
      font-weight:900;
      color:${props => props.theme.colors.primary};
      cursor:pointer;
      transition:300ms;
      &:hover {
        background:${props => props.theme.title === 'light' ? darken(0.1, props.theme.colors.background) : lighten(0.1, props.theme.colors.background)};
      }
    }
    #detached_file {
      margin-top:25px;
      padding:15px;
      background:${props => props.theme.colors.primary};
      border-radius:6px;
      #grid_detail {
        display:grid;
        grid-template-columns:1fr 2fr;
        grid-gap:30px;
        width:100%;
        .item_grid {
          display:flex;
          justify-content: center;
          align-items:center;
          flex-direction:column;
          text-align:center;
          .d-flex {
            width:100%;
          }
          h5 {
            font-size:16px;
            color:#fff;
          }
          &:nth-child(1) {
            background:${props => props.theme.colors.primary};
            padding:20px 15px;
            border-radius: 6px;
          }
          &:nth-child(2) {
            padding:20px;
            border:1px solid ${props => props.theme.colors.primary};
            border-radius:6px;
            background:#fff;
          }
        }
      }
    }
    #ecnpj-list {
      margin-top:40px;
      h6 {
        position:relative;
      }
      h6::before {
        content: '';
        position:absolute;
        left:0%;
        width:36%;
        height:2px;
        background:${props => props.theme.colors.primary};
        border-radius:2px;
        top:50%;
      }
      h6::after {
        content: '';
        position:absolute;
        right:0%;
        width:36%;
        height:2px;
        background:${props => props.theme.colors.primary};
        border-radius:2px;
        top:50%;
      }
      div#grid_ecnpj {
        margin-top:24px;
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:20px;
        @media screen and (max-width: 1000px) {
          & {
            grid-template-columns:1fr;
          }
        }
        div.list-item {
          padding:20px;
          border-radius:8px;
          background:${props => props.theme.title === 'dark' && lighten(0.2, props.theme.colors.background)};
          box-shadow:0px 0px 6px ${props => props.theme.title === 'light' ? 'rgba(0,0,0,.1)' : 'rgba(255,255,255,.1)'};
          position:relative;
          h5 {
            font-size:18px;
          }
          button.button-close {
            position:absolute;
            top: -10px;
            right: -10px;
            width:25px;
            height:25px;
            display:flex;
            justify-content:center;
            align-items:center;
            border-radius:50%;
            box-shadow:0px 0px 5px rgba(0,0,0,.2);
            background:#f7f7f7;
            border:none;
            transition:300ms;
            &:hover {
              background:#f1f1f1;
              transform:scale(1.1);
            }
            svg {
              font-size: 1.1rem
            }
          }
        }
      }
    }
  }
`;
