import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import {
  Menu,
  MenuItem,
  LinearProgress,
  Checkbox,
  TextField,
  Button,
} from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos, ExpandMore } from '@material-ui/icons';
import { FaPrint, FaSearch, FaTimes } from 'react-icons/fa';
import { Table, Row, Col } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { format, addMinutes } from 'date-fns';
import useSelector from '~/store/useSelector';
import { Container } from './styles';
import { signOut } from '../../../store/modules/auth/actions';

import {
  normalizeCpfCnpj,
  normalizeCurrency,
  normalizeAccBank,
} from '../utils/normalize';

import { IBillet } from './IBillet';

const Billet: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [billets, setBillets] = useState<IBillet[]>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [selected, setSelected] = useState<IBillet[]>([]);

  const [filters, setFilters] = useState({
    endDate: '',
    startDate: '',
    payerDocument: '',
    status: '',
    docto: '',
    bordero: '',
    newExpireDate: '',
    borderoId: '',
    orderBy: 'expireDate',
  });
  const dispatch = useDispatch();

  const { id } = useSelector((state) => state.user);

  async function loadBillets(refresh?: boolean) {
    const query: string[] = [];
    if (!refresh) {
      if (filters.startDate) {
        query.push(`startDate=${filters.startDate}`);
      }
      if (filters.endDate) {
        query.push(`endDate=${filters.endDate}`);
      }
      if (filters.payerDocument) {
        query.push(
          `payerDocument=${filters.payerDocument.replace(/[^\d]/g, '')}`
        );
      }
      if (filters.docto) {
        query.push(`document=${filters.docto}`);
      }
      if (filters.borderoId) {
        query.push(`borderoId=${filters.borderoId}`);
      }
    }
    if (page) {
      query.push(`page=${page}`);
    }
    if (perPage) {
      query.push(`perPage=${perPage}`);
    }

    query.push(`titleStatusId=3,11`);
    query.push(`order=ASC`);
    setLoading(true);
    try {
      const { data } = await axios.get<IBillet[]>(
        `${
          process.env.REACT_APP_GATEWAY_OPERATION
        }/operations/titles?type=D&${query.map((i) => i).join('&')}`
      );
      setBillets(data || []);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente');
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
        );
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      loadBillets();
    }
  }, [id, page, perPage, filters.orderBy]); // eslint-disable-line

  function handleSelect(item) {
    if (selected.find((i) => i.id === item.id)) {
      setSelected(selected.filter((i) => i.id !== item.id));
    } else {
      setSelected([...selected, item]);
    }
  }

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDownload() {
    window.open(
      `${
        process.env.REACT_APP_GATEWAY_OPERATION
      }/analysts/titles/boleto?titles=${selected
        .map((s) => s.referenceId)
        .join(',')}`
    );
  }

  return (
    <Container>
      <div id="billets_table_wrap">
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <h4>Boletos</h4>
          </div>
          <div>
            <Button
              color="default"
              variant="contained"
              className="mr-2"
              type="button"
              onClick={handleDownload}
              disabled={selected.length === 0}
            >
              <FaPrint className="mr-2" />
              Imprimir
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mr-2"
              type="button"
              disabled={billets.length === 0 || loading}
              onClick={() => loadBillets()}
            >
              <FaSearch className="mr-2" />
              Filtrar
            </Button>
            {Object.entries(filters).filter(
              ([k, v], i) => !!v && k !== 'orderBy'
            ).length > 0 && (
              <Button
                color="default"
                variant="contained"
                type="button"
                onClick={() => {
                  setFilters({
                    endDate: '',
                    startDate: '',
                    payerDocument: '',
                    status: '',
                    docto: '',
                    bordero: '',
                    newExpireDate: '',
                    borderoId: '',
                    orderBy: 'expireDate',
                  });
                  loadBillets(true);
                }}
              >
                <FaTimes className="mr-2" />
                Limpar filtros
              </Button>
            )}
          </div>
        </div>
        <div className="content_component">
          <div className="filters">
            <Row className="mb-2">
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="CPF/CNPJ do Sacado"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.payerDocument}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        payerDocument: normalizeCpfCnpj(e.target.value),
                      })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    type="date"
                    label="Vencimento de"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.startDate}
                    onChange={(e) =>
                      setFilters({ ...filters, startDate: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    label="até"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.endDate}
                    onChange={(e) =>
                      setFilters({ ...filters, endDate: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="Documento"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.docto}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        docto: normalizeAccBank(e.target.value),
                      })
                    }
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div className="input-group">
                  <TextField
                    type="text"
                    label="Bôrdero"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filters.borderoId}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        borderoId: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
          {loading && <LinearProgress />}
          <Table responsive hover>
            <thead>
              <tr>
                <th>Bôrdero</th>
                <th>Documento</th>
                <th>Vencimento</th>
                <th>Valor</th>
                <th>Sacado</th>
                <th>Nr. Bancário</th>
                <th>Observação</th>
                <th
                  onClick={() => {
                    if (selected.length === billets.length) {
                      setSelected([]);
                    } else {
                      setSelected(billets);
                    }
                  }}
                >
                  <Checkbox
                    checked={selected.length === billets.length}
                    color="primary"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {billets.length > 0 &&
                billets.map((billet, index) => {
                  const checked = selected.find((i) => i.id === billet.id);
                  const ourNumber = billet.ourNumber;
                  if(ourNumber !== ""){
                    return (
                      <tr
                        key={String(index)}
                        onClick={() => handleSelect(billet)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{billet.borderoId}</td>
                        <td>{billet.document}</td>
                        <td>
                          {format(
                            addMinutes(
                              new Date(billet.date),
                              new Date(billet.date).getTimezoneOffset()
                            ),
                            'dd/MM/yyyy'
                          )}
                        </td>
                        <td>R$ {normalizeCurrency(Number(billet.value))}</td>
                        <td>
                          {billet.payerJson
                            ? JSON.parse(billet.payerJson).name
                            : '-'}
                          <br />
                          <strong>
                            {billet.payerJson
                              ? normalizeCpfCnpj(
                                  JSON.parse(billet.payerJson).document
                                )
                              : '-'}
                          </strong>
                        </td>
                        <td>{billet.ourNumber}</td>
                        <td />
                        <td>
                          <Checkbox checked={!!checked} color="primary" />
                        </td>
                      </tr>
                    );
                  }
                  return null
                })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end align-items-center flex-wrap footer_component">
            <div>
              Exibir:
              <button
                type="button"
                aria-controls="simple-menu"
                className="ml-4"
                aria-haspopup="true"
                style={{ color: '#444' }}
                onClick={handleClickMenu}
              >
                {perPage} <ExpandMore color="primary" />
              </button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setPerPage(25);
                    handleClose();
                  }}
                >
                  25
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(50);
                    handleClose();
                  }}
                >
                  50
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setPerPage(100);
                    handleClose();
                  }}
                >
                  100
                </MenuItem>
              </Menu>
              <span className="mr-4 ml-4">
                {page * perPage - perPage + 1}-{page * perPage}
              </span>
              <button
                type="button"
                disabled={page === 1 || loading}
                onClick={() => setPage(page - 1)}
              >
                <ArrowBackIos style={{ fontSize: '12px' }} />
              </button>
              <button
                type="button"
                onClick={() => setPage(page + 1)}
                disabled={billets.length < perPage || loading}
              >
                <ArrowForwardIos style={{ fontSize: '12px' }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Billet;
