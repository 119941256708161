import React, { useState, useEffect, useMemo, memo } from 'react';

import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { FaAngleLeft, FaThumbtack } from 'react-icons/fa';
import useSelector from '~/store/useSelector';
import { modalOpen } from '~/store/modules/register/actions';

import { Container } from './styles';

import Dropdown from './Dropdown';

import { normalizeCurrency } from '../../utils/normalize';

interface IChangeCurrent {
  to: string;
  currentNumber: number;
}

interface IProps {
  open: boolean;
}

const SubMenu: React.FC<IProps> = ({ open }) => {
  const [openDropdown, setOpenDropdown] = useState<null | number>(null);
  const [path, setPath] = useState<null | string>(null);
  const [pathSub, setPathSub] = useState<null | string>(null);
  const [pathSubSub, setPathSubSub] = useState<null | string>(null);
  const [current, setCurrent] = useState<null | number>(null);
  const [limitVisible, setLimitVisible] = useState(
    localStorage.getItem('visible_limit') === 'true'
  );
  const [menuVisible, setMenuVisible] = useState(
    localStorage.getItem('hide_menu') === 'true'
  );

  const [menuItems] = useState([
    {
      item: 'cadastro',
      page: '',
      icon: '/assets/images/icon-register.png',
      description: 'Cadastro',
      isDropdown: true,
      submenu: [
        {
          item: 'informacao-geral',
          page: '/cadastro/informacao-geral',
          description: 'Informação Geral',
        },
        {
          item: 'ecnpj',
          page: '/cadastro/ecnpj',
          description: 'e-CNPJ',
        },
        {
          item: 'contas-bancarias',
          page: '/cadastro/contas-bancarias',
          description: 'Contas Bancárias',
        },
        {
          item: 'socios',
          page: '/cadastro/socios',
          description: 'Sócios',
        },
        {
          item: 'documentos',
          page: '/cadastro/documentos',
          description: 'Documentos',
        },
      ],
    },
    {
      item: 'operacoes',
      page: '',
      icon: '/assets/images/icon-antecipation.png',
      description: 'Operações',
      isDropdown: true,
      submenu: [
        {
          item: 'antecipar-recebiveis',
          page: '',
          description: 'Antecipar Recebíveis',
          isDropdown: true,
          submenu: [
            {
              item: 'nfe-cte',
              page: '/operacoes/antecipar-recebiveis/nfe-cte',
              description: 'NF-e / CT-e',
            },
            {
              item: 'nfse',
              page: '/operacoes/antecipar-recebiveis/nfse',
              description: 'NFS-e',
            },
            {
              item: 'cheque',
              page: '/operacoes/antecipar-recebiveis/cheque',
              description: 'Cheque',
            },
          ],
        },
        {
          item: 'lista',
          page: '/operacoes/lista',
          description: 'Borderôs',
        },
      ],
    },
    {
      item: 'boletos',
      page: '/boletos',
      icon: '/assets/images/icon-billet.png',
      description: 'Boletos',
      isDropdown: false,
      submenu: [],
    },
    {
      item: 'titulos',
      page: '',
      icon: '/assets/images/icon-titles.png',
      description: 'Títulos',
      isDropdown: true,
      submenu: [
        {
          item: 'a-vencer',
          page: '/titulos/a-vencer',
          description: 'A vencer',
        },
        {
          item: 'liquidados',
          page: '/titulos/liquidados',
          description: 'Liquidados',
        },
        {
          item: 'vencidos',
          page: '/titulos/vencidos',
          description: 'Vencidos',
        },
      ],
    },
    {
      item: 'tarifas',
      page: '',
      icon: '/assets/images/icon-fees.png',
      description: 'Tarifas',
      isDropdown: true,
      submenu: [
        {
          item: 'abertos',
          page: '/tarifas/abertos',
          description: 'Em Aberto',
        },
        {
          item: 'liquidadas',
          page: '/tarifas/liquidadas',
          description: 'Liquidadas',
        },
      ],
    },
    {
      item: 'servicos',
      page: '',
      icon: '/assets/images/icon-services.png',
      description: 'Serviços de consulta',
      isDropdown: true,
      submenu: [
        // {
        //   item: 'notas-contra',
        //   page: '/servicos/notas-contra',
        //   description: 'Notas Emitidas Contra',
        // },
        {
          item: 'empresa',
          page: '/servicos/empresa',
          description: 'Consulta de Empresa',
        },
        {
          item: 'protesto',
          page: '/servicos/protesto',
          description: 'Consulta de Protestos',
        },
      ],
    },
    {
      item: 'configuracoes',
      page: '',
      icon: '/assets/images/icon-config.png',
      description: 'Configurações',
      isDropdown: true,
      submenu: [
        {
          item: 'usuarios',
          page: '/configuracoes/usuarios',
          description: 'Usuários',
        },
      ],
    },
  ]);
  const { isEditing } = useSelector((state) => state.register);
  const { limit, limitUsed } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  function handlePath() {
    const pathArray = window.location.pathname.split('/');
    setPath(pathArray[1]);
    if (pathArray[2]) {
      setPathSub(pathArray[2]);
    }
    if (pathArray[3]) {
      setPathSubSub(pathArray[3]);
    }
    switch (pathArray[1]) {
      case 'cadastro':
        setCurrent(1);
        setOpenDropdown(1);
        break;
      case 'operacoes':
        setCurrent(2);
        setOpenDropdown(2);
        break;
      case 'boletos':
        setCurrent(3);
        setOpenDropdown(3);
        break;
      case 'titulos':
        setCurrent(4);
        setOpenDropdown(4);
        break;
      case 'tarifas':
        setCurrent(5);
        setOpenDropdown(5);
        break;
      case 'servicos':
        setCurrent(6);
        setOpenDropdown(6);
        break;
      case 'configuracoes':
        setCurrent(7);
        setOpenDropdown(7);
        break;
      default:
        setCurrent(999);
        setOpenDropdown(999);
        break;
    }
  }

  useEffect(() => {
    handlePath();
  }, []);

  const formatLimit = useMemo(() => {
    if (limit) {
      if (limitVisible) {
        if (limit - (limitUsed || 0) < 0) {
          return `R$ -${normalizeCurrency(
            limit - (limitUsed || 0) > 0
              ? limit - (limitUsed || 0)
              : (limit - (limitUsed || 0)) * -1
          )}`;
        }
        return `R$ ${normalizeCurrency(
          limit - (limitUsed || 0) > 0
            ? limit - (limitUsed || 0)
            : (limit - (limitUsed || 0)) * -1
        )}`;
      }
      return '**********';
    }
    return 'A Definir';
  }, [limit, limitUsed, limitVisible]);

  function handleSwipeVisible() {
    localStorage.setItem('visible_limit', String(!limitVisible));
    setLimitVisible(!limitVisible);
  }

  const handleFixed = () => {
    localStorage.setItem('hide_menu', String(!menuVisible));
    setMenuVisible(!menuVisible);
    open = !open;
  };

  const handleChangeCurrent = ({ to, currentNumber }: IChangeCurrent) => {
    if (isEditing) {
      if (window.location.pathname !== to) {
        dispatch(modalOpen({ modal_to: to }));
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      history.push(to);
    }
    handlePath();
  };

  return (
    <>
      <Container
        className={`shadow antecipation-orange d-flex ${
          !menuVisible ? 'opened-side' : open ? 'opened-side' : ''
        }`}
        style={{ animationDelay: '200ms' }}
      >
        <span>
          <img src="/assets/images/icon-money.png" alt="Money Icon" />
        </span>
        <div>
          <h4>Limite disponível</h4>
          <span className={!limitVisible ? 'no-visible' : ''}>
            {formatLimit}
          </span>
        </div>
        <button type="button" onClick={handleSwipeVisible}>
          {limitVisible ? <Visibility /> : <VisibilityOff />}
        </button>
      </Container>
      <Container
        className={`shadow ${
          !menuVisible ? 'opened-side' : open ? 'opened-side' : ''
        }`}
        id="menu_items"
        style={{ animationDelay: '300ms', position: 'relative' }}
      >
        <ul>
          {current &&
            openDropdown &&
            menuItems.map((li, index) => {
              if (li.isDropdown) {
                return (
                  <li key={String(index)}>
                    <button
                      type="button"
                      className={path === li.item ? 'active' : ''}
                      onClick={() => {
                        setOpenDropdown(index + 1);
                      }}
                    >
                      <span>
                        <img src={li.icon} alt={li.item} />
                      </span>
                      {li.description}
                    </button>
                    <Dropdown open={openDropdown === index + 1}>
                      {li.submenu.map((liSub, ind) => (
                        <li key={String(ind)}>
                          {liSub.isDropdown ? (
                            <>
                              <button
                                type="button"
                                className={
                                  pathSub === liSub.item ? 'active' : ''
                                }
                              >
                                {liSub.description}
                              </button>
                              <ul className="subDropdown">
                                {liSub.submenu.map((el, i) => (
                                  <li key={String(i)}>
                                    <button
                                      type="button"
                                      className={
                                        pathSubSub === el.item ? 'active' : ''
                                      }
                                      onClick={() =>
                                        handleChangeCurrent({
                                          to: el.page,
                                          currentNumber: index,
                                        })
                                      }
                                    >
                                      {el.description}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <button
                              type="button"
                              className={pathSub === liSub.item ? 'active' : ''}
                              onClick={() =>
                                handleChangeCurrent({
                                  to: liSub.page,
                                  currentNumber: index,
                                })
                              }
                            >
                              {liSub.description}
                            </button>
                          )}
                        </li>
                      ))}
                    </Dropdown>
                  </li>
                );
              }
              return (
                <li key={String(index)}>
                  <button
                    type="button"
                    className={current === index + 1 ? 'active' : ''}
                    onClick={() =>
                      handleChangeCurrent({
                        to: li.page,
                        currentNumber: index,
                      })
                    }
                  >
                    <span>
                      <img src={li.icon} alt={li.item} />
                    </span>
                    {li.description}
                  </button>
                </li>
              );
            })}
        </ul>
        {(open || !menuVisible) && (
          <button
            type="button"
            id="fixed_menu_button"
            style={{
              position: 'absolute',
              right: '0',
              bottom: '-45px',
              color: '#3757a1',
              fontSize: '14px',
            }}
            onClick={() => handleFixed()}
          >
            {menuVisible ? <FaThumbtack /> : <FaAngleLeft />}
            {menuVisible ? ` Fixar` : ` Recolher`}
          </button>
        )}
      </Container>
    </>
  );
};

export default memo(SubMenu);
