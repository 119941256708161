import {
  AnticipationActionTypes,
  ITitle,
  STORE_OPERATION,
  STORE_TITLES,
  RESET_ANTICIPATION,
  ADD_BANK_ACCOUNT,
} from './types';

interface IOperationId {
  operationId: string;
}

interface IStoreTitles {
  titles: ITitle[];
}

export function storeOperationId({
  operationId,
}: IOperationId): AnticipationActionTypes {
  return {
    type: STORE_OPERATION,
    payload: {
      operationId,
    },
  };
}

export function storeTitles({ titles }: IStoreTitles): AnticipationActionTypes {
  return {
    type: STORE_TITLES,
    payload: {
      titles,
    },
  };
}

export function resetAnticipation(): AnticipationActionTypes {
  return {
    type: RESET_ANTICIPATION,
  };
}

export function addBankAccount(bankAccount: string): AnticipationActionTypes {
  return {
    type: ADD_BANK_ACCOUNT,
    bankAccount,
  };
}
