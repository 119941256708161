import React, { useState, useEffect, Fragment } from 'react';
import {
  Checkbox,
  Button,
  LinearProgress,
  TextField,
  FormControlLabel,
} from '@material-ui/core';
import {
  Lock,
  StarHalf,
  ExpandMore,
  ExpandLess,
  Description,
  Keyboard,
} from '@material-ui/icons';
import { Table, Row, Col } from 'react-bootstrap';
import { format, isBefore, addHours, subHours, isSameDay } from 'date-fns';

import { useDispatch } from 'react-redux';

import axios from 'axios';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import useSelector from '~/store/useSelector';
import { ITitle } from '~/store/modules/anticipation/types';
import {
  storeOperationId,
  storeTitles,
  resetAnticipation,
} from '~/store/modules/anticipation/actions';
import { signOut } from '~/store/modules/auth/actions';

import animationData from '~/animations/box_empty.json';

import ModalXml from './ModalXml';
import ModalTapNote from './ModalTapNote';
import {
  normalizeCurrency,
  normalizeCpfCnpj,
  normalizeNumber,
} from '../utils/normalize';

interface INote {
  id: string;
  client: string;
  clientDocument: string;
  chNfe?: string;
  chCte?: string;
  titleNumber: string;
  expires: string;
  value: number;
  status: string;
  analystStatus: string;
  createdAt: Date;
}

const TableOne: React.FC = () => {
  const dispatch = useDispatch();
  const [zoneTime] = useState<number>(new Date().getTimezoneOffset() / 60);
  const [status, setStatus] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [notes, setNotes] = useState<INote[]>([]);
  const [selected, setSelected] = useState<INote[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    startValue: '',
    endValue: '',
    key: '',
    payerDocument: '',
  });
  const [dataDays, setDataDays] = useState<Date[]>([]);
  const [expandedDay, setExpandedDay] = useState<Date[]>([]);

  const [xmlOpen, setXmlOpen] = useState(false);
  const [digitOpen, setDigitOpen] = useState(false);

  const { id, typeEmission } = useSelector((state) => state.user);
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function handleExpanded(item: Date) {
    if (expandedDay.find((i) => i === item)) {
      setExpandedDay(expandedDay.filter((i) => !isSameDay(i, item)));
    } else {
      setExpandedDay([...expandedDay, item]);
    }
  }

  async function loadData() {
    setLoading(true);
    setDataDays([]);
    try {
      const { data: dataCompany } = await axios.get(`users/user/profile`);

      const { data: dataStatus } = await axios.get(
        `companies/status/${dataCompany.currentCompany}`
      );
      if (dataStatus.innactivated) {
        setIsBlocked(true);
      } else if (dataStatus.status === 'Em operação') {
        if (!typeEmission) {
          toast.error('Sessão expirada, entre novamente');
          dispatch(signOut());
        } else {
          const query: string[] = [];

          if (filters.startDate) {
            query.push(`startDate=${filters.startDate}`);
          }
          if (filters.endDate) {
            query.push(`endDate=${filters.endDate}`);
          }
          if (filters.payerDocument) {
            query.push(
              `payerDocument=${filters.payerDocument.replace(/[^\d]/g, '')}`
            );
          }
          if (filters.startValue) {
            query.push(`startValue=${normalizeNumber(filters.startValue)}`);
          }
          if (filters.endValue) {
            query.push(`endValue=${normalizeNumber(filters.endValue)}`);
          }
          if (filters.key) {
            let keyType = '';
            if (typeEmission === 'nfe') {
              keyType = 'chNfe';
            } else if (typeEmission === 'cte') {
              keyType = 'chCte';
            }
            query.push(`${keyType}=${normalizeNumber(filters.key)}`);
          }
          const { data } = await axios.get(
            `/${typeEmission}s?${query.map((i) => i).join('&')}`
          );
          if (data.length > 0) {
            const notesData: INote[] = [];
            const dataDaysV: Date[] = [];
            for (let i = 0; i < data.length; i += 1) {
              if (!isBefore(new Date(data[i].titleExpiresDate), new Date())) {
                console.log(data[i]);
                console.log(
                  !dataDaysV.find((d) =>
                    isSameDay(d, new Date(data[i].created_at))
                  )
                );
                if (
                  !dataDaysV.find((d) =>
                    isSameDay(d, new Date(data[i].created_at))
                  )
                ) {
                  dataDaysV.push(new Date(data[i].created_at));
                }
                notesData.push({
                  id: data[i].id,
                  client: data[i].sacado,
                  clientDocument: data[i].sacadoCnpj,
                  chNfe: data[i].chNfe,
                  chCte: data[i].chCte,
                  expires: format(
                    zoneTime >= 0
                      ? addHours(new Date(data[i].titleExpiresDate), zoneTime)
                      : subHours(
                          new Date(data[i].titleExpiresDate),
                          Math.abs(zoneTime)
                        ),
                    'dd/MM/yyyy'
                  ),
                  titleNumber: data[i].titleNumber,
                  value: Number(data[i].titleValue),
                  status: data[i].status,
                  analystStatus: data[i].analystStatus,
                  createdAt: new Date(data[i].created_at),
                });
              }
            }
            setExpandedDay([dataDaysV.sort()[0]]);
            setDataDays(dataDaysV);
            setNotes(notesData);
          } else {
            setNotes([]);
          }
          setStatus(true);
        }
      } else {
        setStatus(false);
      }
    } catch (error) {}// eslint-disable-line
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [dispatch, typeEmission, id]); // eslint-disable-line

  const { operationId, titles } = useSelector((state) => state.anticipation);

  useEffect(() => {
    const selectedTitles: INote[] = [];
    titles.forEach((t) =>
      selectedTitles.push({
        id: t.id,
        client: t.sacado,
        clientDocument: t.sacadoCnpj,
        value: t.value,
        chNfe: t.chNfe,
        chCte: t.chCte,
        titleNumber: t.titleNumber,
        status: t.status,
        analystStatus: '',
        expires: '',
        createdAt: new Date(),
      })
    );
    setSelected(selectedTitles);
  }, [titles]); // eslint-disable-line

  async function addTitle(item: ITitle, reference: string) {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/titles`,
        {
          internalReferenceId: item.id,
          operationId: reference,
          key: item.chNfe || item.chCte,
          number: item.titleNumber,
          type: 'D',
        }
      );
    } catch (err) {
      if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamentem mais tarde.'
        );
      } else if (err.response && err.response.status === 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error(
          'Ocorreu um erro em nossa aplicação, tente novamentem mais tarde.'
        );
      }
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  }
  async function removeTitle(titleId: string, reference: string | null) { // eslint-disable-line
    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/titles/${titleId}/${reference}`
      );
    } catch (err) {
      if (err.response && err.response.status === 500) {
        toast.error(
          'Ocorreu um erro em nossos servidores, tente novamentem mais tarde.'
        );
      } else {
        toast.error(
          'Ocorreu um erro em nossa aplicação, tente novamentem mais tarde.'
        );
      }
      return setLoading(false);
    }
    setLoading(false);
  }

  const handleClick = async (event: React.MouseEvent<unknown>, item: INote) => { // eslint-disable-line
    let operationIdReference = operationId || '';

    if (!operationId) {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/`,
          {
            type: typeEmission,
          }
        );
        dispatch(storeOperationId({ operationId: data.id }));
        operationIdReference = data.id;
      } catch (err) {
        if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamentem mais tarde.'
          );
        }
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        return setLoading(false);
      }
      setLoading(false);
    }

    const selectedIndex = selected.findIndex((i) => i.id === item.id);
    let newSelected: INote[] = [];

    if (selectedIndex === -1) {
      const added = await addTitle(
        {
          id: item.id,
          chNfe: item.chNfe,
          chCte: item.chCte,
          titleNumber: item.titleNumber,
          sacado: item.client,
          sacadoCnpj: item.clientDocument,
          status: item.status,
          value: item.value,
          titleDocument: '',
        },
        operationIdReference
      );
      if (added) {
        newSelected = [...selected, item];
      }
    } else if (selectedIndex === 0 && (item.chNfe || item.chCte)) {
      await removeTitle(item.id, operationIdReference);
      newSelected = newSelected.concat(selected.slice(1));
    } else if (
      selectedIndex === selected.length - 1 &&
      (item.chNfe || item.chCte)
    ) {
      await removeTitle(item.id, operationIdReference);
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0 && (item.chNfe || item.chCte)) {
      await removeTitle(item.id, operationIdReference);
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const titlesStore: ITitle[] = [];

    newSelected.forEach((title) =>
      titlesStore.push({
        id: title.id,
        chNfe: title.chNfe,
        chCte: title.chCte,
        titleNumber: title.titleNumber,
        sacado: title.client,
        sacadoCnpj: '',
        status: title.status,
        value: title.value,
        titleDocument: '',
      })
    );

    dispatch(storeTitles({ titles: titlesStore }));

    if (newSelected.length === 0) {
      dispatch(resetAnticipation());
    }

    setSelected(newSelected);
  };

  const handleUnSelectAll = async (day: Date) => {
    setLoading(true);
    setSelected(selected.filter((s) => !isSameDay(s.createdAt, day)));
    const list = selected.filter((s) => isSameDay(s.createdAt, day));
    for (let i = 0; i < list.length; i += 1) {
      try {
        await removeTitle(list[i].id, operationId);
      } catch (err) {} // eslint-disable-line
    }
    dispatch(resetAnticipation());
    setLoading(false);
  };
  const handleClickAll = async (day: Date, notesDay: INote[]) => { // eslint-disable-line
    let operationIdReference = operationId || '';

    if (!operationId) {
      setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_GATEWAY_OPERATION}/operations/operations/`,
          {
            type: typeEmission,
          }
        );
        dispatch(storeOperationId({ operationId: data.id }));
        operationIdReference = data.id;
      } catch (err) {
        if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamentem mais tarde.'
          );
        }
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data.message);
        }
        return setLoading(false);
      }
      setLoading(false);
    }

    let selectedTemp = selected;
    for (let i = 0; i < notesDay.length; i += 1) {
      const note = notesDay[i];
      const checked = selectedTemp.find(
        (s) =>
          (s.chCte === undefined &&
            s.chNfe === note.chNfe &&
            s.titleNumber === note.titleNumber) ||
          (s.chNfe === undefined &&
            s.chCte === note.chCte &&
            s.titleNumber === note.titleNumber)
      );

      if (!checked) {
        const selectedIndex = selectedTemp.findIndex(
          (c) =>
            (c.chCte === undefined &&
              c.chNfe === note.chNfe &&
              c.titleNumber === note.titleNumber) ||
            (c.chNfe === undefined &&
              c.chCte === note.chCte &&
              c.titleNumber === note.titleNumber)
        );
        let newSelected: INote[] = [];
        if (selectedIndex === -1) {
          const added = await addTitle(
            {
              id: note.id,
              chNfe: note.chNfe,
              chCte: note.chCte,
              titleNumber: note.titleNumber,
              sacado: note.client,
              sacadoCnpj: note.clientDocument,
              status: note.status,
              value: note.value,
              titleDocument: '',
            },
            operationIdReference
          );
          if (added) {
            newSelected = [...selectedTemp, note];
          } else {
            newSelected = [...selectedTemp];
          }
        } else if (selectedIndex === 0 && (note.chNfe || note.chCte)) {
          await removeTitle(note.id, operationIdReference);
          newSelected = newSelected.concat(selectedTemp.slice(1));
        } else if (
          selectedIndex === selectedTemp.length - 1 &&
          (note.chNfe || note.chCte)
        ) {
          await removeTitle(note.id, operationIdReference);
          newSelected = newSelected.concat(selectedTemp.slice(0, -1));
        } else if (selectedIndex > 0 && (note.chNfe || note.chCte)) {
          await removeTitle(note.id, operationIdReference);
          newSelected = newSelected.concat(
            selectedTemp.slice(0, selectedIndex),
            selectedTemp.slice(selectedIndex + 1)
          );
        }
        const titlesStore: ITitle[] = [];

        newSelected.forEach((title) =>
          titlesStore.push({
            id: title.id,
            chNfe: title.chNfe,
            chCte: title.chCte,
            titleNumber: title.titleNumber,
            sacado: title.client,
            sacadoCnpj: '',
            status: title.status,
            titleDocument: '',
            value: title.value,
          })
        );

        dispatch(storeTitles({ titles: titlesStore }));

        if (newSelected.length === 0) {
          dispatch(resetAnticipation());
        }
        selectedTemp = newSelected;
      }
    }
    setSelected(selectedTemp);
  };

  return (
    <div id="anticipation_table_wrap">
      <div className="d-flex justify-content-between align-items-center">
        <h4>Antecipar Recebíveis - NF-e / CT-e</h4>
        <div>
          <Button
            type="button"
            onClick={() => setXmlOpen(true)}
            color="primary"
            variant="contained"
            disabled={status === false && !isBlocked && !loading}
            className="mr-2 text-white"
          >
            Enviar XML's
          </Button>
          <Button
            type="button"
            onClick={() => setDigitOpen(true)}
            color="primary"
            className="mr-2 text-white"
            disabled={status === false && !isBlocked && !loading}
            variant="contained"
          >
            Digitar Nota
          </Button>
          <Button
            type="button"
            onClick={() => {
              if (isSearch) {
                loadData();
              } else {
                setIsSearch(true);
              }
            }}
            disabled={status === false && !isBlocked && !loading}
            color="primary"
            variant="outlined"
          >
            {isSearch ? 'Filtrar' : 'Exibir filtro'}
          </Button>
        </div>
      </div>
      {status === true && !isBlocked && isSearch && (
        <Row className="mb-3 mt-0 filters-wrap">
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Data de Vencimento</small>
            <div className="d-flex align-items-center">
              <TextField
                type="date"
                value={filters.startDate}
                onChange={(e) =>
                  setFilters({ ...filters, startDate: e.target.value })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                type="date"
                value={filters.endDate}
                onChange={(e) =>
                  setFilters({ ...filters, endDate: e.target.value })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-0 mt-0">
            <small className="d-block mb-2">Valor</small>
            <div className="d-flex align-items-center">
              <TextField
                placeholder="0,00"
                value={filters.startValue}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    startValue: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
              <small className="d-block ml-2 mr-2">até</small>
              <TextField
                placeholder="0,00"
                value={filters.endValue}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    endValue: normalizeCurrency(
                      Number(e.target.value.replace(/[^\d]/g, ''))
                    ),
                  })
                }
              />
            </div>
          </Col>
          <Col lg={6} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label={`Chave ${typeEmission === 'nfe' ? 'NF-e' : ''}
              ${typeEmission === 'cte' ? 'CT-e' : ''}`}
              value={filters.key}
              onChange={(e) =>
                setFilters({ ...filters, key: normalizeNumber(e.target.value) })
              }
            />
          </Col>
          <Col lg={6} className="mb-0 mt-0 d-flex align-items-end">
            <TextField
              label={
                (typeEmission === 'nfe' && 'Cliente (CNPJ/CPF)') ||
                (typeEmission === 'cte' && 'Remetente (CNPJ/CPF)')
              }
              value={filters.payerDocument}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  payerDocument: normalizeCpfCnpj(e.target.value),
                })
              }
            />
          </Col>
        </Row>
      )}
      <ModalTapNote
        open={digitOpen}
        setOpen={(e) => setDigitOpen(e)}
        onAdd={() => loadData()}
      />
      <ModalXml
        open={xmlOpen}
        setOpen={() => {
          sessionStorage.setItem('xml_uploaded', '[]');
          setXmlOpen(false);
          loadData();
        }}
      />
      {loading && <LinearProgress />}
      <Table responsive hover>
        <thead>
          <tr>
            <th className="text-left">
              {typeEmission === 'nfe' && 'Cliente'}
              {typeEmission === 'cte' && 'Remetente'}
            </th>
            <th>
              Chave {typeEmission === 'nfe' && 'NF-e'}
              {typeEmission === 'cte' && 'CT-e'}
            </th>
            <th>Documento</th>
            <th>Vencimento</th>
            <th>Valor</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {dataDays.map((day) => {
            const expanded = expandedDay.find((e) => isSameDay(e, day));
            const checked =
              selected.filter((s) => isSameDay(day, s.createdAt)).length ===
              notes.filter((n) => isSameDay(day, n.createdAt)).length;
            return (
              <Fragment key={String(day)}>
                <tr className="tableDay">
                  <td colSpan={6} style={{ padding: '4px 10px' }}>
                    <div className="d-flex justify-content-between align-items-center">
                      <button type="button" onClick={() => handleExpanded(day)}>
                        {
                          notes.filter((n) => isSameDay(day, n.createdAt))
                            .length
                        }{' '}
                        Notas enviadas dia {format(day, 'dd/MM/yyyy')}{' '}
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                      </button>
                      {expanded && (
                        <FormControlLabel
                          labelPlacement="start"
                          className="mb-0 mt-0 ml-0 mr-0"
                          label={checked ? 'Remover Todos' : 'Selecionar Todos'}
                          control={
                            <Checkbox
                              checked={checked}
                              onClick={() => {
                                if (!loading) {
                                  if (checked) {
                                    handleUnSelectAll(day);
                                  } else {
                                    handleClickAll(
                                      day,
                                      notes.filter((n) =>
                                        isSameDay(day, n.createdAt)
                                      )
                                    );
                                  }
                                }
                              }}
                              color="primary"
                              disabled={
                                status === false && !isBlocked && !loading
                              }
                            />
                          }
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {expanded &&
                  notes.length > 0 &&
                  status &&
                  notes
                    .filter((n) => isSameDay(day, n.createdAt))
                    .map((note, index) => {
                      const checkedV = selected.find(
                        (s) =>
                          (s.chCte === undefined &&
                            s.chNfe === note.chNfe &&
                            s.titleNumber === note.titleNumber) ||
                          (s.chNfe === undefined &&
                            s.chCte === note.chCte &&
                            s.titleNumber === note.titleNumber)
                      );
                      return (
                        <tr
                          key={String(index)}
                          onClick={(event) => {
                            if (!loading && note.status !== 'rejeitado') {
                              handleClick(event, note);
                            }
                          }}
                          className={checkedV ? 'selected' : ''}
                          style={{
                            cursor:
                              note.status !== 'rejeitado' &&
                              note.status !== 'em operacao' &&
                              !loading
                                ? 'pointer'
                                : 'not-allowed',
                          }}
                        >
                          <td style={{ textAlign: 'left' }}>
                            <small>
                              <strong>{note.client}</strong>
                              <br />
                              {normalizeCpfCnpj(note.clientDocument)}
                            </small>
                          </td>
                          <td>{note.chNfe || note.chCte}</td>
                          <td>
                            {(note.chNfe && Number(note.chNfe.slice(25, 34))) ||
                              (note.chCte && Number(note.chCte.slice(25, 34)))}
                            - {Number(note.titleNumber)}
                          </td>
                          <td>{note.expires}</td>
                          <td>
                            <strong>R$ {normalizeCurrency(note.value)}</strong>
                          </td>
                          <td>
                            {note.status !== 'em operacao' &&
                              note.status !== 'rejeitado' && (
                                <Checkbox
                                  checked={!!checkedV}
                                  color="primary"
                                />
                              )}
                          </td>
                        </tr>
                      );
                    })}
              </Fragment>
            );
          })}
          {status && !isBlocked && !loading && notes.length === 0 && (
            <tr style={{ height: '350px' }}>
              <td className="text-center" colSpan={6}>
                <Lottie options={defaultOptions} height={160} width={160} />
                <h4>Nenhuma nota encontrada.</h4>
                <h5>
                  Faça upload de seus XML's ou Digite suas notas para listarmos
                  seus recebíveis disponíveis
                </h5>
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    type="button"
                    startIcon={<Description />}
                    onClick={() => setXmlOpen(true)}
                    color="primary"
                    variant="contained"
                    className="text-white"
                  >
                    Enviar XML's
                  </Button>
                  <Button
                    type="button"
                    startIcon={<Keyboard />}
                    onClick={() => setDigitOpen(true)}
                    color="primary"
                    className="ml-2 text-white"
                    variant="contained"
                  >
                    Digitar Nota
                  </Button>
                </div>
              </td>
            </tr>
          )}
          {status === false && !isBlocked && !loading && (
            <tr style={{ height: '350px' }}>
              <td className="text-center" colSpan={6}>
                <StarHalf
                  color="primary"
                  style={{ fontSize: '42px', marginBottom: '20px' }}
                />
                <h4>Seu cadastro ainda não está completo.</h4>
                <h5>Para começar a antecipar é necessário:</h5>
                <ul
                  style={{ listStyle: 'none', margin: '0px', padding: '0px' }}
                >
                  <li>Preencher todos os dados cadastrais</li>
                  <li>Enviar e ter todos os documentos validados</li>
                  <li>Assinar contrato</li>
                </ul>
              </td>
            </tr>
          )}
          {isBlocked && (
            <tr style={{ height: '350px' }}>
              <td className="text-center" colSpan={6}>
                <Lock
                  color="primary"
                  style={{ fontSize: '42px', marginBottom: '20px' }}
                />
                <h4>Sua empresa foi bloqueada para antecipar.</h4>
                <p>
                  Para tirar dúvidas, entre em contato com nossa central de
                  atendimento.
                </p>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableOne;
