export const matriz = (value: number) => {
  switch (value) {
    case 1:
      return "Matriz";
    case 2:
      return "Filial";
    default:
      return "";
  }
};
