interface ICompanyAddress {
  logradouro: string;
  numero: string;
  complemento: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
}
interface ICompanyError {
  companyName: string;
  fantasyName: string;
  inscState: string;
  stateRegistration: string;
  address: ICompanyAddress;
  addressOpt: ICompanyAddress;
  anotherCompanies: string;
  financialInstitutions: string;
  numberOfColabs: string;
  website: string;
  telephones: number[];
}
interface ICompany {
  companyName: string;
  fantasyName: string;
  inscState: string;
  stateRegistration: string;
  address: ICompanyAddress;
  addressOpt: ICompanyAddress;
  diferentAddress: boolean;
  anotherCompanies: string;
  numberOfColabs: string;
  website: string;
  telephones: string[];
  financialInstitutions: string;
}

export default function companyValidation(values: ICompany) {
  let errors: ICompanyError = {
    companyName: "",
    fantasyName: "",
    inscState: "",
    stateRegistration: "",
    anotherCompanies: "",
    financialInstitutions: "",
    address: {
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
    },
    addressOpt: {
      logradouro: "",
      numero: "",
      complemento: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
    },
    telephones: [],
    numberOfColabs: "",
    website: "",
  };
  let count_errors = 0;

  if (values.companyName === "" || !values.companyName) {
    errors.companyName = "Preencha esse campo";
    count_errors += 1;
  }

  if (values.fantasyName === "" || !values.fantasyName) {
    errors.fantasyName = "Preencha esse campo";
    count_errors += 1;
  }

  if (values.inscState === "" || !values.inscState) {
    errors.inscState = "Marque alguma opção";
    count_errors += 1;
  }
  
  if (values.anotherCompanies === "1" && (values.financialInstitutions === "" || !values.financialInstitutions)) {
    errors.financialInstitutions = "Preencha esse campo";
    count_errors += 1;
  }

  if (
    values.inscState === "required" &&
    (values.stateRegistration === "" || !values.stateRegistration)
  ) {
    errors.stateRegistration = "Preencha esse campo";
    count_errors += 1;
  }

  if (values.address.logradouro === "" || !values.address.logradouro) {
    errors.address.logradouro = "Preencha esse campo";
    count_errors += 1;
  }
  if (values.address.numero === "" || !values.address.numero) {
    errors.address.numero = "Preencha esse campo";
    count_errors += 1;
  }
  if (values.address.cep === "" || !values.address.cep) {
    errors.address.cep = "Preencha esse campo";
    count_errors += 1;
  } else if (values.address.cep.length < 9) {
    errors.address.cep = "Preencha esse campo";
    count_errors += 1;
  }
  if (values.address.estado === "" || !values.address.estado) {
    errors.address.estado = "Preencha esse campo";
    count_errors += 1;
  }
  if (values.address.cidade === "" || !values.address.cidade) {
    errors.address.cidade = "Preencha esse campo";
    count_errors += 1;
  }
  if (values.address.bairro === "" || !values.address.bairro) {
    errors.address.bairro = "Preencha esse campo";
    count_errors += 1;
  }

  if(values.diferentAddress) {
    if (values.addressOpt.logradouro === "" || !values.addressOpt.logradouro) {
      errors.addressOpt.logradouro = "Preencha esse campo";
      count_errors += 1;
    }
    if (values.addressOpt.numero === "" || !values.addressOpt.numero) {
      errors.addressOpt.numero = "Preencha esse campo";
      count_errors += 1;
    }
    if (values.addressOpt.cep === "" || !values.addressOpt.cep) {
      errors.addressOpt.cep = "Preencha esse campo";
      count_errors += 1;
    } else if (values.addressOpt.cep.length < 9) {
      errors.addressOpt.cep = "Preencha esse campo";
      count_errors += 1;
    }
    if (values.addressOpt.estado === "" || !values.addressOpt.estado) {
      errors.addressOpt.estado = "Preencha esse campo";
      count_errors += 1;
    }
    if (values.addressOpt.cidade === "" || !values.addressOpt.cidade) {
      errors.addressOpt.cidade = "Preencha esse campo";
      count_errors += 1;
    }
    if (values.addressOpt.bairro === "" || !values.addressOpt.bairro) {
      errors.addressOpt.bairro = "Preencha esse campo";
      count_errors += 1;
    }
  }

  if (values.numberOfColabs === "" || !values.numberOfColabs) {
    errors.numberOfColabs = "Preencha esse campo";
    count_errors += 1;
  }

  let telephones_error: number[] = [];
  values.telephones.forEach((p, ind) => {
    if(p.length < 14) {
      telephones_error.push(ind);
      count_errors += 1;
    }
  });

  errors = {
    ...errors,
    telephones: telephones_error,
  }

  return {
    count_errors,
    errors,
  };
}
