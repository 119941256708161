import styled, { keyframes } from "styled-components";

import { lighten, darken } from "polished";

const fadeExpanded = keyframes`
  0% {
    height:0;
    overflow:hidden;
  } 100% {
    overflow:unset;
    height:65vh;
  }
`;

export const Container = styled.div`
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  #anticipation_table_wrap {
    > div:nth-child(1) {
      padding: 30px;
    }
    div.filters-wrap {
      padding: 0px 30px;
      padding-bottom: 15px;
    }
    div.footer_table {
      border-top: 2px solid #c9c9c9;
      padding: 15px;
    }
    table {
      margin-bottom: 0px;
      thead {
        tr {
          background: rgb(55 87 161 / 20%);
          border-bottom: 2px solid #3757a1;
          th {
            padding: 10px 7.5px;
            border: none;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;
            &:first-child {
              padding-left: 20px;
            }
          }
        }
      }
      tbody {
        .incomplete-registration {
          padding: 180px 0;
        }
        tr {
          &.selected {
            background: rgba(255, 213, 0, 0.03);
          }
          cursor: pointer;
          td {
            padding: 5px 7.5px;
            text-align: center;
            vertical-align: middle;
            font-size: 13px;
            line-height: 1;
            &:first-child {
              padding-left: 20px;
            }
            > span {
              padding: 0px;
            }
          }
        }
      }
    }
    button {
      &:disabled {
        opacity: 0.5;
      }
      color: ${(props) => props.theme.colors.primary};
    }
  }
  div#send-antecipation {
    justify-content: flex-end;
  }
  div#root_table {
    .MuiToolbar-gutters {
      color: ${(props) => props.theme.colors.text} !important;
      background: ${(props) => props.theme.colors.background} !important;
    }
  }
  div.antecipate_content {
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.background};
    table {
      margin: 10px 15px;
      width: calc(100% - 30px);
      background: ${(props) => lighten(0.1, props.theme.colors.background)};
      color: ${(props) => props.theme.colors.text};
      thead {
        background: ${(props) =>
          props.theme.title === "light"
            ? "rgb(210 210 210 / 41%)"
            : "rgb(0 0 0 / 41%) !important"};
      }
      th,
      th span,
      td {
        color: ${(props) => lighten(0.1, props.theme.colors.text)};
      }
    }
  }
  div#antecipate_box {
    #expanded {
      max-width: 100%;
      transition: 350ms;
      animation: ${fadeExpanded} 300ms cubic-bezier(0.075, 0.82, 0.25, 1);
    }
    position: fixed;
    bottom: -300px;
    left: 50%;
    border-radius: 20px;
    box-shadow: -1px 0px 7px 2px
      ${(props) => darken(0.2, props.theme.colors.background)};
    width: 100%;
    @media (min-width: 1300px){
      max-width: 60%;
    }
    max-width: 70%;
    transform: translateX(-50%);
    -webkit-transition: 300ms;
    transition: 300ms;
    z-index: 100;
    &.open {
      bottom: 0;
    }
    z-index: 100;
    .antecipate_box__container:nth-child(1) {
      color: ${(props) => lighten(0.2, props.theme.colors.text)};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 7px solid ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.background};
      border-radius: 15px 15px 0px 0px;
      padding: 35px;
      h3 {
        font-size: 22px;
        font-weight: 800;
        margin-bottom: 0px;
      }
      button {
        border: 1px solid ${(props) => props.theme.colors.primary};
        padding: 8px 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.colors.background};
        color: ${(props) => props.theme.colors.primary};
      }
    }
    .container:nth-child(2) {
      background: ${(props) => props.theme.colors.background};
      padding: 30px;
      max-height: 65vh;
      height: 100%;
      #add_bank {
        padding: 5px 10px;
        background: none;
        border: 1px solid ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.primary};
        font-size: 12px;
        display: block;
        margin-left: auto;
        svg {
          font-size: 12px;
        }
      }
      .control_label_bank {
        margin-left: 0;
        span {
          font-size: 12px;
        }
        * {
          font-size: 13px !important;
        }
      }
      legend {
        color: #585858;
        font-size: 14px;
        margin-bottom: 20px;
      }
      #grid_detail {
        border-top: 1px solid #eee;
        padding: 25px 5px 0px;
        display: grid;
        grid-template-columns: 6fr 4fr;
        grid-gap: 50px;
        > div {
          > p {
            color: #585858;
            font-size: 14px;
          }
          position: relative;
          height: 55vh;
          .list-items {
            height: auto;
            max-height: 50vh;
            overflow: auto;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;
            padding-right: 10px;
            .item {
              background: #f5f5f5;
              padding: 7.5px;
              button {
                color: ${(props) => props.theme.colors.primary};
                padding: 0;
                margin: 0;
              }
              > div {
                line-height: 1.25;
                p,
                h4,
                span {
                  margin: 2px 0px;
                  color: #444;
                }
                p {
                  font-size: 12px;
                  margin: 0;
                }
                h4 {
                  font-size: 18px;
                  font-weight: bolder;
                  margin: 2px 0 0;
                }
                span {
                  font-size: 12px;
                  margin: 0;
                }
              }
            }
          }
        }
        div#total_antecipated {
          width: 100%;
          div {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: 13px;
              color: ${(props) => lighten(0.3, props.theme.colors.text)};
            }
            strong {
              color: ${(props) => lighten(0.1, props.theme.colors.text)};
            }
          }
        }
      }
    }
  }
  div#antecipate-section {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 0%;
    overflow: hidden;
    padding: 0px;
    opacity: 0;
    transition: 500ms;
    &.open {
      opacity: 1;
      height: 100%;
      z-index: 20;
      padding: 20px 0px;
      border-left: 15px solid ${(props) => props.theme.colors.primary};
      width: 20%;
      overflow: hidden;
    }
    background: ${(props) =>
      props.theme.title === "light"
        ? "rgba(255,255,255,.96)"
        : "rgba(0,0,0,.96)"};
    bottom: 0px;
    right: 0px;
    div.grid-item + div.grid-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media screen and (max-width: 1000px) {
      & {
        grid-template-columns: 1fr;
        overflow: auto;
      }
    }
    div.grid-item {
      padding: 20px;
      width: 100%;
      .MuiFormGroup-root {
        padding: 15px;
        height: 120px;
        flex-wrap: nowrap !important;
        overflow: auto;
        * {
          font-size: 12px !important;
        }
      }
      div#items-recieved {
        border-top: 1px solid ${(props) => props.theme.colors.primary};
        margin-top: 15px;
        padding-top: 15px;
        p {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          strong {
            width: 25px;
            height: 25px;
            background: ${(props) => props.theme.colors.primary};
            border-radius: 50%;
            font-size: 18px;
            color: ${(props) => props.theme.colors.background};
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        div.list-items {
          overflow: auto;
          height: 200px;
          padding: 0px 15px;
          div.item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
            margin: 10px 0px;
            background: ${(props) =>
              darken(0.2, props.theme.colors.background)};
            opacity: 0.7;
            transition: 300ms;
            padding: 10px;
            position: relative;
            button {
              position: absolute;
              top: -5px;
              left: -5px;
              height: 20px;
              width: 20px;
              font-size: 12px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              background: ${(props) =>
                darken(0.3, props.theme.colors.background)};
            }
            &:hover {
              opacity: 1;
            }
            p {
              color: ${(props) => lighten(0.3, props.theme.colors.text)};
              font-size: 12px;
              font-weight: 700;
            }
            h4 {
              color: ${(props) => lighten(0.1, props.theme.colors.text)};
              font-size: 10px;
              font-weight: 900;
              text-align: right;
            }
            span {
              color: ${(props) => lighten(0.4, props.theme.colors.text)};
              font-size: 12px;
            }
            span + span {
              text-align: right;
            }
          }
        }
      }
      div#total_antecipated {
        width: 100%;
        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 12px;
            color: ${(props) => lighten(0.3, props.theme.colors.text)};
          }
          strong {
            color: ${(props) => lighten(0.1, props.theme.colors.text)};
          }
        }
      }
    }
    span.value-section {
      color: ${(props) => lighten(0.1, props.theme.colors.text)};
      p {
        margin: 0;
        padding: 0;
      }
      h2 {
        margin: 0;
        padding: 0;
        color: ${(props) => props.theme.colors.primary};
        font-weight: 900;
        font-size: 22px;
      }
    }
  }
  div#header-table-options {
    border-bottom: 1px solid
      ${(props) => lighten(0.1, props.theme.colors.background)};
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    button {
      transition: 300ms;
      &.active {
        border-bottom: 1px solid ${(props) => props.theme.colors.primary};
      }
    }
  }
`;
