import {
  ICompany,
  SWITCH_COMPANY,
  SET_TYPE_EMISSION,
  PERSIST_COMPANY,
  UPDATE_NAME,
  SYNC_COMPANY,
  UserActionTypes,
} from './types';

interface ISwitchCompany {
  company: ICompany;
  token: string;
}
interface IUpdateName {
  name: string;
}

export function switchCompany({
  company,
  token,
}: ISwitchCompany): UserActionTypes {
  return {
    type: SWITCH_COMPANY,
    payload: { company, token },
  };
}

export function setTypeEmission(typeEmission: string): UserActionTypes {
  return {
    type: SET_TYPE_EMISSION,
    payload: { typeEmission },
  };
}

export function persistCompany({
  company,
  token,
}: ISwitchCompany): UserActionTypes {
  return {
    type: PERSIST_COMPANY,
    payload: { company, token },
  };
}

export function updateName({ name }: IUpdateName): UserActionTypes {
  return {
    type: UPDATE_NAME,
    payload: { name },
  };
}

export function syncCompany(): UserActionTypes {
  return {
    type: SYNC_COMPANY,
  };
}
