import styled, { keyframes } from 'styled-components';

import { Modal } from '@material-ui/core';
import { lighten } from 'polished';

const fadeWallet = keyframes`
  0% {
    opacity:0;
    transform:scale(1.1) translateX(-40px) translateY(-15px) rotate(20deg);
  }70%{
    opacity:1;
    transform:scale(1) translateX(-40px) translateY(-15px) rotate(20deg);
  }100%{
    transform:scale(1) translateX(0px) translateY(0px) rotate(0deg);
  }
`;
const fadeMoney = keyframes`
  0% {
    opacity:0;
    transform:translateY(20px);
  } 70% {
    opacity:1;
    transform:translateY(-8px);
  } 100% {
    transform:translateY(0px);
  }
`;

export const Container = styled(Modal)`
  display: flex;
  align-items:center;
  justify-content:center;
  * {
    outline:none !important;
  }
  div#modal_paper {
    padding:25px;
    background:${props => props.theme.colors.background};
    border-radius:10px;
    width:100%;
    max-width:700px;
    text-align:center;
    .animation_money {
      width:auto;
      padding:10px 15px;
      position:relative;
      #wallet {
        font-size:100px;
        z-index:15;
        background:${props => props.theme.colors.background};
        animation:${fadeWallet} 500ms linear;
        animation-delay:500ms;
        animation-fill-mode:backwards;
        color:${props => lighten(0.05, props.theme.colors.text)};
      }
      #money_1, #money_2 {
        position:absolute;
        font-size:50px;
        color:${props => props.theme.colors.primary};
      }
      #money_1 {
        top:25px;
        left:calc(50% - 105px);
        z-index:10;
        animation:${fadeMoney} 300ms cubic-bezier(0.19, 1, 0.22, 1);
        animation-delay:900ms;
        animation-fill-mode:backwards;
      }
      #money_2 {
        top:45px;
        left:calc(50% - 75px);
        z-index:12;
        animation:${fadeMoney} 300ms cubic-bezier(0.19, 1, 0.22, 1);
        animation-delay:1050ms;
        animation-fill-mode:backwards;
      }
    }
    h3 {
      color:${props => props.theme.colors.primary};
      font-weight:600;
    }
    p {
      color:${props => lighten(0.1, props.theme.colors.text)};
    }
  }
`;
