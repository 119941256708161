import React from "react";

import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

interface IStateSelect {
  value: string;
  onChange: any;
  label: string;
  error: boolean;
  required: boolean;
  disabled?: boolean;
}

export const banks = [
  '001 BANCO DO BRASIL S.A (BB)',
  '237 BRADESCO S.A',
  '335 Banco Digio S.A',
  '260 NU PAGAMENTOS S.A (NUBANK)',
  '290 Pagseguro Internet S.A',
  '323 Mercado Pago - conta do Mercado Livre',
  '237 NEXT BANK (UTILIZAR O MESMO CÓDIGO DO BRADESCO)',
  '637 BANCO SOFISA S.A (SOFISA DIRETO)',
  '077 BANCO INTER S.A',
  '341 ITAÚ UNIBANCO S.A',
  '104 CAIXA ECONÔMICA FEDERAL (CEF)',
  '033 BANCO SANTANDER BRASIL S.A',
  '212 BANCO ORIGINAL S.A',
  '756 BANCOOB (BANCO COOPERATIVO DO BRASIL)',
  '655 BANCO VOTORANTIM S.A',
  '655 NEON PAGAMENTOS S.A (OS MESMOS DADOS DO BANCO VOTORANTIM)',
  '041 BANRISUL – BANCO DO ESTADO DO RIO GRANDE DO SUL S.A',
  '389 BANCO MERCANTIL DO BRASIL S.A',
  '422 BANCO SAFRA S.A',
  '070 BANCO DE BRASÍLIA (BRB)',
  '136 UNICRED COOPERATIVA',
  '741 BANCO RIBEIRÃO PRETO',
  '739 BANCO CETELEM S.A',
  '743 BANCO SEMEAR S.A',
  '100 PLANNER CORRETORA DE VALORES S.A',
  '096 BANCO B3 S.A',
  '747 Banco RABOBANK INTERNACIONAL DO BRASIL S.A',
  '748 SICREDI S.A',
  '752 BNP PARIBAS BRASIL S.A',
  '091 UNICRED CENTRAL RS',
  '399 KIRTON BANK',
  '108 PORTOCRED S.A',
  '757 BANCO KEB HANA DO BRASIL S.A',
  '102 XP INVESTIMENTOS S.A',
  '348 BANCO XP S/A',
  '340 SUPER PAGAMENTOS S/A (SUPERDITAL)',
  '364 GERENCIANET PAGAMENTOS DO BRASIL',
  '084 UNIPRIME NORTE DO PARANÁ',
  '180 CM CAPITAL MARKETS CCTVM LTDA',
  '066 BANCO MORGAN STANLEY S.A',
  '015 UBS BRASIL CCTVM S.A',
  '143 TREVISO CC S.A',
  '062 HIPERCARD BM S.A',
  '074 BCO. J.SAFRA S.A',
  '099 UNIPRIME CENTRAL CCC LTDA',
  '025 BANCO ALFA S.A.',
  '075 BCO ABN AMRO S.A',
  '040 BANCO CARGILL S.A',
  '190 SERVICOOP',
  '063 BANCO BRADESCARD',
  '191 NOVA FUTURA CTVM LTDA',
  '064 GOLDMAN SACHS DO BRASIL BM S.A',
  '097 CCC NOROESTE BRASILEIRO LTDA',
  '016 CCM DESP TRÂNS SC E RS',
  '012 BANCO INBURSA',
  '003 BANCO DA AMAZONIA S.A',
  '060 CONFIDENCE CC S.A',
  '037 BANCO DO ESTADO DO PARÁ S.A',
  '159 CASA CREDITO S.A',
  '172 ALBATROSS CCV S.A',
  '085 COOP CENTRAL AILOS',
  '114 CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
  '036 BANCO BBI S.A',
  '394 BANCO BRADESCO FINANCIAMENTOS S.A',
  '004 BANCO DO NORDESTE DO BRASIL S.A.',
  '320 BANCO CCB BRASIL S.A',
  '189 HS FINANCEIRA',
  '105 LECCA CFI S.A',
  '076 BANCO KDB BRASIL S.A.',
  '082 BANCO TOPÁZIO S.A',
  '286 CCR DE OURO',
  '093 PÓLOCRED SCMEPP LTDA',
  '273 CCR DE SÃO MIGUEL DO OESTE',
  '157 ICAP DO BRASIL CTVM LTDA',
  '183 SOCRED S.A',
  '014 NATIXIS BRASIL S.A',
  '130 CARUANA SCFI',
  '127 CODEPE CVC S.A',
  '079 BANCO ORIGINAL DO AGRONEGÓCIO S.A',
  '081 BBN BANCO BRASILEIRO DE NEGOCIOS S.A',
  '118 STANDARD CHARTERED BI S.A',
  '133 CRESOL CONFEDERAÇÃO',
  '121 BANCO AGIBANK S.A',
  '083 BANCO DA CHINA BRASIL S.A',
  '138 GET MONEY CC LTDA',
  '024 BCO BANDEPE S.A',
  '095 BANCO CONFIDENCE DE CÂMBIO S.A',
  '094 BANCO FINAXIS',
  '276 SENFF S.A',
  '137 MULTIMONEY CC LTDA',
  '092 BRK S.A',
  '047 BANCO BCO DO ESTADO DE SERGIPE S.A',
  '144 BEXS BANCO DE CAMBIO S.A.',
  '126 BR PARTNERS BI',
  '301 BPP INSTITUIÇÃO DE PAGAMENTOS S.A',
  '173 BRL TRUST DTVM SA',
  '119 BANCO WESTERN UNION',
  '254 PARANA BANCO S.A',
  '268 BARIGUI CH',
  '107 BANCO BOCOM BBM S.A',
  '412 BANCO CAPITAL S.A',
  '124 BANCO WOORI BANK DO BRASIL S.A',
  '149 FACTA S.A. CFI',
  '197 STONE PAGAMENTOS S.A',
  '142 BROKER BRASIL CC LTDA',
  '389 BANCO MERCANTIL DO BRASIL S.A.',
  '184 BANCO ITAÚ BBA S.A',
  '634 BANCO TRIANGULO S.A (BANCO TRIÂNGULO)',
  '545 SENSO CCVM S.A',
  '132 ICBC DO BRASIL BM S.A',
  '298 VIPS CC LTDA',
  '129 UBS BRASIL BI S.A',
  '128 MS BANK S.A BANCO DE CÂMBIO',
  '194 PARMETAL DTVM LTDA',
  '310 VORTX DTVM LTDA',
  '163 COMMERZBANK BRASIL S.A BANCO MÚLTIPLO',
  '280 AVISTA S.A',
  '146 GUITTA CC LTDA',
  '279 CCR DE PRIMAVERA DO LESTE',
  '182 DACASA FINANCEIRA S/A',
  '278 GENIAL INVESTIMENTOS CVM S.A',
  '271 IB CCTVM LTDA',
  '021 BANCO BANESTES S.A',
  '246 BANCO ABC BRASIL S.A',
  '751 SCOTIABANK BRASIL',
  '208 BANCO BTG PACTUAL S.A',
  '746 BANCO MODAL S.A',
  '241 BANCO CLASSICO S.A',
  '612 BANCO GUANABARA S.A',
  '604 BANCO INDUSTRIAL DO BRASIL S.A',
  '505 BANCO CREDIT SUISSE (BRL) S.A',
  '196 BANCO FAIR CC S.A',
  '300 BANCO LA NACION ARGENTINA',
  '477 CITIBANK N.A',
  '266 BANCO CEDULA S.A',
  '122 BANCO BRADESCO BERJ S.A',
  '376 BANCO J.P. MORGAN S.A',
  '473 BANCO CAIXA GERAL BRASIL S.A',
  '745 BANCO CITIBANK S.A',
  '120 BANCO RODOBENS S.A',
  '265 BANCO FATOR S.A',
  '007 BNDES (Banco Nacional do Desenvolvimento Social)',
  '188 ATIVA S.A INVESTIMENTOS',
  '134 BGC LIQUIDEZ DTVM LTDA',
  '641 BANCO ALVORADA S.A',
  '029 BANCO ITAÚ CONSIGNADO S.A',
  '243 BANCO MÁXIMA S.A',
  '078 HAITONG BI DO BRASIL S.A',
  '111 BANCO OLIVEIRA TRUST DTVM S.A',
  '017 BNY MELLON BANCO S.A',
  '174 PERNAMBUCANAS FINANC S.A',
  '495 LA PROVINCIA BUENOS AIRES BANCO',
  '125 BRASIL PLURAL S.A BANCO',
  '488 JPMORGAN CHASE BANK',
  '065 BANCO ANDBANK S.A',
  '492 ING BANK N.V',
  '250 BANCO BCV',
  '145 LEVYCAM CCV LTDA',
  '494 BANCO REP ORIENTAL URUGUAY',
  '253 BEXS CC S.A',
  '269 HSBC BANCO DE INVESTIMENTO',
  '213 BCO ARBI S.A',
  '139 INTESA SANPAOLO BRASIL S.A',
  '018 BANCO TRICURY S.A',
  '630 BANCO INTERCAP S.A',
  '224 BANCO FIBRA S.A',
  '600 BANCO LUSO BRASILEIRO S.A',
  '623 BANCO PAN',
  '204 BANCO BRADESCO CARTOES S.A',
  '479 BANCO ITAUBANK S.A',
  '456 BANCO MUFG BRASIL S.A',
  '464 BANCO SUMITOMO MITSUI BRASIL S.A',
  '613 OMNI BANCO S.A',
  '652 ITAÚ UNIBANCO HOLDING BM S.A',
  '653 BANCO INDUSVAL S.A',
  '069 BANCO CREFISA S.A',
  '370 BANCO MIZUHO S.A',
  '249 BANCO INVESTCRED UNIBANCO S.A',
  '318 BANCO BMG S.A',
  '626 BANCO FICSA S.A',
  '270 SAGITUR CC LTDA',
  '366 BANCO SOCIETE GENERALE BRASIL',
  '113 MAGLIANO S.A',
  '131 TULLETT PREBON BRASIL CVC LTDA',
  '011 C.SUISSE HEDGING-GRIFFO CV S.A (Credit Suisse)',
  '611 BANCO PAULISTA',
  '755 BOFA MERRILL LYNCH BM S.A',
  '089 CCR REG MOGIANA',
  '643 BANCO PINE S.A',
  '140 EASYNVEST - TÍTULO CV S.A',
  '707 BANCO DAYCOVAL S.A',
  '288 CAROL DTVM LTDA',
  '101 RENASCENCA DTVM LTDA',
  '487 DEUTSCHE BANK S.A BANCO ALEMÃO',
  '233 BANCO CIFRA',
  '177 GUIDE',
  '633 BANCO RENDIMENTO S.A',
  '218 BANCO BS2 S.A',
  '292 BS2 DISTRIBUIDORA DE TÍTULOS E INVESTIMENTOS',
  '169 BANCO OLÉ BONSUCESSO CONSIGNADO S.A',
  '293 LASTRO RDV DTVM LTDA',
  '285 FRENTE CC LTDA',
  '080 B&T CC LTDA',
  '753 NOVO BANCO CONTINENTAL S.A BM',
  '222 BANCO CRÉDIT AGRICOLE BR S.A',
  '754 BANCO SISTEMA',
  '098 CREDIALIANÇA CCR',
  '610 BANCO VR S.A',
  '712 BANCO OURINVEST S.A',
  '010 CREDICOAMO',
  '283 RB CAPITAL INVESTIMENTOS DTVM LTDA',
  '217 BANCO JOHN DEERE S.A',
  '117 ADVANCED CC LTDA',
  '336 BANCO C6 S.A - C6 BANK',
  '654 BANCO DIGIMAIS S.A',
];

export const BankSelect = ({
  value,
  onChange,
  label = "Banco",
  error,
  required,
  disabled,
  ...rest
}: IStateSelect) => {
  return (
    <Autocomplete
      options={banks}
      getOptionLabel={(option) => option}
      {...rest}
      value={value}
      onChange={(e, v) => onChange(v)}
      disabled={disabled}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          disabled={disabled}
        />
      )}
    />
  );
};
