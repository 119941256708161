import React from 'react';

import { Warning } from '@material-ui/icons';
import { Button, Modal, CircularProgress } from '@material-ui/core';

interface IProps {
  open: boolean;
  setOpen(e:boolean): void;
  onDelete(): void;
  loading: boolean;
}

const ModalConfirmation: React.FC<IProps> = ({
  open,
  setOpen,
  onDelete,
  loading,
}) => {
  return (
    <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="d-flex justify-content-center align-items-center"
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: '12px',
            outline: 'none'
          }}
        >
          <Warning
            style={{ fontSize: '80px' }}
            color="primary"
            className="mb-4"
          />
          <p>Você tem certeza que deseja desinstalar este e-CNPJ?</p>
          <strong>Após a exclusão, caso não haja nenhum e-CNPJ instalado, não será possível realizar operações.</strong>
          <div className="mt-4">
            <Button
              type="button"
              variant="outlined"
              color="primary"
              onClick={() => onDelete()}
              disabled={loading}
            >
              {loading ? (<CircularProgress style={{ color: '#fff', fontSize: '16px' }}/>) : 'Sim, excluir'}
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className="ml-2"
              onClick={() => setOpen(false)}
              disabled={loading}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
  );
}

export default ModalConfirmation;