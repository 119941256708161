import React, { memo, useState } from 'react';

import { LinearProgress, Button } from '@material-ui/core';
import { FaPrint } from 'react-icons/fa';

import { Table } from 'react-bootstrap';

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Resume: React.FC<IProps> = () => {
  const [loading] = useState(false);

  return (
    <div id="titles_table_wrap">
      <div className="align-items-center d-flex justify-content-between">
        <div>
          <h4>Resumo da carteira</h4>
        </div>
        <div>
          <Button
            color="primary"
            variant="contained"
            className="mr-2"
            type="button"
          >
            <FaPrint className="mr-2" />
            Imprimir
          </Button>
        </div>
      </div>
      <div className="content_component">
        {loading && <LinearProgress />}
        <Table responsive hover>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Tipo de Titulo</th>
              <th>Vencido</th>
              <th>Vencendo</th>
              <th>A Vencer</th>
              <th>Total</th>
              <th>Qtde</th>
              <th>Valor Médio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Factoring</td>
              <td>Cheque</td>
              <td>R$ 0,00</td>
              <td>R$ 0,00</td>
              <td>R$ 35.000,00</td>
              <td>R$ 35.000,00</td>
              <td>2</td>
              <td>R$ 17.500,00</td>
            </tr>
            <tr>
              <td>Factoring</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
            <tr>
              <td
                colSpan={8}
                style={{
                  background: '#d7deec',
                  padding: '2.5px',
                }}
              />
            </tr>
            <tr>
              <td>FIDC</td>
              <td>Cheque</td>
              <td>R$ 0,00</td>
              <td>R$ 0,00</td>
              <td>R$ 35.000,00</td>
              <td>R$ 35.000,00</td>
              <td>2</td>
              <td>R$ 17.500,00</td>
            </tr>
            <tr>
              <td>FIDC</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
            <tr>
              <td
                colSpan={8}
                style={{
                  background: '#d7deec',
                  padding: '2.5px',
                }}
              />
            </tr>
            <tr>
              <td>Cobrança simples</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
            <tr>
              <td
                colSpan={8}
                style={{
                  background: '#d7deec',
                  padding: '2.5px',
                }}
              />
            </tr>
            <tr>
              <td>Caução</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
            <tr>
              <td
                colSpan={8}
                style={{
                  background: '#d7deec',
                  padding: '2.5px',
                }}
              />
            </tr>
            <tr>
              <td>Total</td>
              <td>Cheque</td>
              <td>R$ 0,00</td>
              <td>R$ 0,00</td>
              <td>R$ 35.000,00</td>
              <td>R$ 35.000,00</td>
              <td>2</td>
              <td>R$ 17.500,00</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>Duplicata</td>
              <td>R$ 95.441,17</td>
              <td>R$ 6.299,53</td>
              <td>R$ 562.504,18</td>
              <td>R$ 664.244,88</td>
              <td>458</td>
              <td>R$ 1.450,32</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default memo(Resume);
