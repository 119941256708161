import React, { useState, useEffect } from 'react';

// import { PDFReader } from "reactjs-pdf-reader";

import { LinearProgress } from '@material-ui/core';
import { CloudDownload, QueryBuilder, CheckCircle } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import useSelector from '~/store/useSelector';
import { signOut } from '~/store/modules/auth/actions';

import { Container } from './styles';

interface ISign {
  email: string;
  id: string;
  name: string;
  signedOn: string | null;
}

interface IContract {
  id: string;
  url: string;
  type: {
    id: number;
    label: string;
  };
  signs: ISign[];
}

interface INextItem {
  (): void;
}

interface IProps {
  handleNextItem: INextItem;
}

const Contract: React.FC<IProps> = ({ handleNextItem }) => {
  const dispatch = useDispatch();
  const [contract, setContract] = useState<IContract[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<
    | 'Incompleto'
    | 'Em análise'
    | 'Aguardando geração do contrato'
    | 'Aguardando assinatura do contrato'
    | 'Em operação'
  >('Incompleto');

  const { id } = useSelector((state) => state.user);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await axios.get(`/companies/status/${id}`);
        setStatus(data.status);
      } catch (err) {}
      try {
        const { data } = await axios.get(`/contract`);
        setContract(data);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente');
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error(
            'Ocorreu um erro em nossos servidores, tente novamente mais tarde'
          );
        } else if (err.response && err.response.status !== 404) {
          toast.error('Ocorreu um erro, tente novamente mais tarde');
        }
      }
      setLoading(false);
    }
    if (id) {
      loadData();
    }
  }, [id]); // eslint-disable-line

  return (
    <Container>
      <div className="header_component d-flex">
        <h4>Contrato</h4>
      </div>
      {loading && (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      <div className="content_component">
        {!loading ? (
          contract.length > 0 ? (
            <div id="contract_list">
              {contract.map((c) => (
                <div key={c.id}>
                  <h4>{c.type.label}</h4>
                  <div className="controls">
                    <a href={c.url} target="blank" download>
                      <CloudDownload />
                      Fazer Download
                    </a>
                    {c.signs.find((s) => s.signedOn === null) ? (
                      <div className="status">
                        <QueryBuilder />
                        <span>Aguardando Assinaturas</span>
                      </div>
                    ) : (
                      <div className="status">
                        <CheckCircle />
                        <span>Completamente Assinado</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="incomplete-registration">
              {status === 'Aguardando geração do contrato' ? (
                <>
                  <h3>{status}</h3>
                  <p>
                    Caso tenha se passado mais de um dia útil sem atualização
                    desta tela, contate nosso suporte.
                  </p>
                </>
              ) : (
                <>
                  <h3>Finalize seu cadastro.</h3>
                  <p>Para visualizar os contratos finalize seu cadastro</p>
                </>
              )}
            </div>
          )
        ) : (
          <div className="incomplete-registration">
            <p>Carregando...</p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Contract;
