import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import axios from 'axios';

import {
  TextField,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Container } from './styles';
import { editUnActive } from '../../../store/modules/register/actions';
import { signInSuccess } from '../../../store/modules/auth/actions';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(editUnActive());
  }, []); // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });
  const [msg, setMsg] = useState('');

  const [successLogin, setSuccessLogin] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrors({
      email: '',
      password: '',
    });
    setMsg('');
    function emailIsValid(email: string) {
      if (email.indexOf('@') === -1) {
        return false;
      }
      const after = email.split('@');
      if (after[1].indexOf('.') === -1) {
        return false;
      }
      return true;
    }
    const lock_error = {
      email: '',
      password: '',
    };
    if (!emailIsValid(email)) {
      lock_error.email = 'Email não é válido';
    }
    if (password.length === 0) {
      lock_error.password = 'Preencha este campo';
    }
    if (lock_error.email !== '' || lock_error.password !== '') {
      return setErrors(lock_error);
    }
    setLoading(true);
    try {
      const { data } = await axios.post(`/users/user/sessions`, {
        email,
        password,
      });
      axios.defaults.headers.Authorization = `Bearer ${data.token}`;
      const { data: company } = await axios.get(`/companies/company`);
      setSuccessLogin(true);
      dispatch(
        signInSuccess({
          token: data.token,
          user: {
            name: data.user.name,
            id: data.user.id,
            email: data.user.email,
          },
          company: {
            clieId: company.clieId,
            id: company.id,
            cnpj: company.cnpj,
            companyName: company.companyName || company.cnpj,
            fantasyName: company.fantasyName || company.cnpj,
            limit: company.limit,
            limitUsed: company.limitUsed,
            typeEmission: company.typeEmission,
          },
        })
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setMsg('Dados de Login incorretos');
      } else if (err.response && err.response.status === 403) {
        setMsg(
          (err.response.data && err.response.data.message) ||
            'Ativação de conta pendente'
        );
      } else if (err.response && err.response.status === 400) {
        setMsg('Senha incorreta');
      } else {
        setMsg('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <div id="wrap-login">
        <div className={`logo-section ${successLogin ? 'close' : ''}`}>
          <img
            src="/assets/images/logo-branca.png"
            alt="BFC Capital Partners LTDA"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={`form-container ${successLogin ? 'close' : ''}`}>
            <h4>Acessar Conta</h4>
            {msg !== '' && <Alert severity="error">{msg}</Alert>}
            <TextField
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ animationDelay: '300ms' }}
              error={errors.email !== ''}
              helperText={errors.email}
              inputProps={{
                autocomplete: 'off',
              }}
            />
            <FormControl style={{ animationDelay: '450ms' }}>
              <InputLabel htmlFor="standard-adornment-password">
                Senha
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
              />
            </FormControl>
            <div className="button-wrap">
              <Button
                type="submit"
                color="primary"
                size="large"
                variant="contained"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: '#fff' }} />
                ) : (
                  'Entrar'
                )}
              </Button>
              <Link to="/auth/home">Criar conta</Link>
            </div>
            <Link to="/auth/forgot-password" className="forgot-pass">
              Esqueceu sua Senha?
            </Link>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default Login;
