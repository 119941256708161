import styled from 'styled-components';

import { darken } from 'polished';

export const Container = styled.div`
  color:${(props) => props.theme.colors.text};
  div.container-fluid {
    padding: 35px 10px 25px;
    &#first_footer{
      background:${(props) => props.theme.colors.background};
    }
    &#second_footer {
      background:${(props) => darken(0.1, props.theme.colors.background)};
    }
    h4 {
      color:${(props) => props.theme.colors.primary};
      font-size:16px;
    }
    p {
      font-size:12px;
    }
    h5 {
      font-size:12px;
      font-weight:900;
    }
  }
`;
