import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
  background: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  box-shadow:0px 0px 10px rgba(0,0,0,.1);
  height:100%;
  align-items:flex-start;
  justify-content:space-between;
  div.header_component {
    border-bottom:1px solid #eee;
    width:100%;
  }
  div.content_component {
    margin: 20px 0px;
    padding: 20px 10px;
    height:100%;
    width:100%;
    div.content_component-faq {
      width:100%;
      display:grid;
      grid-gap: 15px;
      .MuiButtonBase-root {
        p {
          font-weight: bold;
        }
      }
      > div {
        padding: 10px;
        p {
          font-family: Late, sans-serif;
          color: #555;
        }
      }
      @media screen and (max-width: 850px) {
        & {
          grid-template-columns:1fr;
        }
      }
    }
  }
`;
