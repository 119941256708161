import React, { memo, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { signOut } from "../../../store/modules/auth/actions";
import {
  TextField,
  Button,
} from "@material-ui/core";
import StateSelect from './components/StateSelect';
import { Row, Col } from "react-bootstrap";

import {
  normalizeDate,
} from "../utils/normalize";
import consultCep from "../../../services/consultCep";

import { Container } from "./styles";
import IPayer from "../Sacados/payer";
import { normalizeCnpj, normalizeCpf, normalizeCep, normalizeTelephone } from "../Sacados/utils"
import { format } from 'date-fns';

//FIXME: Bug do CEP billing address e Bug dateOfBirth 

interface IMatch {
  id: string;
}

interface IProps extends RouteComponentProps<IMatch> {
  handleNextItem: () => void;
}

const SacadoDetail: React.FC<IProps> = ({ handleNextItem, match }) => {
  const [payer, setPayer] = useState<IPayer>({} as IPayer);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function loadPayer() {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_GATEWAY_OPERATION}/operations/payers/${match.params.id}`);
        setPayer({...data, dateOfBirth: format(new Date(data.dateOfBirth), 'dd/MM/yyyy')});
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(signOut());
          toast.error('Sua sessão expirou, entre novamente')
        } else if (err.response && err.response.status === 403) {
          toast.error('Você não está autorizado a acessar este recurso');
        } else if (err.response && err.response.status === 500) {
          toast.error('Ocorreu um erro em nossos servidores, tente novamente mais tarde')
        } else {
          toast.error('Ocorreu um erro, tente novamente mais tarde')
        }
      }
    }
    loadPayer();
  }, [match.params.id, dispatch]);

  async function handleUpdatePayer() {
    try {
      const { dateOfBirth } = payer

      const day = String(dateOfBirth).slice(0,2)
      const month = String(dateOfBirth).slice(3,5)
      const year = String(dateOfBirth).slice(6,10)

      await axios.put(`${process.env.REACT_APP_GATEWAY_OPERATION}/operations/payers/${match.params.id}`, {
        ...payer,
        dateOfBirth: new Date(`${year}-${month}-${day}`)
      });
      toast.success('Dados salvo com sucesso')
      history.push('/operacoes/sacados')
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(signOut());
        toast.error('Sua sessão expirou, entre novamente')
      } else if (err.response && err.response.status === 403) {
        toast.error('Você não está autorizado a acessar este recurso');
      } else if (err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, tente novamente mais tarde')
      } else {
        toast.error('Ocorreu um erro, tente novamente mais tarde')
      }
    }
  }

  async function handleGoBack() {
    history.push('/operacoes/sacados')
  }

  async function handleCepAddress() {
    const consult = await consultCep(payer.address.zipCode.replace('-', ''));
    if (consult) {
      setPayer({
        ...payer,
        address: {
          ...payer.address,
          street: consult.logradouro,
          district: consult.bairro,
          city: consult.cidade,
          state: consult.estado,
        }
      });
    }
  }
  async function handleCepAddressBilling() {
    const consult = await consultCep(payer.billingAddress.zipCode.replace('-', ''));
    if (consult) {
      setPayer({
        ...payer,
        billingAddress: {
          ...payer.billingAddress,
          street: consult.logradouro,
          district: consult.bairro,
          city: consult.cidade,
          state: consult.estado,
        }
      });
    }
  }

  return (
    <Container>
      { payer && payer.address && payer.billingAddress && (
        <div id="payer_detail">
          <div id="header_content">
            <h4 className="mb-0">{payer.name} - {payer.type === 'PF' ? normalizeCpf(payer.document) : normalizeCnpj(payer.document)}</h4>
          </div>
          <div id="body_content">
            <Row>
              <Col className="mb-3" lg={4}>
                <TextField label="CPF/CNPJ" value={payer.type === 'PF' ? normalizeCpf(payer.document) : normalizeCnpj(payer.document)} disabled />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Nome" value={payer.name} onChange={e => setPayer({ ...payer, name: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="RG/CGF" value={payer.rgOrStateRegistration} onChange={e => setPayer({ ...payer, rgOrStateRegistration: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={2}>
                <TextField label="CEP" onBlur={handleCepAddress} value={payer.address.zipCode} onChange={e => setPayer({ ...payer, address: { ...payer.address, zipCode: normalizeCep(e.target.value) } })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Endereço" value={payer.address.street} onChange={e => setPayer({ ...payer, address: { ...payer.address, street: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={2}>
                <TextField label="Numero" value={payer.address.number} onChange={e => setPayer({ ...payer, address: { ...payer.address, number: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Complemento" value={payer.address.complement} onChange={e => setPayer({ ...payer, address: { ...payer.address, complement: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={3}>
                <TextField label="Bairro" value={payer.address.district} onChange={e => setPayer({ ...payer, address: { ...payer.address, district: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={3}>
                <TextField label="Cidade" value={payer.address.city} onChange={e => setPayer({ ...payer, address: { ...payer.address, city: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={3}>
                <StateSelect label="UF" value={payer.address.state} onChange={e => setPayer({ ...payer, address: { ...payer.address, state: e.target.value } })} />
              </Col>
              <Col className="mb-3" lg={3}>
                <TextField label="Telefone" value={payer.phone} onChange={e => setPayer({ ...payer, phone: normalizeTelephone(e.target.value) })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Profissão" value={payer.profession} onChange={e => setPayer({ ...payer, profession: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Contato" value={payer.contact} onChange={e => setPayer({ ...payer, contact: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="E-mail" value={payer.emails.length > 0 ? payer.emails[0] :  '-'} onChange={e => setPayer({ ...payer, emails: [e.target.value] })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Data de Nascimento" value={payer.dateOfBirth} onChange={e => setPayer({ ...payer, dateOfBirth: normalizeDate(e.target.value) })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Nacionalidade" value={payer.nationality} onChange={e => setPayer({ ...payer, nationality: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Naturalidade" value={payer.naturalness} onChange={e => setPayer({ ...payer, naturalness: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={6}>
                <TextField label="Nome da mãe" value={payer.motherName} onChange={e => setPayer({ ...payer, motherName: e.target.value })} />
              </Col>
              <Col className="mb-3" lg={4}>
                <TextField label="Estado Civil" value={payer.maritalStatus} onChange={e => setPayer({ ...payer, maritalStatus: e.target.value })} />
              </Col>
            </Row>

            <div className="mt-2">
              <strong className="text-muted">Endereço de cobrança</strong>
              <Row className="mt-3">
                <Col className="mb-3" lg={3}>
                  <TextField label="CEP " onBlur={handleCepAddressBilling} value={payer.billingAddress.zipCode} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, zipCode: normalizeCep(e.target.value) } })} />
                </Col>
                <Col className="mb-3" lg={6}>
                  <TextField label="Logradouro " value={payer.billingAddress.street} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, street: e.target.value } })} />
                </Col>
                <Col className="mb-3" lg={1}>
                  <TextField label="Numero " value={payer.billingAddress.number} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, number: e.target.value } })} />
                </Col>
                <Col className="mb-3" lg={2}>
                  <TextField label="Complemento " value={payer.billingAddress.complement} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, complement: e.target.value } })} />
                </Col>
                <Col className="mb-3" lg={5}>
                  <TextField label="Bairro " value={payer.billingAddress.district} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, district: e.target.value } })} />
                </Col>
                <Col className="mb-3" lg={5}>
                  <TextField label="Cidade " value={payer.billingAddress.city} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, city: e.target.value } })} />
                </Col>
                <Col className="mb-3" lg={2}>
                  <StateSelect label="UF " value={payer.billingAddress.state} onChange={e => setPayer({ ...payer, billingAddress: { ...payer.billingAddress, state: e.target.value } })} />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="d-flex justify-content-end mb-4">
              <Button onClick={handleUpdatePayer} color="primary" variant="contained" type="button">
                Salvar
              </Button>
              <Button onClick={handleGoBack} color="primary" variant="outlined" type="button" className="ml-2">
                Voltar
              </Button>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default memo(SacadoDetail);
